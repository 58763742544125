import React, { useEffect } from 'react';
import {
  Route, Routes, Navigate, useSearchParams,
} from 'react-router-dom';
import route from './routes';
import MyStrategies from '../../pages/MyStrategies/MyStrategies';
import MySubscriptions from '../../pages/MySubscriptions/MySubscriptions';
import StrategiesRating from '../../pages/StrategiesRating/StrategiesRating';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';
import AuthProvider from '../../entities/AuthProvider/AuthProvider';
import StrategyInfo from '../../pages/StrategyInfo/StrategyInfo';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { tokenRequest, getWindowWidth, getRedirectUrlsRequest } from '../../store/customer/reducer';
import MyStrategyInfo from '../../pages/MyStrategyInfo/MyStrategyInfo';

function CombineRouter() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const tempToken = searchParams.get('token') || '';
  const sessionToken = sessionStorage.getItem('token');
  const { token } = useAppSelector((state) => state.customer.token);
  const currentWindowWidth = document.documentElement.clientWidth;
  // -- если токена нет - выполни dispatch, получить токен
  useEffect(() => {
    if (!sessionToken && tempToken) {
      dispatch(tokenRequest(tempToken));
    }
  }, [searchParams, sessionToken, tempToken, dispatch]);
  // -- чистка браузерной строки от searchParams
  useEffect(() => {
    if (sessionToken && tempToken) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [sessionToken, setSearchParams, searchParams, tempToken]);
  // -- получение ссылок
  useEffect(() => {
    if (sessionToken || token) {
      dispatch(getRedirectUrlsRequest());
    }
  }, [sessionToken, token, dispatch]);
  // -- получение ширины экрана
  useEffect(() => {
    dispatch(getWindowWidth(currentWindowWidth));
  }, [dispatch, currentWindowWidth]);
  return (
    <div>
      <AuthProvider token={sessionToken}>
        <Routes>
          <Route path={route.root} element={<StrategiesRating />} />
          <Route path={route.strategy_information.catalog} element={<StrategyInfo />} />
          <Route path={route.my_strategies.catalog} element={<MyStrategies />} />
          <Route path={route.my_strategy_information.catalog} element={<MyStrategyInfo />} />
          <Route path={route.my_subscriptions.catalog} element={<MySubscriptions />} />
          <Route path={route.not_found_page.catalog} element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to={route.root} replace />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default CombineRouter;
