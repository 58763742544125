import React from 'react';
import { useMedia } from 'react-use';
import MySubscriptionsMobile from './MySubscriptionsMobile/MySubscriptionsMobile';
import MySubscriptionsDesktop from './MySubscriptionsDesktop/MySubscriptionsDesktop';

function MySubscriptions() {
  const isMobile = useMedia('(max-width: 545px)');
  return (
    isMobile
      ? <MySubscriptionsMobile />
      : <MySubscriptionsDesktop />
  );
}

export default MySubscriptions;
