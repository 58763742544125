import { combineReducers } from '@reduxjs/toolkit';
import customerReducer from '../../../store/customer/reducer';
import modalReducer from '../../../store/modal/reducer';
import myStrategiesReducer from '../../../store/myStrategies/reducer';
import mySubscriptionsReducer from '../../../store/mySubscription/reducer';
import notificationReducer from '../../../store/notification/reducer';
import strategiesRatingReducer from '../../../store/strategiesRating/reducer';
import strategyInfoReducer from '../../../store/strategyInfo/reducer';
// TODO сделать слайс entities согласно колличеству редьюсеров
const rootReducer = combineReducers({
  customer: customerReducer,
  modal: modalReducer,
  myStrategies: myStrategiesReducer,
  mySubscriptions: mySubscriptionsReducer,
  strategiesRating: strategiesRatingReducer,
  strategyInfo: strategyInfoReducer,
  notification: notificationReducer,
});
export default rootReducer;
