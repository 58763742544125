import {
  put, takeLeading, call,
} from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getActiveSubscriptionsRequest,
  getActiveSubscriptionsSuccess,
  getActiveSubscriptionsFail,
  getClosedSubscriptionsRequest,
  getClosedSubscriptionsSuccess,
  getClosedSubscriptionsFail,
  subscribeStrategySuccess,
  subscribeStrategyFail,
  pauseSubscriptionSuccess,
  pauseSubscriptionFail,
  resumeSubscriptionSuccess,
  resumeSubscriptionFail,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  editSubscriptionGetDataSuccess,
  editSubscriptionGetDataFail,
  editSubscriptionSuccess,
  editSubscriptionFail,
} from './reducer';
import { getStrategiesRequest } from '../strategiesRating/reducer';
import { notificationModalOpen } from '../notification/reducer';
import { baseUrl } from '../../shared/consts/baseUrl';
import {
  IRequestSubscribeStrategy,
  IResponseSubscribeStrategy,
  IActiveSubscriptionResponse,
  StatusSubscriptionResponse,
  ISubscriptionData,
  IRequestEditSubscription,
  IClosedSubscriptionResponse,
} from './models';

function* getActiveSubscriptionsSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {data: IActiveSubscriptionResponse} = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'subscription/active',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));

    // обсудить с бекендом отказ от вложенности resp.data.active_subscriptions
    // сортировка active_subscription по возрастанию номера счета
    resp.data.active_subscriptions.sort((a, b) => a.account - b.account);
    // соритровка по названиею подписки
    resp.data.active_subscriptions.map((item) => item.subscription_data.sort((a, b) => {
      const nameA = a.strategy_name.toUpperCase();
      const nameB = b.strategy_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    }));

    yield put(getActiveSubscriptionsSuccess(resp.data.active_subscriptions));
  } catch (e: any) {
    yield put(getActiveSubscriptionsFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_ACTIVE_STRATEGY.TITLE',
      message: `NOTIFICATION.GET_ACTIVE_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* getClosedSubscriptionsSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {data: IClosedSubscriptionResponse} = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'subscription/closed',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));

    // обсудить с бекендом отказ от вложенности resp.data.closed_subscriptions
    // добавить сортировку closed_subscriptions по возрастанию номера счета
    // соритровка по номеру счета
    resp.data.closed_subscriptions.sort((a, b) => a.account - b.account);

    yield put(getClosedSubscriptionsSuccess(resp.data.closed_subscriptions));
  } catch (e: any) {
    yield put(getClosedSubscriptionsFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_CLOSED_STRATEGY.TITLE',
      message: `NOTIFICATION.GET_CLOSED_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* subscribeStrategySaga({ payload }: PayloadAction<IRequestSubscribeStrategy>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: IResponseSubscribeStrategy
    } = yield call(() => axios({
      method: 'post',
      baseURL: baseUrl,
      url: 'follow',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: payload,
    }));
    yield put(subscribeStrategySuccess());
    yield put(getStrategiesRequest());
    if (resp.status >= 200 && resp.status <= 299) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.SUBSCRIBE_STRATEGY.TITLE',
        message: 'NOTIFICATION.SUBSCRIBE_STRATEGY.SUCCESS',
        actionButton: 'MODAL.OK_BTN',
        type: 'success',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    yield put(subscribeStrategyFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.SUBSCRIBE_STRATEGY.TITLE',
      message: `NOTIFICATION.SUBSCRIBE_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* pauseSubscriptionSaga({ payload }: PayloadAction<number>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    yield call(() => axios({
      method: 'put',
      baseURL: baseUrl,
      url: `subscription/pause/${payload}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    // resp - приходит id подписки, пока не используем его нигде
    yield put(pauseSubscriptionSuccess());
    yield put(getActiveSubscriptionsRequest());
  } catch (e: any) {
    yield put(pauseSubscriptionFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.PAUSE_SUBSCRIPTION.TITLE',
      message: `NOTIFICATION.PAUSE_SUBSCRIPTION.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* resumeSubscriptionSaga({ payload }: PayloadAction<number>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    yield call(() => axios({
      method: 'put',
      baseURL: baseUrl,
      url: `subscription/resume/${payload}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    // resp - приходит id подписки, пока не используем его нигде
    yield put(resumeSubscriptionSuccess());
    yield put(getActiveSubscriptionsRequest());
  } catch (e: any) {
    yield put(resumeSubscriptionFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.RESUME_SUBSCRIPTION.TITLE',
      message: `NOTIFICATION.RESUME_SUBSCRIPTION.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* deleteSubscriptionSaga({ payload }: PayloadAction<number>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: StatusSubscriptionResponse,
    } = yield call(() => axios({
      method: 'delete',
      baseURL: baseUrl,
      url: `subscription/${payload}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    // resp - приходит id подписки, пока не используем его нигде
    yield put(deleteSubscriptionSuccess());
    yield put(getActiveSubscriptionsRequest());
    yield put(getClosedSubscriptionsRequest());
    if (resp.status >= 200 && resp.status <= 299) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.DELETING_SUBSCRIPTION.TITLE',
        message: 'NOTIFICATION.DELETING_SUBSCRIPTION.SUCCESS',
        actionButton: 'MODAL.OK_BTN',
        type: 'success',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    yield put(deleteSubscriptionFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.DELETING_SUBSCRIPTION.TITLE',
      message: `NOTIFICATION.DELETING_SUBSCRIPTION.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* editSubscriptionSaga({ payload }: PayloadAction<IRequestEditSubscription>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: StatusSubscriptionResponse,
    } = yield call(() => axios({
      method: 'put',
      baseURL: baseUrl,
      url: 'subscription/modify',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: payload,
    }));
    yield put(editSubscriptionSuccess());
    yield put(getActiveSubscriptionsRequest());
    if (resp.status >= 200 && resp.status <= 299) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.EDIT_SUBSCRIPTION.TITLE',
        message: 'NOTIFICATION.EDIT_SUBSCRIPTION.SUCCESS',
        actionButton: 'MODAL.OK_BTN',
        type: 'success',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    yield put(editSubscriptionFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.EDIT_SUBSCRIPTION.TITLE',
      message: `NOTIFICATION.EDIT_SUBSCRIPTION.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* editSubscriptionGetDataSaga({ payload }: PayloadAction<number>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: ISubscriptionData,
    } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: `subscription/copy_rule/${payload}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    // resp - данные подписки
    yield put(editSubscriptionGetDataSuccess(resp.data));
  } catch (e: any) {
    yield put(editSubscriptionGetDataFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.EDIT_SUBSCRIPTION.TITLE',
      message: `NOTIFICATION.EDIT_SUBSCRIPTION.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}

export function* mySubscriptionsWatcher() {
  yield takeLeading('mySubscriptions/getActiveSubscriptionsRequest', getActiveSubscriptionsSaga);
  yield takeLeading('mySubscriptions/getClosedSubscriptionsRequest', getClosedSubscriptionsSaga);
  yield takeLeading('mySubscriptions/subscribeStrategyRequest', subscribeStrategySaga);
  yield takeLeading('mySubscriptions/pauseSubscriptionRequest', pauseSubscriptionSaga);
  yield takeLeading('mySubscriptions/resumeSubscriptionRequest', resumeSubscriptionSaga);
  yield takeLeading('mySubscriptions/deleteSubscriptionRequest', deleteSubscriptionSaga);
  yield takeLeading('mySubscriptions/editSubscriptionGetDataRequest', editSubscriptionGetDataSaga);
  yield takeLeading('mySubscriptions/editSubscriptionRequest', editSubscriptionSaga);
}
