import React, { FC } from 'react';
import { FormGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';
import Selector from '../../entities/Selector/Selector';
import route from '../../app/routes/routes';
import styles from './ModalSelector.module.scss';
import {
  IMyStrategyMobileSelector,
  IRewardTypes,
  ISubscriptionsMobileSelector,
} from '../../store/myStrategies/models';
import { IResponseGetAccounts } from '../../store/customer/models';
import { useAppSelector } from '../../hooks/hooks';
import { ISubscriptionData } from '../../store/mySubscription/models';

export type IValues = IResponseGetAccounts
  | IRewardTypes[]
  | IMyStrategyMobileSelector[]
  | ISubscriptionsMobileSelector[]
  | ISubscriptionData
interface ISelectorProps {
  values: IValues,
  name: string,
  formik: FormikProps<any>,
}
let accounts: object[] = [];
// let reward_accounts: object[] = [];
const ModalSelector: FC<ISelectorProps> = ({
  values, name, formik,
}) => {
  const { pathname } = useLocation();
  const { data } = useAppSelector((state) => state.strategyInfo);

  if ('invest_accounts' in values && 'vacant_accounts' in values) {
    accounts = [...values.invest_accounts, ...values.vacant_accounts];
    // reward_accounts = [...values.invest_accounts, ...values.vacant_accounts];
  }
  return (
    <FormGroup
      key={uuidv4()}
      className={styles.selector_main}
    >
      {('invest_accounts' in values || 'vacant_accounts' in values) && (
        pathname === route.root || pathname === `/strategies_rating/strategy/${data.id}`
      ) && (
        <Selector
          values={accounts}
          name={name}
          formik={formik}
          pickFirst
        />
      )}
      {'account' in values && pathname === route.my_subscriptions.catalog && (
        <Selector
          values={values}
          name={name}
          formik={formik}
          pickFirst
        />
      )}
      {'vacant_accounts' in values && pathname === route.my_strategies.catalog && (
        <Selector
          values={values.vacant_accounts} // если рендерится селектор по выбору типа вознаграждения отправляем true
          name={name}
          formik={formik}
          pickFirst
        />
      )}
      {Array.isArray(values) && 'strategy_type' in values[0] && (
        <Selector
          values={values}
          name={name}
          formik={formik}
          pickFirst
        />
      )}
      {Array.isArray(values) && 'label' in values[0] && ( // mobile sort selector
        <Selector
          values={values}
          name={name}
          formik={formik}
          pickFirst
        />
      )}
    </FormGroup>
  );
};
export default ModalSelector;
