import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useAppSelector } from '../../hooks/hooks';
import route from '../../app/routes/routes';
import CreateSubscriptionContent from './CreateSubscriptionContent/CreateSubscriptionContent';
import CreateStrategyContent from './CreateStrategyContent/CreateStrategyContent';
import EditSubscriptionContent from './EditSubscriptionContent/EditSubscriptionContent';

interface IProps {
  formik: FormikProps<any>,
  recommended_amount?: number
}
// TODO убрать этот компонент
const ModalContent: FC<IProps> = ({ formik, recommended_amount }) => {
  const { accounts } = useAppSelector((state) => state.customer); // достаем список счетов
  const { data } = useAppSelector((state) => state.strategyInfo);
  const { pathname } = useLocation();
  return (
    <>
      {(
        (pathname === route.root || pathname === `/strategies_rating/strategy/${data.id}`)
      )
      && (
      <CreateSubscriptionContent
        formik={formik}
        accounts={accounts}
        recommended_amount={recommended_amount}
      />
      )}
      {pathname === route.my_strategies.catalog
      && (
      <CreateStrategyContent
        formik={formik}
        accounts={accounts}
      />
      )}
      {pathname === route.my_subscriptions.catalog
      && (
      <EditSubscriptionContent
        formik={formik}
      />
      )}
    </>
  );
};

export default ModalContent;
