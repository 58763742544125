import React from 'react';
import { useMedia } from 'react-use';
import MyStrategiesDesktop from './MyStrategiesDesktop/MyStrategiesDesktop';
import MyStrategiesMobile from './MyStrategiesMobile/MyStrategiesMobile';

function MyStrategies() {
  const isMobile = useMedia('(max-width: 545px)');
  return (
    isMobile
      ? <MyStrategiesMobile />
      : <MyStrategiesDesktop />
  );
}

export default MyStrategies;
