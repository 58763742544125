import React, { useCallback, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './NotificationModal.module.scss';
import Text from '../Text/Text';
import { ButtonCancel, ButtonNormal } from '../../features/Buttons';
import successIcon from '../../shared/assets/icons/success_icon.svg';
import errorIcon from '../../shared/assets/icons/error_icon.svg';
import attentionIcon from '../../shared/assets/icons/attention_icon.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { notificationModalClose } from '../../store/notification/reducer';
import {
  deleteStrategyRequest,
  getActiveStrategiesRequest,
  getClosedStrategiesRequest,
} from '../../store/myStrategies/reducer';

import {
  pauseSubscriptionRequest,
  resumeSubscriptionRequest,
  deleteSubscriptionRequest,
} from '../../store/mySubscription/reducer';
import route from '../../app/routes/routes';
import { ReactModalEditSubscription } from '../../containers/ReactModalEditSubscription';
import { onRedirectHandler } from '../../handlers/handlers';

const NotificationModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toggle = () => dispatch(notificationModalClose());
  const [copyState, setCopyState] = useState(false);
  const { pathname } = useLocation();

  const { current_subscription } = useAppSelector((state) => state.mySubscriptions);
  const {
    request_id,
    type,
    isOpen,
    actionButton,
    cancelButton,
    label,
    message,
    props,
  } = useAppSelector((state) => state.notification.notification);
  const { create_account_url, deposit_funds_url } = useAppSelector((state) => state.customer.redirectUrls);
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    switch (actionButton) {
      case 'NOTIFICATION.DELETE_STRATEGY':
        dispatch(deleteStrategyRequest(Number(props)));
        if (pathname === route.my_strategies.catalog) {
          setTimeout(() => {
            dispatch(getActiveStrategiesRequest());
            dispatch(getClosedStrategiesRequest());
          }, 300);
        }
        setTimeout(() => {
          navigate(route.my_strategies.catalog, { replace: true });
        }, 300);
        break;
      case 'NOTIFICATION.PAUSE_BTN':
        dispatch(pauseSubscriptionRequest(Number(props)));
        break;
      case 'NOTIFICATION.RESUME_BTN':
        dispatch(resumeSubscriptionRequest(Number(props)));
        break;
      case 'NOTIFICATION.DELETE_SUBSCRIPTION':
        dispatch(deleteSubscriptionRequest(Number(props)));
        break;
      case 'MODAL.OK_BTN':
        if (pathname.includes('strategies_rating')) navigate(route.root, { replace: true });
        setTimeout(() => {
          document.body.style.overflow = 'visible';
        }, 400);
        if (pathname.includes('my_strategy')) navigate(route.my_strategies.catalog, { replace: true });
        setTimeout(() => {
          document.body.style.overflow = 'visible';
        }, 400);
        break;
      case 'NOTIFICATION.OPEN_ACCOUNT_BTN':
        onRedirectHandler(create_account_url);
        break;
      case 'MODAL.DEPOSIT':
        onRedirectHandler(deposit_funds_url);
        break;
      default:
        // при нажатии кнопки ОК не меняется в <body> overflow с hidden на visible,
        // установили сет таймаут для ручного переключения, надо смотреть под капот реакт страпа,
        // 400мс оптимально для перезаписи, если ставить меньше, то не перезаписывает
        setTimeout(() => {
          document.body.style.overflow = 'visible';
        }, 400);
    }
  }, [create_account_url, navigate, pathname, actionButton, dispatch, props]);
  return (
    <Modal
      isOpen={isOpen}
      centered
      className={styles.modal_window}
      cssModule={{ modal: styles.modal }}
      contentClassName={cx(
        styles.modal_content,
        {
          [styles.modal_content__request_id]: request_id,
        },
      )}
    >
      <ModalHeader
        className={styles.header}
        cssModule={{ 'btn-close': styles.btn_close }}
        toggle={toggle}
      >
        <div
          className={styles.title_wrapper}
        >
          { type === 'success' && <img src={successIcon} alt="success" /> }
          { type === 'error' && <img src={errorIcon} alt="error" /> }
          { type === 'attention' && <img src={attentionIcon} alt="attention" /> }

          <Text type="h1">{`${t(label)}`}</Text>
        </div>

      </ModalHeader>
      <ModalBody className={styles.modal_body}>
        <div
          className={styles.body_wrapper}
        >
          <Text type="notification-text">{`${t(message)}`}</Text>
          {/* {message === 'NOTIFICATION.DELETING_STRATEGY.SUCCESS' */}
          {/*  ? (<Text type="notification-text">{`${props} ${t(message)}`}</Text>) */}
          {/*  : (<Text type="notification-text">{`${t(message)}`}</Text>)} */}
        </div>
      </ModalBody>
      <ModalFooter
        className={styles.footer}
      >
        <div
          className={cx(styles.btn_wrapper)}
        >
          {
            actionButton && actionButton === 'NOTIFICATION.EDIT_BTN'
            && (
            <ReactModalEditSubscription
              title="MODAL.SUBSCRIBE_TITLE"
              button="NOTIFICATION.EDIT_BTN"
              args={current_subscription}
            />
            )
}
          {actionButton && actionButton !== 'NOTIFICATION.EDIT_BTN'
          && (
          <ButtonNormal
            onClick={() => {
              toggle();
              handleClick();
            }}
          >
            <Text type="button">{t(actionButton)}</Text>
          </ButtonNormal>
          )}
          {' '}
          {cancelButton && (
          <ButtonCancel
            onClick={toggle}
          >
            <Text type="button">{t(cancelButton)}</Text>
          </ButtonCancel>
          )}
        </div>
        {/* eslint-disable-next-line camelcase */}
        {request_id && (
        <div
          className={styles.body_wrapper}
        >
          <Text type="notification-text" color="gray">Request id:</Text>
          <CopyToClipboard
            text={request_id}
            onCopy={() => setCopyState(true)}
          >
            <Text
              type="notification-text"
              color="gray"
              className={cx(
                styles.requestID,
                {
                  [styles.requestID__copied]: copyState,
                },
              )}
            >
              {request_id}
            </Text>
          </CopyToClipboard>
        </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default NotificationModal;
