import {
  put, takeLeading, call,
} from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  tokenFail,
  tokenSuccess,
  getRedirectUrlsSuccess,
  getRedirectUrlsFail,
  getVacantAccountsSuccess,
  getVacantAccountsFail,
} from './reducer';
import {
  IResponseGetAccounts, IToken, IUrls, TXHeader,
} from './models';
import { baseUrl } from '../../shared/consts/baseUrl';
import { notificationModalOpen } from '../notification/reducer';

function* tokenSaga({ payload }: PayloadAction<IToken>) {
  try {
    const resp: { data: IToken } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: `auth/${payload}`,
    }));
    yield put(tokenSuccess(resp.data));
    sessionStorage.setItem('token', resp.data.token);
  } catch (e) {
    yield put(tokenFail((e as Error).message));
  }
}

function* getRedirectUrlSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: { data: IUrls } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'redirect_urls',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    yield put(getRedirectUrlsSuccess(resp.data));
  } catch (e: any) {
    yield put(getRedirectUrlsFail((e as Error).message));
    if (e.response.data.Code === 500) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.GET_REDIRECT_URLS.TITLE',
        message: `NOTIFICATION.GET_REDIRECT_URLS.${e.response.data.Key}`,
        actionButton: 'MODAL.OK_BTN',
        request_id: e.response.data.RequestID,
        type: 'error',
        isOpen: true,
      }));
    }
  }
}

function* getVacantAccountsSaga({ payload }: PayloadAction<string>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      headers: TXHeader,
      data: IResponseGetAccounts
    } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'account/vacant',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    /* если на беке исправят баг с отправкой счетов с любым балансом по енд поинту 'account/vacant'
    то необходимо убрать условие и оставить только экшен */
    if (payload === 'vacant') {
      // Фильтруем счета с балансом >= 100
      const filteredVacantAccounts = resp.data.vacant_accounts.filter(
        (account) => account.balance >= 100,
      );
      // Создаем новый объект с отфильтрованными счетами
      const filteredResponse: IResponseGetAccounts = {
        ...resp.data,
        vacant_accounts: filteredVacantAccounts,
      };
      if (filteredResponse.vacant_accounts.length) {
        yield put(getVacantAccountsSuccess(filteredResponse));
      } else {
        yield put(notificationModalOpen({
          label: 'NOTIFICATION.CREATING_STRATEGY.TITLE',
          message: 'NOTIFICATION.CREATING_STRATEGY.BALANCE_ERROR',
          actionButton: 'MODAL.DEPOSIT',
          cancelButton: 'MODAL.CANCEL_BTN',
          request_id: resp.headers['x-request-id'],
          type: 'error',
          isOpen: true,
        }));
      }
    } else {
      yield put(getVacantAccountsSuccess(resp.data));
    }

    // проверка на уведомление
    if (
      payload === 'vacant'
      && !resp.data.vacant_accounts.length
    ) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.CREATING_STRATEGY.TITLE',
        message: 'NOTIFICATION.CREATING_STRATEGY.ERROR',
        actionButton: 'NOTIFICATION.OPEN_ACCOUNT_BTN',
        cancelButton: 'MODAL.CANCEL_BTN',
        request_id: resp.headers['x-request-id'],
        type: 'error',
        isOpen: true,
      }));
    } else if (
      payload === 'all'
      && resp.data.vacant_accounts.length === 0
      && resp.data.invest_accounts.length === 0
    ) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.SUBSCRIBE_STRATEGY.TITLE',
        message: 'NOTIFICATION.SUBSCRIBE_STRATEGY.ERROR',
        actionButton: 'NOTIFICATION.OPEN_ACCOUNT_BTN',
        cancelButton: 'MODAL.CANCEL_BTN',
        request_id: resp.headers['x-request-id'],
        type: 'error',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    // проверка на уведомление
    yield put(getVacantAccountsFail((e as Error).message));
    if (
      payload === 'vacant'
    ) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.CREATING_STRATEGY.TITLE',
        message: `NOTIFICATION.GET_VACANT_ACCOUNTS.${e.response.data.Key}`,
        actionButton: 'MODAL.OK_BTN',
        request_id: e.response.data.RequestID,
        type: 'error',
        isOpen: true,
      }));
    } else if (
      payload === 'all'
    ) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.SUBSCRIBE_STRATEGY.TITLE',
        message: `NOTIFICATION.GET_VACANT_ACCOUNTS.${e.response.data.Key}`,
        actionButton: 'MODAL.OK_BTN',
        request_id: e.response.data.RequestID,
        type: 'error',
        isOpen: true,
      }));
    }
  }
}

export function* customerWatcher() {
  yield takeLeading('customer/tokenRequest', tokenSaga);
  yield takeLeading('customer/getRedirectUrlsRequest', getRedirectUrlSaga);
  yield takeLeading('customer/getVacantAccountsRequest', getVacantAccountsSaga);
}
