import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import route from '../../app/routes/routes';

interface IAuth {
  children: React.ReactElement,
  token: string | null
}
const AuthProvider = ({ children, token }: IAuth) => {
  const { pathname } = useLocation();
  const id = pathname.replace(/[^0-9]/g, '');
  if (
    !token && (
      pathname !== route.not_found_page.catalog
      && pathname !== `/strategies_rating/strategy/${id}`
      && pathname !== route.root
      && pathname !== '/'
    )) {
    return <Navigate to={route.not_found_page.catalog} replace />;
  }
  if (!token && pathname === route.strategy_information.catalog) {
    return <Navigate to={route.strategy_information.catalog} replace />;
  }
  return children;
};

export default AuthProvider;
