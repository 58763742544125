import React, { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import styles from './Feedback.module.scss';
import Text from '../Text/Text';

interface IFeedback {
    formik: FormikProps<any>,
    name: string,
    isFocus: boolean,
    field: string,
    isHideHint?: boolean
    className?: string
}
let formikError: any;
const Feedback: FC<IFeedback> = (
  {
    formik,
    name,
    isFocus,
    field,
    isHideHint,
    className,
  },
) => {
  const { t } = useTranslation();
  let valueHint;
  // eslint-disable-next-line default-case
  switch (name) {
    case 'fixed_vol':
      valueHint = t('MODAL.VALIDATE_HINT_LOT_VOLUME');
      break;
    case 'percent_vol':
      valueHint = t('MODAL.VALIDATE_HINT_RATIO');
      break;
    case 'strategy_name':
      valueHint = t('MODAL.VALIDATE_HINT');
      break;
    case 'recommended_amount':
      valueHint = t('MODAL.VALIDATE_HINT_RECOMMENDED_VALUE');
      break;
    case 'searchInput':
      valueHint = t('STRATEGIES_RATING.VALIDATE_HINT_SEARCH');
      break;
  }
  formikError = formik.errors[`${name}`];
  return (
    <>
      {
        isFocus && !isHideHint && field === name
        && (
        <div
          className={cx(
            styles.hint,
            className,
          )}
        >
          <Text type="text">
            {valueHint}
          </Text>
        </div>
        )
    }
      {
          formik.touched[`${name}`]
          && formik.errors[`${name}`]
          && (field !== name || !isFocus)
          && (
          <div
            className={cx(
              styles.error,
              className,
            )}
          >
              {typeof formikError === 'string'
                  && (
                  <Text type="text">
                    {formikError}
                  </Text>
                  )}
          </div>
          )
            }
    </>
  );
};

export default Feedback;
