import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationProps } from './models';

interface INotificationState {
    notification: INotificationProps
}
const initialState: INotificationState = {
  notification: {
    label: '',
    message: '',
    actionButton: '',
    cancelButton: '',
    request_id: '',
    props: '',
    type: '',
    isOpen: false,
  },
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationModalOpen(state, action: PayloadAction<INotificationProps>) {
      state.notification = action.payload;
    },
    notificationModalClose(state) {
      state.notification = initialState.notification;
    },
  },
});
export const {
  notificationModalOpen,
  notificationModalClose,
} = notificationSlice.actions;
export default notificationSlice.reducer;
