import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  currSortMethod,
  currSortType,
  IRequestCreateStrategy,
  IResponseGetActiveStrategies,
  IResponseGetClosedStrategies,
  IRewardTypes,
  IStrategyState,
} from './models';

const initialState: IStrategyState = {
  allStrategies: {
    active: {
      active_strategies: [],
      isLoading: false,
      error: '',
    },
    closed: {
      closed_strategies: [],
      isLoading: false,
      error: '',
    },
    sort: {
      sort_types: ['total_pnl', 'total_followers', 'balance', 'equity', 'income_rewards', 'account'],
      curr_sort_type: 'account',
      curr_sort_method: 'default',
    },
  },
  rewards: {
    rewardTypes: [],
    isLoading: false,
    error: '',
  },
  info: {
    isActive: true,
    validationScheme: 1,
  },
  isLoading: false,
  error: '',
};

const myStrategiesSlice = createSlice({
  name: 'myStrategies',
  initialState,
  reducers: {
    setStrategyContent(state, action: PayloadAction<boolean>) {
      state.info.isActive = action.payload;
    },
    changeValidScheme(state, action: PayloadAction<number>) {
      state.info.validationScheme = action.payload;
    },
    // -- создание стратегии
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createStrategyRequest(state, _: PayloadAction<IRequestCreateStrategy>) {
      state.isLoading = true;
    },
    createStrategySuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    createStrategyFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- удаление стратегии
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteStrategyRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
    },
    deleteStrategySuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    deleteStrategyFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- получение списка вознаграждений
    getRewardRequest(state) {
      state.rewards.isLoading = true;
    },
    getRewardSuccess(state, action: PayloadAction<IRewardTypes[]>) {
      state.rewards.rewardTypes = action.payload;
      state.rewards.isLoading = false;
      state.rewards.error = '';
    },
    getRewardFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- получение активных стратегий
    getActiveStrategiesRequest(state) {
      state.allStrategies.active.isLoading = true;
    },
    getActiveStrategiesSuccess(state, action: PayloadAction<IResponseGetActiveStrategies[]>) {
      state.allStrategies.active.active_strategies = action.payload;
      state.allStrategies.active.isLoading = false;
      state.allStrategies.active.error = '';
    },
    getActiveStrategiesFail(state, action: PayloadAction<string>) {
      state.allStrategies.active.isLoading = false;
      state.allStrategies.active.error = action.payload;
    },
    // -- получение закрытых стратегий
    getClosedStrategiesRequest(state) {
      state.allStrategies.closed.isLoading = true;
    },
    getClosedStrategiesSuccess(state, action: PayloadAction<IResponseGetClosedStrategies[]>) {
      state.allStrategies.closed.closed_strategies = action.payload;
      state.allStrategies.closed.isLoading = false;
      state.allStrategies.closed.error = '';
    },
    getClosedStrategiesFail(state, action: PayloadAction<string>) {
      state.allStrategies.closed.isLoading = false;
      state.allStrategies.closed.error = action.payload;
    },
    changeSortType(state, action: PayloadAction<currSortType>) {
      state.allStrategies.sort.curr_sort_type = action.payload;
    },
    changeSortMethod(state, action: PayloadAction<currSortMethod>) {
      state.allStrategies.sort.curr_sort_method = action.payload;
    },
  },
});
export const {
  setStrategyContent,
  changeValidScheme,
  createStrategyRequest,
  createStrategySuccess,
  createStrategyFail,
  deleteStrategyRequest,
  deleteStrategySuccess,
  deleteStrategyFail,
  getRewardRequest,
  getRewardSuccess,
  getRewardFail,
  getActiveStrategiesRequest,
  getActiveStrategiesSuccess,
  getActiveStrategiesFail,
  getClosedStrategiesRequest,
  getClosedStrategiesSuccess,
  getClosedStrategiesFail,
  changeSortType,
  changeSortMethod,
} = myStrategiesSlice.actions;
export default myStrategiesSlice.reducer;
