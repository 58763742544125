/* eslint-disable max-len */
import React, {
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';
import cx from 'classnames';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';
import styles from './Selector.module.scss';
import Text from '../Text/Text';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { changeValidScheme } from '../../store/myStrategies/reducer';
import { setBalance } from '../../store/customer/reducer';
import route from '../../app/routes/routes';
import { IRewardTypes, ISubscriptionsMobileSelector } from '../../store/myStrategies/models';
import { IVacantAccounts } from '../../store/customer/models';
import mobileSelectorUp from '../../shared/assets/icons/mobileSelectorUp.svg';
import mobileSelectorDown from '../../shared/assets/icons/mobileSelectorDown.svg';
import { setCurrentSortLabel } from '../../store/strategiesRating/reducer';
import { setCurrentSubsSortLabel } from '../../store/mySubscription/reducer';
import { USD } from '../../shared/consts/consts';
import { IValues } from '../../containers/ModalSelectors/ModalSelector';

interface ISelectorProps {
  values: IValues | any,
  name: string,
  formik: FormikProps<any>,
  pickFirst?: boolean,
}
const Selector: FC<ISelectorProps> = ({
  values, name, formik, pickFirst,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const toggle = () => setIsOpen(!isOpen);
  const { master_account, info } = useAppSelector((state) => state.modal);
  const { id } = info;
  const { type_of_copy } = useAppSelector((state) => state.mySubscriptions.current_subscription);
  const sortedValues = useMemo(() => {
    const copyValues = [...values];
    if (Array.isArray(copyValues) && 'account' in copyValues[0] && copyValues.length > 1) {
      return copyValues.sort(
        (a: { account: number; balance: number; }, b: { account: number; balance: number; }) => b.balance - a.balance || a.account - b.account,
      );
    }
    return copyValues;
  }, [values]);
  // если есть true и input пустой, то сразу выбираем первое значение из массива
  setTimeout(() => {
    // выпадание первого элемента
    if (pickFirst && !formik.values[name]) {
      switch (name) {
        case 'strategy_reward':
          formik.setFieldValue(name, t(`MODAL.REWARD_TYPE_${values[0].strategy_type}`));
          break;
        case 'vacant_accounts':
          formik.setFieldValue(name, sortedValues[0].account);
          formik.setFieldValue('balance', sortedValues[0].balance);
          dispatch(setBalance(sortedValues[0].balance));
          break;
        case 'invest_accounts':
          formik.setFieldValue(name, sortedValues[0].account);
          formik.setFieldValue('balance', sortedValues[0].balance);
          dispatch(setBalance(sortedValues[0].balance));
          break;
        case 'edit_account':
          formik.setFieldValue(name, values.account);
          formik.setFieldValue('id', values.id);
          formik.setFieldValue('type_of_copy', values.type_of_copy);
          if (master_account) {
            formik.setFieldValue('balance', values.balance);
            dispatch(setBalance(values.balance));
          }
          if (values.type_of_copy === id) {
            formik.setFieldValue('fixed_vol', values.fixed_vol === 0 ? '' : values.fixed_vol);
            formik.setFieldValue('percent_vol', values.percent_vol === 0 ? '' : values.percent_vol);
            formik.setFieldValue('stop_loss', values.stop_loss === 0 ? '' : values.stop_loss);
            formik.setFieldValue('take_profit', values.take_profit === 0 ? '' : values.take_profit);
            formik.setFieldValue('stop_loss_toggle', values.stop_loss > 0);
            formik.setFieldValue('take_profit_toggle', values.take_profit > 0);
          }
          break;
        case 'label':
          // фильтруем по pathname как именно заполнять форму мобильного выбора типа вознаграждения
          if (pathname === route.my_subscriptions.catalog) {
            formik.setFieldValue(name, t(values.find(
              (el: ISubscriptionsMobileSelector) => el.id === type_of_copy,
            ).label));
            formik.setFieldValue('id', type_of_copy);
          } else {
            formik.setValues({ label: t(values[0].label), id: values[0].id });
          }
          break;
        case 'mobileSelector':
          if (pathname === route.my_subscriptions.catalog) {
            dispatch(setCurrentSubsSortLabel(t(values[1].label)));
          } else {
            dispatch(setCurrentSortLabel(t(values[1].label)));
          }
          formik.setFieldValue(name, t(values[1].label));
          break;
        default:
          break;
      }
    }
  });
  const handleClick = useCallback((value: any) => {
    // reset форм если клиент решил сменить тип вознаграждения при выбранном value или счет при выбранном TP или SL
    if (
      formik.touched.strategy_reward_value
        || formik.touched.stop_loss
        || formik.touched.take_profit
        || formik.touched.fixed_vol
        || formik.touched.percent_vol
    ) formik.handleReset();
    formik.setFieldValue(name, t(
      value.account
        || ('strategy_type' in value && `MODAL.REWARD_TYPE_${value.strategy_type}`),
    ));
    //  добавление баланса в форму
    formik.setFieldValue('balance', value.balance ?? formik.values.balance);
    // смена валидационной схемы по выбору стратегии
    if ('strategy_type' in value) dispatch(changeValidScheme(value.strategy_type));
    if ('balance' in value) dispatch(setBalance(value.balance));
    if ('label' in value) {
      formik.setValues({ label: t(value.label), id: value.id });
      formik.handleSubmit();
    }
  }, [dispatch, formik, name, t]);

  const handleIsOpenChange = useDebouncedCallback((value: boolean) => {
    setIsOpen(value);
  }, 200);
  useEffect(() => {
    if (isOpen && menuRef && menuRef.current) {
      // @ts-ignore
      const menu: HTMLDivElement = menuRef.current;
      menu.focus({ preventScroll: true });
    }
  }, [
    isOpen,
  ]);
  const handleMenuBlur = useCallback(({ currentTarget, relatedTarget }: any) => {
    if (currentTarget.contains(relatedTarget)) return;
    handleIsOpenChange(false);
  }, [handleIsOpenChange]);

  return (
    <>
      <div
        className={cx(
          styles.dropdown__btn,
          {
            [styles.dropdown__btn__focus]: isOpen,
          },
          {
            [styles.dropdown__btn__noArrowImg]: (Array.isArray(values) && 'account' in values[0] && values.length < 2)
            || 'account' in values,
          },
          {
            [styles.dropdown__btn__noArrowImg__noPointer]: 'account' in values,
          },
        )}
        onClick={toggle}
      >
        <Input
          className={cx(
            styles.input,
            {
              [styles.input__noPointer]: 'account' in values,
            },
          )}
          {...formik.getFieldProps(name)}
          disabled
        />
        {
          (
            (Array.isArray(values) && values[0]?.balance !== undefined)
              || values?.balance !== undefined
          )
          && formik.values.balance !== undefined
          // отображение баланса и USD
          && (
          <Text type="text" color="blue">
            {formik.values.balance}
            {' '}
            {USD}
          </Text>
          )
        }
        {/* для редакитрования подписки приходит объект и условие ниже дает false ,
         стрелка не отображается (поведение верное) */}
        {(Array.isArray(values) && values.length > 1)
            && (
            <div
              className={styles.dropdown__btn__arrow}
            >
              {isOpen ? <img src={mobileSelectorUp} alt="" /> : <img src={mobileSelectorDown} alt="" />}
            </div>
            )}
      </div>

      {isOpen && (
        Array.isArray(sortedValues)
          && 'account' in sortedValues[0])
          && sortedValues.length > 1
          && (
            <div
              className={cx(
                styles.dropdown__content,
              )}
              tabIndex={-1}
              onBlur={handleMenuBlur}
            >
              {sortedValues.map((value: IVacantAccounts) => (
                <div
              // селектор по выбору счета
                  ref={menuRef}
                  key={uuidv4()}
                  tabIndex={-1}
                  role="listbox"
                  onClick={() => {
                    handleClick(value);
                    handleIsOpenChange(false);
                  }}
                  className={cx(
                    styles.dropdown__content__item,
                    {
                      [styles.dropdown__content__item__isActive]:
                      value.account === formik.getFieldProps(name).value,
                    },
                  )}
                >
                  <Text type="text">
                    {'account' in value && value.account}
                  </Text>
                  <Text type="text">
                    {'balance' in value ? `${value.balance}  ${USD}` : ''}
                  </Text>
                </div>
              ))}
            </div>
          )}

      {isOpen
          && (
            Array.isArray(values) && 'strategy_type' in values[0]
          )
          && (
          <div className={cx(
            // className,
            styles.dropdown__content,
          )}
          >
            {values.map((value: IRewardTypes) => (
              <div
              // селектор по выбору вознаграждения
                ref={menuRef}
                key={uuidv4()}
                tabIndex={-1}
                role="listbox"
                onBlur={handleMenuBlur}
                onClick={() => {
                  handleClick(value);
                  handleIsOpenChange(false);
                }}
                className={cx(
                  styles.dropdown__content__item,
                  {
                    [styles.dropdown__content__item__isActive]:
                      t(`MODAL.REWARD_TYPE_${value.strategy_type}`) === formik.getFieldProps(name).value,
                  },
                )}
              >
                <Text type="text">
                  {t(`MODAL.REWARD_TYPE_${value.strategy_type}`)}
                </Text>
              </div>
            ))}
          </div>
          )}

      {isOpen && (Array.isArray(values) && 'label' in values[0]) && (
      <div className={cx(
        styles.dropdown__content,
        {
          // стиль для выпадайки фильтра мобильной версии
          [styles.dropdown__content__filter]: (Array.isArray(values) && 'label' in values[0]),
        },
      )}
      >
        {values.map((value: any) => (
          <div
              // селектор по типов сортировки
            ref={menuRef}
            key={uuidv4()}
            tabIndex={-1}
            role="listbox"
                  // @ts-ignore
            onBlur={handleMenuBlur}
            onClick={() => {
              handleClick(value);
              handleIsOpenChange(false);
            }}
            className={cx(
              styles.dropdown__content__item,
              {
                [styles.dropdown__content__item__isActive]:
                        t(value.label) === formik.getFieldProps(name).value,
              },
            )}
          >
            <Text type="text">
              {t(value.label)}
            </Text>
          </div>
        ))}
      </div>
      )}
    </>
  );
};

export default Selector;
