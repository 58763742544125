/* eslint-disable react/no-danger */
import React, { FC, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './ReactModalInfo.module.scss';
import Text from '../../../entities/Text/Text';
import {
  ButtonInfo,
} from '../../../features/Buttons';

interface IModalInfo {
  title: string,
  content: string
}

export const ReactModalInfo: FC<IModalInfo> = ({ title, content }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const windowInnerWidth = window.innerWidth;
  return (
    <>
      <ButtonInfo
        onClick={toggle}
      />
      <Modal
        isOpen={modal}
        centered
        className={styles.modal_window}
        contentClassName={styles.modal_content}
        backdrop={windowInnerWidth > 545}
      >
        <ModalHeader
          className={styles.header}
          cssModule={{ 'btn-close': styles.btn_close }}
          toggle={toggle}
        >
          <Text type="h1">{t(title)}</Text>
        </ModalHeader>
        <ModalBody
          className={styles.modal_body}
        >
          <div
            className={cx(styles.info_content_wrapper)}
          >
            {/* Для реадиктирования текста(абзацы и тд) через сервис переводов */}
            <div
              className={cx(styles.content)}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
