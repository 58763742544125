import React from 'react';
import { FormikProps } from 'formik';
import styles from './ToggleSwitch.module.scss';
import Text from '../Text/Text';
import { useAppSelector } from '../../hooks/hooks';

interface IToggleSwitch {
    label: string;
    name: string; // "stop_loss_toggle" или "take_profit_toggle"
    formik: FormikProps<any>
}
const ToggleSwitch: React.FC<IToggleSwitch> = ({
  label, name, formik,
}) => {
  const { current_subscription } = useAppSelector((state) => state.mySubscriptions);

  const handleClick = () => {
    if (formik.values[name]) {
      switch (name) {
        case 'stop_loss_toggle':
          if (formik.values.stop_loss !== '') {
            formik.setFieldValue('stop_loss', '');
            formik.setFieldTouched('stop_loss', false);
          } else {
            formik.setFieldTouched('stop_loss', false);
          }
          break;
        case 'take_profit_toggle':
          if (formik.values.take_profit !== '') {
            formik.setFieldValue('take_profit', '');
            formik.setFieldTouched('take_profit', false);
          } else {
            formik.setFieldTouched('stop_loss', false);
          }
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case 'stop_loss_toggle':
          if (formik.values.stop_loss === '') {
            formik.setFieldValue('stop_loss', current_subscription.stop_loss === 0
              ? ''
              : current_subscription.stop_loss);
          }
          break;
        case 'take_profit_toggle':
          if (formik.values.take_profit === '') {
            formik.setFieldValue('take_profit', current_subscription.take_profit === 0
              ? ''
              : current_subscription.take_profit);
          }
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className={styles.toggle_text_container}>
      <div className={styles.toggle_switch}>
        <input
          type="checkbox"
          className={styles.checkbox}
          id={label}
          {...formik.getFieldProps(name)}
          checked={formik.values[name]}
          onClick={handleClick}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className={styles.label}
          htmlFor={label}
        >
          <span className={styles.inner} />
          <span className={styles.switch} />
        </label>
      </div>
      <Text type="text">{label}</Text>
    </div>
  );
};

export default ToggleSwitch;
