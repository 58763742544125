import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import cx from 'classnames';
import ReactGA from 'react-ga4';
import CombineRouter from './routes/CombineRouter';
import styles from './index.module.scss';
import Layout from './layout/layout';
import NotificationModal from '../entities/NotificationModal/NotificationModal';

const trackerId = process.env.REACT_APP_GA_TRACKING_CODE;
function Index() {
  useEffect(() => {
    if (trackerId) ReactGA.initialize(trackerId);
    ReactGA.send('pageview');
  }, []);
  console.log('version 1.6');
  return (
    <div className="App">
      <Container fluid className={cx(styles.app_container)}>
        <Layout>
          <CombineRouter />
        </Layout>
        <NotificationModal />
      </Container>
    </div>
  );
}

export default Index;
// npm -v
// 8.1.2
// node -v
// v16.13.2
