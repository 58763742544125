import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Text from '../../../entities/Text/Text';
import styles from './ButtonToggle.module.scss';
import { useAppSelector } from '../../../hooks/hooks';

interface IButtonToggle {
  onClickContent: any,
  isStrategies?: boolean,
  isSubscriptions?: boolean,
  isMyStrategy?: boolean,
  isToggled?: boolean
}
export const ButtonToggle = ({
  onClickContent, isStrategies, isSubscriptions, isMyStrategy, isToggled,
}: IButtonToggle) => {
  const { t } = useTranslation();
  const isActiveStrategies = useAppSelector((state) => state.myStrategies.info.isActive);
  const isActiveSubscriptions = useAppSelector((state) => state.mySubscriptions.info.isActive);
  return (
    <>
      {
        isStrategies && (
          <div className={cx(styles.toggle_wrapper)}>
            <ButtonGroup
              cssModule={{ 'btn-group': styles.btn_group }}
            >
              <Button
                onClick={() => onClickContent(true)}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: isActiveStrategies,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.ACTIVE_BTN')}</Text>
              </Button>
              <Button
                onClick={() => onClickContent(false)}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: !isActiveStrategies,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.CLOSED_BTN')}</Text>
              </Button>
            </ButtonGroup>
          </div>
        )
      }
      {
        isSubscriptions && (
          <div className={cx(styles.toggle_wrapper)}>
            <ButtonGroup
              cssModule={{ 'btn-group': styles.btn_group }}
            >
              <Button
                onClick={() => onClickContent(true)}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: isActiveSubscriptions,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.ACTIVE_BTN')}</Text>
              </Button>
              <Button
                onClick={() => onClickContent(false)}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: !isActiveSubscriptions,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.CLOSED_BTN')}</Text>
              </Button>
            </ButtonGroup>
          </div>
        )
      }
      {
          isMyStrategy && (
          <div className={cx(styles.toggle_wrapper)}>
            <ButtonGroup
              cssModule={{ 'btn-group': styles.btn_group }}
            >
              <Button
                onClick={onClickContent}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: isToggled,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.MY_PARAMS_BTN')}</Text>
              </Button>
              <Button
                onClick={onClickContent}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: !isToggled,
                  },
                )}
              >
                <Text type="button">{t('MY_STRATEGIES.RATING_BTN')}</Text>
              </Button>
            </ButtonGroup>
          </div>
          )
      }

    </>
  );
};
