/* eslint-disable react-hooks/exhaustive-deps,max-len */
import cx from 'classnames';
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'reactstrap';
import styles from './MyStrategiesMobile.module.scss';
import Text from '../../../entities/Text/Text';
import { ButtonToggle } from '../../../features/ButtonToggle';
import { ReactModalStrategy } from '../../../containers/ReactModalStrategy';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  changeSortMethod,
  changeSortType,
  getActiveStrategiesRequest,
  getActiveStrategiesSuccess,
  getClosedStrategiesRequest,
  setStrategyContent,
} from '../../../store/myStrategies/reducer';
import createStrategyIcon from '../../../shared/assets/icons/create-strategy-icon.svg';
import depositIcon from '../../../shared/assets/icons/deposit-icon.svg';
import withdrawIcon from '../../../shared/assets/icons/withdraw-icon.svg';
import showIcon from '../../../shared/assets/icons/show-icon.svg';
import hideIcon from '../../../shared/assets/icons/hide-icon.svg';
import controlIcon from '../../../shared/assets/icons/control-icon.svg';
import activeControlIcon from '../../../shared/assets/icons/active-control-icon.svg';
import deleteIcon from '../../../shared/assets/icons/delete-icon.svg';

import { ButtonManage } from '../../../features/Buttons';
import ModalSelector from '../../../containers/ModalSelectors/ModalSelector';
import { notificationModalOpen } from '../../../store/notification/reducer';
import { IMyStrategy, currSortMethod, currSortType } from '../../../store/myStrategies/models';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import { USD } from '../../../shared/consts/consts';
import { FeeComponent } from '../../../entities/FeeComponent/FeeComponent';

interface IInitialValues {
  mobileSelector: string
}

const initialValues = {
  mobileSelector: '',
};
const mobileSelector = [
  { label: 'MY_STRATEGIES.ACCOUNT_NUMBER' },
  { label: 'MY_STRATEGIES.PROFIT_LOSS' },
  { label: 'MY_STRATEGIES.SUBSCRIBERS' },
  { label: 'MY_STRATEGIES.BALANCE' },
  { label: 'MY_STRATEGIES.FUNDS' },
  // { label: 'MY_STRATEGIES.PAID' },
];

function MyStrategyMobile() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClick = (e: boolean) => { // переключение типа контента активные/закрытые
    dispatch(setStrategyContent(e));
  };
  const { isLoading, info, allStrategies } = useAppSelector((state) => state.myStrategies);
  const { active, closed, sort } = allStrategies;
  const { isActive } = info;
  const loadingStatus = isLoading
    || active.isLoading
    || closed.isLoading;
  const sortedData: IMyStrategy = JSON.parse(JSON.stringify(allStrategies));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
  } = sort;
  useEffect(() => {
    dispatch(getActiveStrategiesRequest());
    dispatch(getClosedStrategiesRequest());
    if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
    if (curr_sort_type !== 'account') dispatch(changeSortType('account'));
  }, [dispatch]);

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    switch (sortMethod) {
      case 'default':
        dispatch(changeSortMethod('down'));
        sortedData.active.active_strategies.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
        dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
        break;
      case 'up':
        dispatch(changeSortMethod('down'));
        sortedData.active.active_strategies.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
        dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
        break;
      case 'down':
        dispatch(changeSortMethod('up'));
        sortedData.active.active_strategies.sort((a, b) => a[`${sortType}`] - b[`${sortType}`]);
        dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
        break;
      default:
        break;
    }
  }, [dispatch, sortedData]);
  const formik = useFormik<IInitialValues>({
    initialValues,
    onSubmit: (sortValue) => {
      switch (sortValue.mobileSelector) {
        case t('MY_STRATEGIES.ACCOUNT_NUMBER'):
          dispatch(changeSortType(sort_types[5]));
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[5]}`] - b[`${sort_types[5]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case t('MY_STRATEGIES.PROFIT_LOSS'):
          dispatch(changeSortType(sort_types[0]));
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[0]}`] - b[`${sort_types[0]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case t('MY_STRATEGIES.SUBSCRIBERS'):
          dispatch(changeSortType(sort_types[1]));
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[1]}`] - b[`${sort_types[1]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case t('MY_STRATEGIES.BALANCE'):
          dispatch(changeSortType(sort_types[2]));
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[2]}`] - b[`${sort_types[2]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case t('MY_STRATEGIES.FUNDS'):
          dispatch(changeSortType(sort_types[3]));
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        // case t('MY_STRATEGIES.PAID'):
        //   dispatch(changeSortType(sort_types[4]));
        //   dispatch(changeSortMethod('up'));
        //   sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[4]}`] - b[`${sort_types[4]}`]);
        //   dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
        //   break;
        default:
          break;
      }
    },
  });
  const [showActive, setShowActive] = useState([0]);
  const [showControl, setShowControl] = useState(0);
  const menuRef = useRef(null);
  const {
    deposit_funds_url, withdraw_funds_url,
  } = useAppSelector((state) => state.customer.redirectUrls);
  const depositClick = () => {
    window.location.assign(deposit_funds_url);
  };
  const withdrawClick = () => {
    window.location.assign(withdraw_funds_url);
  };
  const onDeleteStrategyHandler = async (strategyId: number) => {
    dispatch(notificationModalOpen({
      label: 'NOTIFICATION.DELETING_STRATEGY.TITLE',
      message: 'NOTIFICATION.DELETING_STRATEGY.ATTENTION',
      actionButton: 'NOTIFICATION.DELETE_STRATEGY',
      cancelButton: 'MODAL.CANCEL_BTN',
      props: strategyId,
      type: 'attention',
      isOpen: true,
    }));
  };
  useEffect(() => {
    if (showControl && menuRef && menuRef.current) {
      // @ts-ignore
      const menu: HTMLDivElement = menuRef.current;
      menu.focus();
    }
  }, [
    showControl,
  ]);
  return (
    <div className={cx(styles.my_strategies_mobile)}>
      <div className={styles.title}>
        <Text type="h1">
          {t('MY_STRATEGIES.PAGE_TITLE')}
        </Text>
        {isActive
          && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== ''
          && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== 'MY_STRATEGIES.INFO_CONTENT'
          && (
            <ReactModalInfo
              title={t('OTHER.INFO_TITLE')}
              content={t('MY_SUBSCRIPTIONSS.INFO_CONTENT')}
            />
          )}
        {!isActive
          && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== ''
          && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== 'MY_STRATEGIES.INFO_CONTENT_CLOSED'
          && (
            <ReactModalInfo
              title={t('OTHER.INFO_TITLE')}
              content={t('MY_STRATEGIES.INFO_CONTENT_CLOSED')}
            />
          )}
      </div>

      <div className={cx(styles.action_buttons_wrapper)}>
        <ReactModalStrategy
          title="MODAL.CREATING_STRATEGY"
          args=""
          icon={createStrategyIcon}
        />
        <ButtonManage
          onClick={depositClick}
          icon={depositIcon}
          handleReset={formik.handleReset}
          dispatch={dispatch}
          actions={[]}
        />
        <ButtonManage
          onClick={withdrawClick}
          icon={withdrawIcon}
          handleReset={formik.handleReset}
          dispatch={dispatch}
          actions={[]}
        />
      </div>

      <ButtonToggle isStrategies onClickContent={handleClick} />
      {loadingStatus
        && (
          <Spinner
            className={styles.spinner}
          />
        )}
      {!loadingStatus
        && isActive
        && active.active_strategies.length === 0
        && closed.closed_strategies.length === 0
        && (
          <div className={styles.notification_text_wrapper}>
            <Text type="page-notification-mobile">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_1')}
            </Text>
            <Text type="page-notification-mobile">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_2')}
            </Text>
          </div>
        )}
      {!loadingStatus
        && isActive
        && active.active_strategies.length === 0
        && closed.closed_strategies.length > 0
        && (
          <div className={styles.notification_text_wrapper}>
            <Text type="page-notification-mobile">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_3')}
            </Text>
            <Text type="page-notification-mobile">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_2')}
            </Text>
          </div>
        )}
      {!isLoading
        && !active.isLoading
        && isActive && active.active_strategies.length > 0
        && (
        <>
          <div className={cx(styles.filter_block)}>
            <ModalSelector
              values={mobileSelector}
              name="mobileSelector"
              formik={formik}
            />
            <div className={cx(styles.filter_icon)}>
              <ArrowSwitcher
                onClick={() => handleSortClick(curr_sort_type, curr_sort_method)}
                currSort={curr_sort_type}
                sortMethod={curr_sort_method}
              />
            </div>
          </div>
          {active.active_strategies.map((value) => (
            <div
              key={uuidv4()}
              className={cx(
                styles.strategy_card,
                { [styles.strategy_card__active]: showActive.includes(value.id) },
              )}
            >

              <div
                className={styles.show_icon}
                onClick={() => (
                  showActive.includes(value.id)
                    ? setShowActive((prev) => prev.filter((el) => el !== value.id))
                    : setShowActive((prev) => [...prev, value.id])
                )}
              >
                <img
                  src={showActive.includes(value.id) ? hideIcon : showIcon}
                  alt=""
                />
              </div>
              <div
                className={cx(
                  styles.strategy_title_wrapper,
                  { [styles.strategy_title_wrapper__active]: showActive.includes(value.id) },
                )}
                onClick={() => setShowActive((prev) => [...prev, value.id])}
              >

                <div className={styles.strategy_title}>
                  <Text
                    type="h1"
                    className={cx(
                      styles.strategy_name,
                      { [styles.strategy_name__active]: showActive.includes(value.id) },
                    )}
                  >
                    {value.name}
                  </Text>
                </div>

                <div className={cx(
                  styles.account_number_wrapper,
                  { [styles.account_number_wrapper__active]: showActive.includes(value.id) },
                )}
                >
                  <Text type="text">{t('MY_STRATEGIES.ACCOUNT_NUMBER')}</Text>
                  {' '}
                  <Text type="text">
                    {value.account}
                  </Text>
                </div>

              </div>

              <div
                className={cx(
                  styles.info_block,
                  { [styles.info_block__active]: showActive.includes(value.id) },
                )}
                onClick={() => setShowActive((prev) => [...prev, value.id])}
              >
                <div className={cx(styles.info_item)}>
                  <Text
                    type="h1"
                    className={cx(
                      {
                        [styles.green_font]: (showActive.includes(value.id) && value.total_pnl > 0),
                        [styles.red_font]: (showActive.includes(value.id) && value.total_pnl < 0),
                        [styles.black_font]: (showActive.includes(value.id) && value.total_pnl === 0),
                      },
                    )}
                  >
                    {
                      (value.total_pnl > 0 && `+${+value.total_pnl.toFixed(2)} ${USD}`)
                      || (value.total_pnl === 0 && `0 ${USD}`)
                      || (value.total_pnl < 0 && `${+value.total_pnl.toFixed(2)} ${USD}`)
                    }
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.PROFIT_LOSS')}
                  </Text>
                </div>
                <div className={cx(styles.info_item)}>
                  <Text type="h1">
                    {value.total_followers}
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.SUBSCRIBERS')}
                  </Text>
                </div>

                <div
                  className={cx(
                    styles.control_block_wrapper,
                    { [styles.control_block_wrapper__active]: showActive.includes(value.id) },
                  )}
                  role="button"
                >
                  <div
                    className={cx(styles.control_icon)}
                    onClick={() => (showControl === value.id ? setShowControl(0) : setShowControl(value.id))}
                  >
                    <img src={showControl === value.id ? activeControlIcon : controlIcon} alt="" />
                  </div>

                  {
                    showControl === value.id && (
                      <div
                        ref={menuRef}
                        className={cx(styles.control_dropdown)}
                        tabIndex={-1}
                        role="listbox"
                      >
                        <div
                          className={cx(styles.icon_wrapper)}
                          onClick={depositClick}
                        >
                          <img src={depositIcon} alt="" />
                        </div>
                        <div
                          className={cx(styles.icon_wrapper)}
                          onClick={withdrawClick}
                        >
                          <img src={withdrawIcon} alt="" />
                        </div>
                        <div
                          onClick={() => onDeleteStrategyHandler(value.id)}
                          className={cx(
                            styles.icon_wrapper,
                            styles.icon_wrapper__delete_icon,
                          )}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

              {
                showActive.includes(value.id) && (
                  <div className={cx(styles.content_wrapper)}>
                    <div className={cx(styles.content_item)}>
                      <Text type="text">
                        {t('MY_STRATEGIES.BALANCE')}
                      </Text>
                      <Text type="button">
                        {`${value.balance} ${USD}`}
                      </Text>
                    </div>
                    <div className={cx(styles.content_item)}>
                      <Text type="text">
                        {t('MY_STRATEGIES.FUNDS')}
                      </Text>
                      <Text type="button">
                        {`${value.equity} ${USD}`}
                      </Text>
                    </div>
                    <div className={cx(styles.content_item)}>
                      <Text type="text">
                        {t('MY_STRATEGIES.REWARD_TYPE')}
                      </Text>
                      <FeeComponent
                        type_of_fee={value.fee.type_of_fee}
                        percent_fee={value.fee.percent_fee}
                        fixed_fee={value.fee.fixed_fee}
                        textType="button"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          ))}
        </>
        )}
      {!loadingStatus
        && !isActive
        && closed.closed_strategies.length === 0
        && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification-mobile">
            {t('MY_STRATEGIES.NO_CLOSED_STRATEGIES_DESC_1')}
          </Text>
        </div>
        )}
      {!loadingStatus
        && !isActive
        && closed.closed_strategies.length > 0
        && (
        <>
          {closed.closed_strategies.map((value) => (

            <div
              key={uuidv4()}
              className={cx(
                styles.strategy_card,
                {
                  [styles.strategy_card__active]: showActive.includes(value.id),
                },
              )}
            >

              <div
                className={styles.show_icon}
                onClick={
                  () => (
                    showActive.includes(value.id)
                      ? setShowActive((prev) => prev.filter((el) => el !== value.id))
                      : setShowActive((prev) => [...prev, value.id])
                  )
                }
              >
                <img
                  src={showActive.includes(value.id) ? hideIcon : showIcon}
                  alt=""
                />
              </div>
              <div
                className={cx(
                  styles.strategy_title_wrapper,
                  {
                    [styles.strategy_title_wrapper__active]: showActive.includes(value.id),
                  },
                )}
                onClick={() => setShowActive((prev) => [...prev, value.id])}
              >

                <div className={styles.strategy_title}>
                  <Text
                    type="h1"
                    className={cx(
                      styles.strategy_name,
                      { [styles.strategy_name__active]: showActive.includes(value.id) },
                    )}
                  >
                    {value.name}
                  </Text>
                </div>

                <div className={cx(
                  styles.account_number_wrapper,
                  { [styles.account_number_wrapper__active]: showActive.includes(value.id) },
                )}
                >
                  <Text type="text">{t('MY_STRATEGIES.ACCOUNT_NUMBER')}</Text>
                  {' '}
                  <Text type="text">
                    {value.account}
                  </Text>
                </div>

              </div>

              <div
                className={cx(
                  styles.info_block,
                )}
                onClick={() => setShowActive((prev) => [...prev, value.id])}
              >
                <div className={cx(
                  styles.info_item,
                  { [styles.black_font]: showActive.includes(value.id) },
                )}
                >
                  <Text
                    type="h1"
                    className={cx(
                      {
                        [styles.green_font]: (showActive.includes(value.id) && value.total_pnl > 0),
                        [styles.red_font]: (showActive.includes(value.id) && value.total_pnl < 0),
                        [styles.black_font]: (showActive.includes(value.id) && value.total_pnl === 0),
                      },
                    )}
                  >
                    {
                      (value.total_pnl > 0 && `+${+value.total_pnl.toFixed(2)} ${USD}`)
                      || (value.total_pnl === 0 && `0 ${USD}`)
                      || (value.total_pnl < 0 && `${+value.total_pnl.toFixed(2)} ${USD}`)
                    }
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.PROFIT_LOSS')}
                  </Text>
                </div>
                <div className={cx(
                  styles.info_item,
                  { [styles.black_font]: showActive.includes(value.id) },
                )}
                >
                  <Text type="h1">
                    {value.total_followers}
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.SUBSCRIBERS')}
                  </Text>
                </div>
              </div>
              {showActive.includes(value.id) && (
                <div className={cx(styles.content_wrapper)}>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.CREATION_DATE')}
                    </Text>
                    <Text type="button">
                      {`${value.strategy_created}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.CLOSING_DATE')}
                    </Text>
                    <Text type="button">
                      {`${value.strategy_closed}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.BALANCE')}
                    </Text>
                    <Text type="button">
                      {`${value.balance} ${USD}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.FUNDS')}
                    </Text>
                    <Text type="button">
                      {`${value.equity} ${USD}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.REWARD_TYPE')}
                    </Text>
                    <FeeComponent
                      type_of_fee={value.fee.type_of_fee}
                      percent_fee={value.fee.percent_fee}
                      fixed_fee={value.fee.fixed_fee}
                      textType="button"
                    />
                  </div>
                  {/* <div className={cx(styles.content_item)}> */}
                  {/*  <Text type="text"> */}
                  {/*    {t('MY_STRATEGIES.PAID')} */}
                  {/*  </Text> */}
                  {/*  <Text type="button"> */}
                  {/*    {`${value.income_rewards} ${USD}`} */}
                  {/*  </Text> */}
                  {/* </div> */}
                </div>
              )}

            </div>
          ))}
        </>
        )}
    </div>
  );
}

export default MyStrategyMobile;
