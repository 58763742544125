import React, { FC, useCallback } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styles from './ReactModalEditlSubscription.module.scss';
import Text from '../../../entities/Text/Text';
import {
  ButtonCancel, ButtonEditSub, ButtonNormal,
} from '../../../features/Buttons';
import ModalContent from '../../ModalContent/ModalContent';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setModalContent, setModalIsOpen } from '../../../store/modal/reducer';
import { editSubscriptionRequest } from '../../../store/mySubscription/reducer';
import {
  validationSchema4,
  validationSchema5,
  validationSchema6,
  validationSchema7,
} from '../../../shared/schemas/schemas';
import { notificationModalClose } from '../../../store/notification/reducer';
import { ReactModalInfo } from '../../ReactModalInfo';
import { IFormikPropsEdit, IReactModalProps } from '../model/types/types';

const initialValues = {
  type_of_copy: 1,
  id: 0,
  fixed_vol: '',
  percent_vol: '',
  stop_loss: '',
  take_profit: '',
  stop_loss_toggle: false,
  take_profit_toggle: false,
};

export const ReactModalEditSubscription: FC<IReactModalProps> = ({
  button, title, args,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { info, master_account } = useAppSelector((state) => state.modal);
  const {
    currentAccountBalance,
  } = useAppSelector((state) => state.customer); // достаем список счетов
  const { current_subscription, isLoading } = useAppSelector((state) => state.mySubscriptions);
  const formik = useFormik<IFormikPropsEdit>({
    initialValues,
    onSubmit: async (data) => dispatch(editSubscriptionRequest({
      id: data.id,
      type_of_copy: info.id, // не ссылаемся на формик, так как в мобильной версии у выбора вознаграждения свой формик
      fixed_vol: info.id === 2 ? data.fixed_vol : 0,
      percent_vol: info.id === 3 ? data.percent_vol : 0,
      stop_loss: (data.stop_loss_toggle && data.stop_loss) ? data.stop_loss : 0,
      take_profit: (data.take_profit_toggle && data.take_profit) ? data.take_profit : 0,
    })),
    validationSchema:
    // перебор валидационных схем в соответстветствии с нажатой кнопкой
            (info.id === 1 && validationSchema4(currentAccountBalance))
            || (info.id === 2 && validationSchema5(currentAccountBalance))
            || (info.id === 3 && validationSchema6(currentAccountBalance))
            || (info.id === 4 && validationSchema7(currentAccountBalance)),
  });
  const toggle = useCallback((value: number) => {
    dispatch(setModalIsOpen(value));
    dispatch(setModalContent(current_subscription.type_of_copy));
  }, [current_subscription.type_of_copy, dispatch]);

  return (
    <div>
      <ButtonEditSub
        onClick={() => toggle(args.id)}
        handleReset={formik.handleReset}
      >
        <Text type="button">{t(button)}</Text>
      </ButtonEditSub>
      {!isLoading && (
      <Modal
        isOpen={master_account === args.id}
        centered
        backdrop={false}
        className={styles.modal_window}
        contentClassName={styles.modal_content}
      >
        <ModalHeader
          className={styles.header}
          cssModule={{ 'btn-close': styles.btn_close }}
          toggle={() => {
            dispatch(setModalIsOpen(0));
            dispatch(notificationModalClose());
          }}
        >
          {t('MODAL.EDIT_SUBSCRIPTION_INFO_CONTENT') !== ''
          && t('MODAL.EDIT_SUBSCRIPTION_INFO_CONTENT') !== 'MODAL.EDIT_SUBSCRIPTION_INFO_CONTENT'
          && (
            <div className={styles.info_wrapper}>
              <ReactModalInfo
                title={t('OTHER.INFO_TITLE')}
                content={t('MODAL.EDIT_SUBSCRIPTION_INFO_CONTENT')}
              />
              <div className={styles.info_border} />
            </div>
          )}
          <div className={styles.title_wrapper}>
            <Text type="h1">{`${t(title)} ${args.strategy_name}`}</Text>
          </div>

        </ModalHeader>
        <ModalBody
          className={styles.modal_body}
        >
          {/* <ModalButtonGroup */}
          {/*  handleReset={formik.handleReset} */}
          {/* /> */}
          <ModalContent
            formik={formik}
          />
        </ModalBody>
        <ModalFooter
          className={styles.footer}
        >
          <ButtonNormal
            disabled={
                Object.keys(formik.errors).length > 0
                || (
                  (
                    (formik.values.take_profit === '' && current_subscription.take_profit === 0)
                    || ((formik.values.take_profit === current_subscription.take_profit)
                          && formik.values.take_profit_toggle)
                      || (
                        formik.values.take_profit !== ''
                        && !formik.values.take_profit_toggle
                        && current_subscription.take_profit === 0
                      )
                  )
                    && (
                      (formik.values.stop_loss === '' && current_subscription.stop_loss === 0)
                      || ((formik.values.stop_loss === current_subscription.stop_loss)
                          && formik.values.stop_loss_toggle)
                      || (
                        formik.values.stop_loss !== ''
                          && !formik.values.stop_loss_toggle
                          && current_subscription.stop_loss === 0
                      )
                    )
                    && (
                      formik.values.fixed_vol === '' ? 0 : formik.values.fixed_vol
                    ) === current_subscription.fixed_vol
                    && (
                      formik.values.percent_vol === '' ? 0 : formik.values.percent_vol
                    ) === current_subscription.percent_vol
                    && formik.values.type_of_copy === info.id
                )
            } // проверка на ошибки
            onClick={() => {
              formik.handleSubmit();
              setTimeout(() => {
                dispatch(setModalIsOpen(0));
                dispatch(notificationModalClose());
              });
            }}
          >
            <Text type="button">{t('MODAL.SUBSCRIBE')}</Text>
          </ButtonNormal>
          {' '}
          <ButtonCancel
            onClick={() => {
              dispatch(setModalIsOpen(0));
              dispatch(notificationModalClose());
            }}
          >
            <Text type="button">{t('MODAL.CANCEL_BTN')}</Text>
          </ButtonCancel>
        </ModalFooter>
      </Modal>
      )}
    </div>
  );
};
