/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStrategyInfoState } from './models';
import { ICharts, IStrategiesRatingItem } from '../strategiesRating/models';

const initialState: IStrategyInfoState = {
  data: {
    id: 0,
    name: '',
    master_login: 0,
    profitability: 0,
    // chart_mini: '',
    chart_small: [],
    charts: [],
    created_at: '',
    max_drop: 0,
    max_leverage: 0,
    total_followers: 0,
    age: 0,
    leverage: 0,
    fee: {
      type_of_fee: 0,
      percent_fee: 0,
      fixed_fee: 0,
      delay_in_seconds: 0,
    },
    subscribers_balance: 0,
    recommended_amount: 0,
  },
  isLoading: false,
  error: '',
};

const strategyInfoSlice = createSlice({
  name: 'strategyInfo',
  initialState,
  reducers: {
    // -- получение информации по определенной стратегии
    getStrategyInfoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
    },
    getStrategyInfoSuccess(state, action: PayloadAction<IStrategiesRatingItem>) {
      state.data = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    getStrategyInfoFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStrategyChartRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
    },
    getStrategyChartSuccess(state, action: PayloadAction<ICharts[]>) {
      state.data.charts = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    getStrategyChartFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getStrategyInfoRequest,
  getStrategyInfoSuccess,
  getStrategyInfoFail,
  getStrategyChartRequest,
  getStrategyChartSuccess,
  getStrategyChartFail,
} = strategyInfoSlice.actions;
export default strategyInfoSlice.reducer;
