/* eslint-disable react-hooks/exhaustive-deps */
import cx from 'classnames';
import { Spinner, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './MyStrategiesDesktop.module.scss';
import Text from '../../../entities/Text/Text';
import { ButtonToggle } from '../../../features/ButtonToggle';
import { ReactModalStrategy } from '../../../containers/ReactModalStrategy';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  changeSortMethod,
  changeSortType,
  getActiveStrategiesRequest, getActiveStrategiesSuccess,
  getClosedStrategiesRequest,
  setStrategyContent,
} from '../../../store/myStrategies/reducer';
import createStrategyIcon from '../../../shared/assets/icons/create-strategy-icon.svg';
import depositIcon from '../../../shared/assets/icons/deposit-icon.svg';
import withdrawIcon from '../../../shared/assets/icons/withdraw-icon.svg';
import { ButtonManage, ButtonTableHeader } from '../../../features/Buttons';
import deleteIcon from '../../../shared/assets/icons/delete-icon.svg';
import { onDeleteStrategyHandler, onRedirectHandler } from '../../../handlers/handlers';
import { currSortMethod, currSortType, IMyStrategy } from '../../../store/myStrategies/models';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import { USD } from '../../../shared/consts/consts';
import { FeeComponent } from '../../../entities/FeeComponent/FeeComponent';
import { ClosedTHeaderStr } from '../../../widgets/strategiesTHeader/closed/ui/ClosedTHeader';

function MyStrategyDesktop() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showBalanceMenu, setShowBalanceMenu] = useState('');

  const { deposit_funds_url, withdraw_funds_url } = useAppSelector((state) => state.customer.redirectUrls);
  const handleClick = (e: boolean) => { // переключение типа контента активные/закрытые
    dispatch(setStrategyContent(e));
  };
  const { isLoading, info, allStrategies } = useAppSelector((state) => state.myStrategies);
  const { active, closed, sort } = allStrategies;
  const { isActive } = info;
  const loadingStatus = isLoading
    || active.isLoading
    || closed.isLoading;
  const sortedData: IMyStrategy = JSON.parse(JSON.stringify(allStrategies));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
  } = sort;
  useEffect(() => {
    dispatch(getActiveStrategiesRequest());
    dispatch(getClosedStrategiesRequest());
    if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
    if (curr_sort_type !== 'account') dispatch(changeSortType('account'));
  }, [dispatch]);

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    // нажатие на другой столбец
    if (curr_sort_type !== sortType) {
      dispatch(changeSortType(sortType));
      dispatch(changeSortMethod('down'));
      sortedData.active.active_strategies.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
      dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
    } else {
      // нажатие на тот же столбец
      switch (sortMethod) {
        case 'default':
          dispatch(changeSortMethod('down'));
          sortedData.active.active_strategies.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case 'down':
          dispatch(changeSortMethod('up'));
          sortedData.active.active_strategies.sort((a, b) => a[`${sortType}`] - b[`${sortType}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        case 'up':
          dispatch(changeSortMethod('default'));
          dispatch(changeSortType(sort_types[5]));
          sortedData.active.active_strategies.sort((a, b) => a[`${sort_types[5]}`] - b[`${sort_types[5]}`]);
          dispatch(getActiveStrategiesSuccess(sortedData.active.active_strategies));
          break;
        default:
          break;
      }
    }
  }, [curr_sort_type, dispatch, sortedData.active.active_strategies]);
  return (
    <div>
      <div
        className={styles.head_container}
      >
        <div
          className={cx(
            styles.head_container,
            styles.head_container__left,
          )}
        >
          <Text type="h1" className={styles.title}>
            {t('MY_STRATEGIES.PAGE_TITLE')}
          </Text>
          <ButtonToggle
            onClickContent={handleClick}
            isStrategies
          />
          {isActive
            && t('MY_STRATEGIES.INFO_CONTENT') !== ''
            && t('MY_STRATEGIES.INFO_CONTENT') !== 'MY_STRATEGIES.INFO_CONTENT'
            && (
              <ReactModalInfo
                title={t('OTHER.INFO_TITLE')}
                content={t('MY_STRATEGIES.INFO_CONTENT')}
              />
            )}
          {!isActive
            && t('MY_STRATEGIES.INFO_CONTENT_CLOSED') !== ''
            && t('MY_STRATEGIES.INFO_CONTENT_CLOSED') !== 'MY_STRATEGIES.INFO_CONTENT_CLOSED'
            && (
              <ReactModalInfo
                title={t('OTHER.INFO_TITLE')}
                content={t('MY_STRATEGIES.INFO_CONTENT_CLOSED')}
              />
            )}
        </div>
        <div
          className={styles.head_container__right}
        >
          <div>
            <ReactModalStrategy
              button="MODAL.CREATE_STRATEGY"
              title="MODAL.CREATING_STRATEGY"
              args=""
              icon={createStrategyIcon}
            />
          </div>
          <div>
            <ButtonManage
              onClick={() => onRedirectHandler(deposit_funds_url)}
              icon={depositIcon}
              handleReset={() => false}
            >
              <Text type="button">{t('MODAL.DEPOSIT')}</Text>
            </ButtonManage>
          </div>
          <div>
            <ButtonManage
              onClick={() => onRedirectHandler(withdraw_funds_url)}
              icon={withdrawIcon}
              handleReset={() => false}
            >
              <Text type="button">{t('MODAL.WITHDRAW')}</Text>
            </ButtonManage>
          </div>
        </div>
      </div>
      {loadingStatus
        && (
        <Spinner
          className={styles.spinner}
        />
        )}
      {!loadingStatus
        && isActive
        && active.active_strategies.length === 0
        && closed.closed_strategies.length === 0
        && (
          <div className={styles.notification_text_wrapper}>
            <Text type="page-notification">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_1')}
            </Text>
            <Text type="page-notification">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_2')}
            </Text>
          </div>
        )}
      {!loadingStatus
        && isActive
        && active.active_strategies.length === 0
        && closed.closed_strategies.length > 0
        && (
          <div className={styles.notification_text_wrapper}>
            <Text type="page-notification">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_3')}
            </Text>
            <Text type="page-notification">
              {t('MY_STRATEGIES.NO_ACTIVE_STRATEGIES_DESC_2')}
            </Text>
          </div>
        )}
      {!loadingStatus
        && isActive
        && active.active_strategies.length > 0
        && (
          <Table
            className={styles.table}
          >
            <thead>
              <tr>
                <th>
                  <ButtonTableHeader
                    disabled
                  >
                    <Text type="button">{t('MY_STRATEGIES.STRATEGY_MANAGEMENT')}</Text>
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[0], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[0]}
                  >
                    <Text type="button">{t('MY_STRATEGIES.PROFIT_LOSS')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[0]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[1], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[1]}
                  >
                    <Text type="button">{t('MY_STRATEGIES.SUBSCRIBERS')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[1]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[2], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[2]}
                  >
                    <Text type="button">{t('MY_STRATEGIES.BALANCE')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[2]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[3], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[3]}
                  >
                    <Text type="button">{t('MY_STRATEGIES.FUNDS')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[3]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {active.active_strategies.map((value) => (
                <tr
                  key={uuidv4()}
                  className={styles.table_row}
                >
                  <td>
                    <div
                      className={styles.strategy_name_wrapper}
                    >
                      <Link
                        className="nav-link"
                        ria-current="page"
                        to={`/my_strategy/strategy/${value.id}`}
                      >
                        <Text type="text">{value.name}</Text>
                      </Link>
                      <div
                        onClick={() => onDeleteStrategyHandler(dispatch, value.id)}
                        className={cx(styles.icon_wrapper)}
                      >
                        <img src={deleteIcon} alt="" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <Text
                      type="text"
                      className={cx(
                        {
                          [styles.green_font]: value.total_pnl > 0,
                          [styles.red_font]: value.total_pnl < 0,
                          [styles.black_font]: value.total_pnl === 0,
                        },
                      )}
                    >
                      {
                      (value.total_pnl > 0 && `+${+value.total_pnl.toFixed(2)} ${USD}`)
                      || (value.total_pnl === 0 && `0 ${USD}`)
                      || (value.total_pnl < 0 && `${+value.total_pnl.toFixed(2)} ${USD}`)
                    }
                    </Text>
                  </td>
                  <td>
                    <Text type="text">{value.total_followers}</Text>
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <td
                    onClick={showBalanceMenu
                      ? () => setShowBalanceMenu('')
                      : () => setShowBalanceMenu(value.name)}
                    className={styles.table__balance}
                  >
                    <Text type="text">{`${value.balance} ${USD}`}</Text>
                    {showBalanceMenu === value.name
                    && (
                      <div
                        className={styles.table__balance__menu}
                      >
                        <div
                          onClick={() => onRedirectHandler(`${deposit_funds_url}?account=${value.account}`)}
                        >
                          <Text type="text">{t('MODAL.DEPOSIT')}</Text>
                        </div>
                        <div
                          onClick={() => onRedirectHandler(`${withdraw_funds_url}?account=${value.account}`)}
                        >
                          <Text type="text">{t('MODAL.WITHDRAW')}</Text>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <Text type="text">{`${value.equity} ${USD}`}</Text>
                  </td>
                  {/* <td> */}
                  {/*  <Text type="text">{`${value.income_rewards} ${USD}`}</Text> */}
                  {/* </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      {!loadingStatus && !isActive && closed.closed_strategies.length === 0 && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification">
            {t('MY_STRATEGIES.NO_CLOSED_STRATEGIES_DESC_1')}
          </Text>
        </div>
      )}
      {!loadingStatus
        && !isActive
        && closed.closed_strategies.length > 0
        && (
          <Table
            className={styles.table}
            hover
          >
            <ClosedTHeaderStr />
            <tbody>
              {closed.closed_strategies.map((value) => (
                <tr
                  className={styles.table_row}
                  key={uuidv4()}
                >
                  <td>
                    <Text type="text">{value.account}</Text>
                  </td>
                  <td>
                    <Text type="text">{value.name}</Text>
                  </td>
                  <td>
                    <Text
                      type="text"
                      className={cx(
                        {
                          [styles.green_font]: value.total_pnl > 0,
                          [styles.red_font]: value.total_pnl < 0,
                          [styles.black_font]: value.total_pnl === 0,
                        },
                      )}
                    >
                      {
                      (value.total_pnl > 0 && `+${+value.total_pnl.toFixed(2)} ${USD}`)
                      || (value.total_pnl === 0 && `0 ${USD}`)
                      || (value.total_pnl < 0 && `${+value.total_pnl.toFixed(2)} ${USD}`)
                    }
                    </Text>
                  </td>
                  <td>
                    <Text type="text">{value.total_followers}</Text>
                  </td>
                  <td>
                    <Text type="text">{value.strategy_created}</Text>
                  </td>
                  <td>
                    <Text type="text">{value.strategy_closed}</Text>
                  </td>
                  <td>
                    <Text type="text">{`${value.balance} ${USD}`}</Text>
                  </td>
                  <td>
                    <Text type="text">{`${value.equity} ${USD}`}</Text>
                  </td>
                  <td>
                    <FeeComponent
                      type_of_fee={value.fee.type_of_fee}
                      percent_fee={value.fee.percent_fee}
                      fixed_fee={value.fee.fixed_fee}
                      textType="text"
                    />
                  </td>
                  {/* <td> */}
                  {/*  <Text type="text">{`${value.income_rewards} ${USD}`}</Text> */}
                  {/* </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
    </div>
  );
}

export default MyStrategyDesktop;
