/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useFormik } from 'formik';
import { Input, Spinner } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getStrategiesRequest,
  changeSortMethod,
  changeSortType,
  getStrategiesSuccess,
  setRatePageSize,
  setCurrentPage,
  setCurrentSortLabel,
} from '../../../store/strategiesRating/reducer';
import styles from './StrategiesRatingMobile.module.scss';
import Text from '../../../entities/Text/Text';
import ModalSelector from '../../../containers/ModalSelectors/ModalSelector';
import showIcon from '../../../shared/assets/icons/show-icon.svg';
import {
  IDataStrategies,
  currSortMethod,
  currSortType,
  IStrategiesRatingItem,
} from '../../../store/strategiesRating/models';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import { pageSize } from '../../../shared/consts/consts';
import { ButtonSearch } from '../../../features/Buttons';
import Feedback from '../../../entities/Feedback/Feedback';
import { searchValidationSchema } from '../../../shared/schemas/schemas';

const mobileSelector = [
  { label: 'STRATEGIES_RATING.STRATEGY' },
  { label: 'STRATEGIES_RATING.PROFITABILITY' },
  { label: 'STRATEGIES_RATING.MAXIMUM_DRAWDOWN' },
  { label: 'STRATEGIES_RATING.MAXIMUM_LEVERAGE' },
  { label: 'STRATEGIES_RATING.AGE' },
  { label: 'MY_STRATEGIES.SUBSCRIBERS' },
];

function StrategiesRatingMobile() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  const { data, sort, isLoading } = useAppSelector(
    (state) => state.strategiesRating,
  );
  const { currentPage, ratePageSize } = useAppSelector(
    (state) => state.strategiesRating.pagination,
  );
  const [isFocus, setIsFocus] = useState(false);
  const [field, setField] = useState('');
  const focusHandler = (newField: string) => {
    setIsFocus(true);
    setField(newField);
  };
  const blurHandler = () => {
    setIsFocus(false);
  };
  // глубокое копирование state
  const sortedData: IDataStrategies = JSON.parse(JSON.stringify(data));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
    curr_sort_label,
  } = sort;

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    if (curr_sort_type !== sortType) {
      dispatch(changeSortMethod('down'));
      dispatch(changeSortType(sortType));
      sortedData.list.sort((a, b) => {
        if (a[`${sortType}`] < b[`${sortType}`]) {
          return 1;
        }
        if (a[`${sortType}`] > b[`${sortType}`]) {
          return -1;
        }
        return 0;
      });
      dispatch(getStrategiesSuccess(sortedData));
    } else {
      switch (sortMethod) {
        case 'default':
          // dispatch(changeSortMethod('down'));
          dispatch(changeSortMethod('up'));
          // sortedData.list.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
          sortedData.list.sort((a, b) => {
            if (a[`${sortType}`] < b[`${sortType}`]) {
              return -1;
            }
            if (a[`${sortType}`] > b[`${sortType}`]) {
              return 1;
            }
            return 0;
          });
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case 'down':
          dispatch(changeSortMethod('up'));
          sortedData.list.sort((a, b) => {
            if (a[`${sortType}`] < b[`${sortType}`]) {
              return -1;
            }
            if (a[`${sortType}`] > b[`${sortType}`]) {
              return 1;
            }
            return 0;
          });
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case 'up': // в колонке стратегия с up меняется сразу на down
          if (curr_sort_type === sort_types[0]) {
            dispatch(changeSortMethod('down'));
            sortedData.list.sort((a, b) => {
              if (a[`${sortType}`] < b[`${sortType}`]) {
                return 1;
              }
              if (a[`${sortType}`] > b[`${sortType}`]) {
                return -1;
              }
              return 0;
            });
            dispatch(getStrategiesSuccess(sortedData));
          } else {
            dispatch(changeSortMethod('default'));
            dispatch(changeSortType(sort_types[0]));
            // sortedData.list.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
            sortedData.list.sort((a, b) => b[`${sort_types[0]}`] - a[`${sort_types[0]}`]);
            dispatch(getStrategiesSuccess(sortedData));
          }
          break;
        default:
          break;
      }
    }
  }, [curr_sort_type, dispatch, sortedData]);
  interface IInitialValues {
    mobileSelector: string,
    searchInput: string
  }
  const initialValues = {
    mobileSelector: curr_sort_label,
    searchInput: '',
  };
  const formik = useFormik<IInitialValues>({
    initialValues,
    onSubmit: (sortValue) => {
      switch (sortValue.mobileSelector) {
        case t('STRATEGIES_RATING.STRATEGY'):
          dispatch(changeSortType(sort_types[5]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          // sortedData.list.sort((a, b) => a[`${sort_types[5]}`] - b[`${sort_types[5]}`]);
          sortedData.list.sort((a, b) => {
            if (a[`${sort_types[5]}`] < b[`${sort_types[5]}`]) {
              return 1;
            }
            if (a[`${sort_types[5]}`] > b[`${sort_types[5]}`]) {
              return -1;
            }
            return 0;
          });
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case t('STRATEGIES_RATING.PROFITABILITY'):
          dispatch(changeSortType(sort_types[0]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          // sortedData.list.sort((a, b) => a[`${sort_types[0]}`] - b[`${sort_types[0]}`]);
          sortedData.list.sort((a, b) => b[`${sort_types[0]}`] - a[`${sort_types[0]}`]);
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case t('STRATEGIES_RATING.MAXIMUM_DRAWDOWN'):
          dispatch(changeSortType(sort_types[1]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          // sortedData.list.sort((a, b) => a[`${sort_types[1]}`] - b[`${sort_types[1]}`]);
          sortedData.list.sort((a, b) => b[`${sort_types[1]}`] - a[`${sort_types[1]}`]);
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case t('STRATEGIES_RATING.MAXIMUM_LEVERAGE'):
          dispatch(changeSortType(sort_types[2]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          sortedData.list.sort((a, b) => b[`${sort_types[2]}`] - a[`${sort_types[2]}`]);
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case t('STRATEGIES_RATING.AGE'):
          dispatch(changeSortType(sort_types[3]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          // sortedData.list.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
          sortedData.list.sort((a, b) => b[`${sort_types[3]}`] - a[`${sort_types[3]}`]);
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case t('MY_STRATEGIES.SUBSCRIBERS'):
          dispatch(changeSortType(sort_types[4]));
          // dispatch(changeSortMethod('up'));
          dispatch(changeSortMethod('down'));
          // sortedData.list.sort((a, b) => a[`${sort_types[4]}`] - b[`${sort_types[4]}`]);
          sortedData.list.sort((a, b) => b[`${sort_types[4]}`] - a[`${sort_types[4]}`]);
          dispatch(getStrategiesSuccess(sortedData));
          break;
        default:
          break;
      }
    },
    validationSchema: searchValidationSchema,
  });
  // определение индексов элементов массива для метода slice
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - ratePageSize) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize * ratePageSize;
    return (
      !formik.values.searchInput
        ? data.list.slice(firstPageIndex, lastPageIndex)
        : data.list
          .filter(({ name }) => name.toLowerCase().includes(formik.values.searchInput.toLowerCase()))
          .slice(firstPageIndex, lastPageIndex)
    );
  }, [currentPage, ratePageSize, data, formik.values.searchInput]);
  const handleShowSearchInput = () => {
    setIsShowSearchInput(!isShowSearchInput);
    if (isShowSearchInput) formik.setFieldValue('searchInput', '');
  };
  // сброс всех state на initial после перехода на страницу не со страницы strategy info
  useEffect(() => {
    if (
      !data.list.length
        || location.state === null
      // @ts-ignore
        || !location.state.includes('/strategies_rating/strategy/')
    ) {
      if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
      if (curr_sort_type !== 'profitability') dispatch(changeSortType('profitability'));
      formik.setFieldValue('mobileSelector', '');
      dispatch(setCurrentSortLabel(''));
      dispatch(getStrategiesRequest());
      if (currentPage !== 1) dispatch(setCurrentPage(1));
      if (ratePageSize !== 1) dispatch(setRatePageSize(1));
    }
  }, [dispatch]);
  // useEffect для сбрасывание rate и currentPage при поиске
  useEffect(() => {
    if (currentPage !== 1) dispatch(setCurrentPage(1));
    if (ratePageSize !== 1) dispatch(setRatePageSize(1));
  }, [formik.values.searchInput]);
  const handleClick = useCallback((id: number, value: { from: string }) => {
    navigate(`strategy/${id}`, { state: value });
  }, [navigate]);
  const showMoreScroll = () => {
    // @ts-ignore
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
      dispatch(setCurrentPage(currentPage + 1));
      dispatch(setRatePageSize(ratePageSize + 1));
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', showMoreScroll);
  }, [currentTableData]);
  return (
    <div
      className={cx(styles.my_strategies_mobile)}
    >
      <div
        className={styles.title}
      >
        <Text type="h1">
          {t('STRATEGIES_RATING.PAGE_TITLE')}
        </Text>
        {t('STRATEGIES_RATING.INFO_CONTENT') !== ''
        && t('STRATEGIES_RATING.INFO_CONTENT') !== 'STRATEGIES_RATING.INFO_CONTENT'
        && (
          <ReactModalInfo
            title={t('OTHER.INFO_TITLE')}
            content={t('STRATEGIES_RATING.INFO_CONTENT')}
          />
        )}
      </div>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && data.list.length > 0 && (
      <>
        <div className={cx(styles.filter_block)}>
          <ModalSelector
            values={mobileSelector}
            name="mobileSelector"
            formik={formik}
          />
          <div className={cx(styles.button)}>
            <ArrowSwitcher
              onClick={() => handleSortClick(curr_sort_type, curr_sort_method)}
              currSort={curr_sort_type}
              sortMethod={curr_sort_method}
            />
          </div>
          <ButtonSearch
            onClick={handleShowSearchInput}
          />
        </div>
        {isShowSearchInput
            && (
            <div
              className={styles.input_container}
            >
              <Input
                maxLength={20}
                autoComplete="off"
                placeholder={t('OTHER.SEARCH_PLACEHOLDER')}
                type="text"
                {...formik.getFieldProps('searchInput')}
                onFocus={() => focusHandler('searchInput')}
                onBlur={(e) => {
                  blurHandler();
                  formik.handleBlur(e);
                }}
              />
              <Feedback name="searchInput" isFocus={isFocus} formik={formik} field={field} />
            </div>
            )}
        {currentTableData.map((strategy: IStrategiesRatingItem) => (
          <div
            key={uuidv4()}
            className={cx(
              styles.strategy_card,
            )}
            onClick={() => handleClick(strategy.id, { from: location.pathname })}
          >
            <div
              className={styles.show_icon}
            >
              <img
                src={showIcon}
                alt=""
              />
            </div>
            <div className={styles.strategy_title}>
              <Text
                type="h3"
                className={styles.strategy_title_text}
              >
                {strategy.name}
              </Text>
            </div>
            <div
              className={styles.info_block}
            >
              <div className={cx(styles.info_item)}>
                <Text
                  type="h3"
                >
                  {`${strategy.profitability}%`}
                </Text>
                <Text
                  type="text"
                >
                  {t('STRATEGIES_RATING.PROFITABILITY')}
                </Text>
              </div>
              <div className={cx(styles.info_item)}>
                <Text type="h3">
                  {strategy.total_followers}
                </Text>
                <Text type="text">
                  {t('MY_STRATEGIES.SUBSCRIBERS')}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </>
      )}
    </div>
  );
}

export default React.memo(StrategiesRatingMobile);
