/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import Text from '../Text/Text';
import styles from './DropdownMenu.module.scss';

export interface DropDownMenuEventType { option: any, index: number }

interface DropDownMenuProps {
    className?: string;
    options?: Array<any>,
    inlineBlock: 'right' | 'left'
    align?: 'left' | 'center' | 'right',
    onClick?: (obj: DropDownMenuEventType) => void
    children?: React.ReactNode,
}
const DropDownMenu = ({
  className, inlineBlock, align, onClick, children, options,
}: DropDownMenuProps) => (
  <div
    className={cx(
      className,
      styles.drop_down_menu,
      styles[`drop_down_menu__inline-block--${inlineBlock}`],
    )}
  >
    {(!children && options && onClick) && options.map((option, index) => (
      <div
        key={index}
        onClick={() => onClick({ option, index })}
        className={styles.drop_down_menu__item}
      >
        <Text type="button" align={align}>{option}</Text>
        {options.length - 1 !== index && <br />}
      </div>
    ))}
    { children }
  </div>
);

export default DropDownMenu;
