import React from 'react';
import { useMedia } from 'react-use';
import StrategyInfoMobile from './StrategyInfoMobile/StrategyInfoMobile';
import StrategyInfoDesktop from './StrategyInfoDesktop/StrategyInfoDesktop';

function StrategyInfo() {
  const isMobile = useMedia('(max-width: 545px)');
  return (
    isMobile
      ? <StrategyInfoMobile />
      : <StrategyInfoDesktop />
  );
}

export default StrategyInfo;
