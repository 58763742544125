import React, { FC, useState } from 'react';
import {
  Form, Input, Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { FormikProps } from 'formik';
import ModalSelector from '../../ModalSelectors/ModalSelector';
import styles from './CreateStrategyContent.module.scss';
import { useAppSelector } from '../../../hooks/hooks';
import Text from '../../../entities/Text/Text';
import Feedback from '../../../entities/Feedback/Feedback';
import smallAttentionIcon from '../../../shared/assets/icons/smallAttentionIcon.svg';
import { USD } from '../../../shared/consts/consts';
import { IResponseGetAccounts } from '../../../store/customer/models';
import { IFormikPropsStrategy } from '../../ReactModalStrategy/model/types/types';

let rewardLabel: string;
let rewardInputIcon: string;

interface IProps {
  formik: FormikProps<IFormikPropsStrategy>
  accounts: IResponseGetAccounts
}
const CreateStrategyContent: FC<IProps> = ({ formik, accounts }) => {
  const { rewardTypes } = useAppSelector((state) => state.myStrategies.rewards);
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const [field, setField] = useState('');
  const focusHandler = (newField: string) => {
    setIsFocus(true);
    setField(newField);
  };
  const blurHandler = () => {
    setIsFocus(false);
  };

  // eslint-disable-next-line default-case
  switch (formik.values.strategy_reward) {
    case t('MODAL.REWARD_TYPE_1'):
      rewardLabel = t('MODAL.REWARD_VALUE_PERCENT');
      rewardInputIcon = '%';
      break;
    case t('MODAL.REWARD_TYPE_2'):
      rewardLabel = t('MODAL.REWARD_VALUE_FIX');
      rewardInputIcon = USD;
      break;
    case t('MODAL.REWARD_TYPE_3'):
      rewardLabel = t('MODAL.REWARD_VALUE_FIX_DELAY');
      rewardInputIcon = USD;
      break;
  }
  return (
    <Form>
      <div className={styles.container}>
        <Label className={styles.selector_label}>
          <Text type="text">{t('MODAL.CHOOSE_ACCOUNT')}</Text>
        </Label>
        <ModalSelector
          values={accounts}
          name="vacant_accounts" // выбор счета
          formik={formik}
        />
      </div>

      <div className={styles.container}>
        <Label className={styles.selector_label}>
          <Text type="text">{t('MODAL.STRATEGY_NAME')}</Text>
        </Label>
        <div
          className={styles.input_container}
        >
          <Input
            cssModule={{
              'is-invalid': styles.is_invalid,
            }}
            invalid={
            !!formik.touched.strategy_name
                && !!formik.errors.strategy_name
                && (field !== 'strategy_name' || !isFocus)
          }
            maxLength={20}
            {...formik.getFieldProps('strategy_name')}
            onFocus={() => focusHandler('strategy_name')}
            onBlur={(e) => {
              blurHandler();
              formik.handleBlur(e);
            }}
            autoComplete="off"
          />
          <Feedback name="strategy_name" isFocus={isFocus} formik={formik} field={field} />
        </div>
      </div>

      <div className={styles.container}>
        <Label className={styles.selector_label}>
          <Text type="text">{t('MODAL.REWARD_TYPE')}</Text>
        </Label>
        <ModalSelector
          values={rewardTypes}
          name="strategy_reward"
          formik={formik}
        />
      </div>

      <div className={styles.container}>
        <Label className={styles.selector_label}>
          <Text type="text">
            {rewardLabel}
          </Text>
        </Label>
        <div
          className={styles.input_container}
        >
          <Input
            cssModule={{
              'is-invalid': styles.is_invalid,
            }}
            invalid={
            !!formik.touched.strategy_reward_value
                && !!formik.errors.strategy_reward_value
                && (field !== 'strategy_reward_value' || !isFocus)
          }
            className={cx(
              styles.input_container,
              styles.noHint,
            )}
            maxLength={3}
            {...formik.getFieldProps('strategy_reward_value')}
            onFocus={() => focusHandler('strategy_reward_value')}
            onBlur={(e) => {
              blurHandler();
              formik.handleBlur(e);
            }}
            autoComplete="off"
          />
          <Text
            type="text"
            className={styles.span}
          >
            {rewardInputIcon}
          </Text>
          <Feedback isHideHint name="strategy_reward_value" isFocus={isFocus} formik={formik} field={field} />
        </div>
      </div>
      <div className={styles.container}>
        <Label className={styles.selector_label}>
          <Text type="text">
            {t('MODAL.RECOMMENDED_VALUE')}
          </Text>

        </Label>
        <div
          className={styles.input_container}
        >
          <Input
            cssModule={{
              'is-invalid': styles.is_invalid,
            }}
            invalid={
            !!formik.touched.recommended_amount
                && !!formik.errors.recommended_amount
                && (field !== 'recommended_amount' || !isFocus)
          }
            maxLength={20}
            {...formik.getFieldProps('recommended_amount')}
            onFocus={() => focusHandler('recommended_amount')}
            onBlur={(e) => {
              blurHandler();
              formik.handleBlur(e);
            }}
            autoComplete="off"
          />
          <Text
            type="text"
            className={styles.span}
          >
            {USD}
          </Text>
          <Feedback name="recommended_amount" isFocus={isFocus} formik={formik} field={field} />
        </div>
      </div>

      {formik.values.strategy_reward === t('MODAL.REWARD_TYPE_2')
      && (
        <div className={cx(
          styles.container,
          styles.container__attention,
        )}
        >
          <img src={smallAttentionIcon} alt="" />
          <Text type="text">{t('MODAL.REWARD_VALUE_FIX_ATTENTION')}</Text>
        </div>
      )}
    </Form>
  );
};

export default CreateStrategyContent;
