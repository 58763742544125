import { Button } from 'reactstrap';
import cx from 'classnames';
import React from 'react';
import styles from './Buttons.module.scss';
import subscribeIcon from '../../../shared/assets/icons/subscribe_icon.svg';
import arrowLeftIcon from '../../../shared/assets/icons/arrow-left.svg';
import deleteIcon from '../../../shared/assets/icons/delete-icon.svg';
import registerIcon from '../../../shared/assets/icons/register.svg';
import magnifier from '../../../shared/assets/icons/magnifier.svg';
import { handleDispatch } from '../lib/lib';

interface IButtonShowMore {
    onClick: any,
    children: JSX.Element,
}
export const ButtonNormal = ({
  children, onClick, disabled,
}: any) => (
  <Button
    className={styles.button}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);
export const ButtonSearch = ({
  onClick,
}: any) => (
  <Button
    className={styles.button__search}
    onClick={onClick}
  >
    <img src={magnifier} alt="" />
  </Button>
);

export const ButtonSubscribe = ({
  children, onClick, buttonSize, handleReset, dispatch, actions,
}: any) => (
  <Button
    className={cx(
      styles.button__subscribe,
      {
        [styles.button__subscribe__medium]: buttonSize === 'medium',
        [styles.button__subscribe__mobile]: buttonSize === 'mobile',
      },
    )}
    onClick={() => {
      onClick();
      handleReset();
      handleDispatch(dispatch, actions);
    }}
  >
    <img src={subscribeIcon} alt="" />
    {' '}
    {children}
  </Button>
);
export const ButtonRegistration = ({
  children, onClick, buttonSize, handleReset,
}: any) => (
  <Button
    className={cx(
      styles.button__registration,
      {
        [styles.button__registration__medium]: buttonSize === 'medium',
        [styles.button__registration__mobile]: buttonSize === 'mobile',
      },
    )}
    onClick={() => {
      onClick();
      handleReset();
    }}
  >
    <img src={registerIcon} alt="" />
    {' '}
    {children}
  </Button>
);
export const ButtonEditSub = ({
  children, onClick, handleReset,
}: any) => (
  <Button
    className={styles.button}
    onClick={() => {
      onClick();
      handleReset();
    }}
  >
    {children}
  </Button>
);

export const ButtonTableHeader = ({
  children, onClick, disabled, isActive,
}: any) => (
  <Button
    className={cx(
      styles.button__table_header,
      {
        [styles.button__table_header__active_header_btn_border]: isActive,
      },
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

export const ButtonCancel = ({ children, onClick }: any) => (
  <Button
    className={styles.button__cancel}
    onClick={onClick}
  >
    {children}
  </Button>
);

export const ButtonManage = ({
  children, onClick, handleReset, dispatch, actions, icon,
}: any) => (
  <Button
    className={styles.button__manage}
    onClick={() => {
      handleReset();
      onClick();
      handleDispatch(dispatch, actions);
    }}
  >
    <img src={icon} alt="" />
    {' '}
    {children}
  </Button>
);
export const ButtonBack = ({
  children, onClick, buttonSize,
}: any) => (
  <Button
    className={cx(
      styles.button__back,
      {
        [styles.button__back__medium]: buttonSize === 'medium',
      },
    )}
    onClick={onClick}
  >
    <img src={arrowLeftIcon} alt="" />
    {' '}
    {children}
  </Button>
);
export const ButtonDelete = ({
  children, onClick, buttonSize,
}: any) => (
  <Button
    className={cx(
      styles.button__delete,
      {
        [styles.button__delete__medium]: buttonSize === 'medium',
      },
    )}
    onClick={onClick}
  >
    <img src={deleteIcon} alt="" />
    {' '}
    {children}
  </Button>
);

export const ButtonInfo = ({ onClick } : any) => (
  <Button
    className={cx(
      styles.button__info,
    )}
    onClick={onClick}
  />
);

export const ButtonShowMore = ({
  onClick, children,
}: IButtonShowMore) => (
  <Button
    className={cx(
      styles.button__load_more,
    )}
    onClick={onClick}
  >
    {children}
  </Button>

);
