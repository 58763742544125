/* eslint-disable prefer-const,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { usePagination } from '../../hooks/usePagination';
import styles from './Pagination.module.scss';
import Text from '../Text/Text';

interface IPagination {
    onPageChange: (pageNumber: number) => void,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number,
    className: string,
}
const Pagination = (props: IPagination) => {
  const {
    onPageChange,
    totalCount,
    siblingCount,
    currentPage,
    pageSize,
    className,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // Если текущая страница = 0 или длинна массива с номерами страниц меньше 2 компонент не показывается
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }
  const firstPage = 1;
  let lastPage = Number(paginationRange && paginationRange[paginationRange.length - 1]);

  return (
    <ul
      className={cx(
        styles.pagination_container,
        { [className]: className },
      )}
    >
      {
          paginationRange && paginationRange.map((pageNumber) => {
            if (typeof pageNumber === 'string') {
              return null;
            }
            // точки слева
            if (pageNumber === firstPage
                && (paginationRange.includes('dotsLeft') || paginationRange.includes('dotsBoth'))) {
              return (
                <li
                  key={uuidv4()}
                  className={cx(
                    styles.pagination_item,
                    { [styles.pagination_item__selected]: pageNumber === currentPage },
                  )}
                  onClick={() => onPageChange(pageNumber)}
                >
                  <Text type="mobile-menu">{pageNumber}</Text>
                  &nbsp;
                  &hellip;
                </li>
              );
            }
            // точки справа
            if (lastPage
                && pageNumber === lastPage
                && (paginationRange.includes('dotsRight') || paginationRange.includes('dotsBoth'))) {
              return (
                <li
                  key={uuidv4()}
                  className={cx(
                    styles.pagination_item,
                    { [styles.pagination_item__selected]: pageNumber === currentPage },
                  )}
                  onClick={() => onPageChange(pageNumber)}
                >
                  &hellip;
                  &nbsp;
                  <Text type="mobile-menu">{pageNumber}</Text>
                </li>
              );
            }
            return (
            // рендер кнопок без точек
              <li
                key={uuidv4()}
                className={cx(
                  styles.pagination_item,
                  { [styles.pagination_item__selected]: pageNumber === currentPage },
                )}
                onClick={() => onPageChange(pageNumber)}
              >
                <Text type="mobile-menu">{pageNumber}</Text>
              </li>
            );
          })
      }
    </ul>
  );
};

export default Pagination;
