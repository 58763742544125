/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IResponseGetAccounts, IToken, IUrls,
} from './models';

interface ICustomerState {
  accounts: IResponseGetAccounts
  token: IToken
  currentAccountBalance: number,
  redirectUrls: IUrls
  windowWidth: number
  isLoading: boolean
  error: string
}
const initialState: ICustomerState = {
  accounts: {
    vacant_accounts: [],
    invest_accounts: [],
    isLoading: false,
    error: '',
  },
  currentAccountBalance: 0,
  redirectUrls: {
    create_account_url: '',
    return_to_external_url: '',
    deposit_funds_url: '',
    withdraw_funds_url: '',
    interface_language: '',
    flags: [],
  },
  token: {
    token: '',
  },
  windowWidth: 0,
  isLoading: false,
  error: '',
};
const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // -- запрос токена
    tokenRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
    },
    tokenSuccess(state, action: PayloadAction<IToken>) {
      state.token = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    tokenFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- удаление токена
    deleteToken(state, action: PayloadAction<IToken>) {
      state.token = action.payload;
      state.error = '';
    },
    // -- получение ширины экрана
    getWindowWidth(state, action: PayloadAction<number>) {
      state.windowWidth = action.payload;
    },
    // -- получение баланса по выбранному счёту
    setBalance(state, action: PayloadAction<number>) {
      state.currentAccountBalance = action.payload;
    },
    // -- получение ссылок
    getRedirectUrlsRequest(state) {
      state.isLoading = true;
    },
    getRedirectUrlsSuccess(state, action: PayloadAction<IUrls>) {
      state.redirectUrls = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    getRedirectUrlsFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- получение списка счетов
    getVacantAccountsRequest(state, _: PayloadAction<string>) {
      state.accounts.isLoading = true;
    },
    getVacantAccountsSuccess(state, action: PayloadAction<IResponseGetAccounts>) {
      state.accounts = action.payload;
      state.accounts.isLoading = false;
      state.accounts.error = '';
    },
    getVacantAccountsFail(state, action: PayloadAction<string>) {
      state.accounts.isLoading = false;
      state.accounts.error = action.payload;
    },
  },
});
export const {
  tokenRequest,
  tokenSuccess,
  tokenFail,
  deleteToken,
  getWindowWidth,
  getRedirectUrlsRequest,
  getRedirectUrlsSuccess,
  getRedirectUrlsFail,
  getVacantAccountsRequest,
  getVacantAccountsSuccess,
  getVacantAccountsFail,
  setBalance,
} = customerSlice.actions;
export default customerSlice.reducer;
