import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './ReactModalSubscribe.module.scss';
import Text from '../../../entities/Text/Text';
import {
  ButtonCancel, ButtonNormal, ButtonSubscribe,
} from '../../../features/Buttons';
import ModalContent from '../../ModalContent/ModalContent';
import ModalButtonGroup from '../../../entities/ModalButtonGroup/ModalButtonGroup';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setModalContent, setModalIsOpen } from '../../../store/modal/reducer';
import { getVacantAccountsRequest } from '../../../store/customer/reducer';
import { subscribeStrategyRequest } from '../../../store/mySubscription/reducer';
import {
  validationSchema4,
  validationSchema5,
  validationSchema6,
  validationSchema7,
} from '../../../shared/schemas/schemas';
import { IStrategiesRatingItem } from '../../../store/strategiesRating/models';
import route from '../../../app/routes/routes';
import { ReactModalInfo } from '../../ReactModalInfo';
import { buttonGroupFlag } from '../../../shared/consts/consts';

interface IReactModalProps {
  button: string,
  title: string,
  args: IStrategiesRatingItem,
  buttonSize?: string
}
export interface IFormikPropsInvest {
  invest_accounts: string,
  stop_loss: number | string,
  take_profit: number | string,
  stop_loss_toggle: boolean,
  take_profit_toggle: boolean,
  fixed_vol: number | string,
  percent_vol: number | string,
  balance: number
}
const initialValues = {
  invest_accounts: '',
  stop_loss: '',
  take_profit: '',
  stop_loss_toggle: false,
  take_profit_toggle: false,
  fixed_vol: '',
  percent_vol: '',
  balance: 0,
};
export const ReactModalSubscribe = ({
  button, title, args, buttonSize,
}: IReactModalProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const sessionToken = sessionStorage.getItem('token');
  const { info, master_account } = useAppSelector((state) => state.modal);
  const { token } = useAppSelector((state) => state.customer.token);
  const {
    accounts,
    isLoading,
    currentAccountBalance,
  } = useAppSelector((state) => state.customer); // достаем список счетов
  const { flags } = useAppSelector((state) => state.customer.redirectUrls);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0);
  useEffect(() => {
    setWindowInnerWidth(window.innerWidth);
  }, []);
  const formik = useFormik<IFormikPropsInvest>({
    initialValues,
    onSubmit: async (data) => {
      dispatch(subscribeStrategyRequest({
        master_account,
        followers_account: Number(data.invest_accounts),
        type_of_copy: info.id,
        fixed_vol: info.id === 2 ? Number(data.fixed_vol) : 0,
        percent_vol: info.id === 3 ? Number(data.percent_vol) : 0,
        stop_loss: (data.stop_loss_toggle && data.stop_loss) ? Number(data.stop_loss) : 0,
        take_profit: (data.take_profit_toggle && data.take_profit) ? Number(data.take_profit) : 0,
      }));
    },
    validationSchema:
        (info.id === 1 && validationSchema4(currentAccountBalance))
        || (info.id === 2 && validationSchema5(currentAccountBalance))
        || (info.id === 3 && validationSchema6(currentAccountBalance))
        || (info.id === 4 && validationSchema7(currentAccountBalance)),
  });
  const handleReset = () => {
    formik.resetForm();
  };
  const navigate = useNavigate();
  const toggle = useCallback((value: number) => {
    if (sessionToken || token) {
      dispatch(setModalIsOpen(value));
      dispatch(setModalContent(4));
    } else navigate(route.not_found_page.catalog, { replace: true });
  }, [dispatch, navigate, sessionToken, token]);
  return (
    <div className={cx(
      {
        [styles.main_wrapper__mobile]: buttonSize === 'mobile',
      },
    )}
    >
      <ButtonSubscribe
        onClick={() => toggle(args.master_login)}
        buttonSize={buttonSize}
        handleReset={formik.handleReset}
        dispatch={dispatch}
        actions={[getVacantAccountsRequest('all')]}
      >
        <Text type="button">{t(button)}</Text>
      </ButtonSubscribe>
      {(accounts.vacant_accounts.length > 0 || accounts.invest_accounts.length > 0)
      && !isLoading
      && (
        <Modal
          isOpen={master_account === args.master_login}
          centered
          className={styles.modal_window}
          contentClassName={styles.modal_content}
          backdrop={windowInnerWidth > 545}
        >
          <ModalHeader
            className={styles.header}
            cssModule={{ 'btn-close': styles.btn_close }}
            toggle={() => toggle(0)}
          >
            {
              t('MODAL.SUBSCRIBE_INFO_CONTENT') !== ''
            && t('MODAL.SUBSCRIBE_INFO_CONTENT') !== 'MODAL.SUBSCRIBE_INFO_CONTENT'
            && (
              <div className={styles.info_wrapper}>
                <ReactModalInfo
                  title={t('OTHER.INFO_TITLE')}
                  content={t('MODAL.SUBSCRIBE_INFO_CONTENT')}
                />
                <div className={styles.info_border} />
              </div>
            )
            }
            <div className={styles.title_wrapper}>
              <Text type="h1">{`${t(title)} ${args.name}`}</Text>
            </div>

          </ModalHeader>
          <ModalBody
            className={styles.modal_body}
          >
            {
              flags.includes(buttonGroupFlag) && <ModalButtonGroup onResetForm={handleReset} />
            }
            <ModalContent
              formik={formik}
              recommended_amount={args.recommended_amount}
            />
          </ModalBody>
          <ModalFooter
            className={styles.footer}
          >
            <ButtonNormal
              disabled={
              !formik.isValid
                  || (info.id === 2 && formik.values.fixed_vol === '')
                  || (info.id === 3 && formik.values.percent_vol === '')
}
              onClick={() => {
                formik.handleSubmit();
                setTimeout(() => toggle(0));
              }}
            >
              <Text type="button">{t('MODAL.SUBSCRIBE')}</Text>
            </ButtonNormal>
            {' '}
            <ButtonCancel
              onClick={() => toggle(0)}
            >
              <Text type="button">{t('MODAL.CANCEL_BTN')}</Text>
            </ButtonCancel>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
