/* eslint-disable no-plusplus,max-len,prefer-const,no-return-assign */
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Spinner } from 'reactstrap';
import i18next from 'i18next';
import { getCookie } from 'typescript-cookie';
import styles from './Charts.module.scss';
import { useAppSelector } from '../../hooks/hooks';

const gradient_background_plugin = {
  id: 'gradient_background_plugin',
  afterLayout(chart: Chart): boolean | void {
    const {
      ctx, scales, chartArea,
    } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    ctx.save();
    const yAxis = scales.y;
    const yThreshold = yAxis.getPixelForValue(0);
    const offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
    // eslint-disable-next-line prefer-const
    let backgroundGradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
    backgroundGradient.addColorStop(0, 'rgba(45, 100, 233, 0.6)');
    backgroundGradient.addColorStop(offset, 'rgba(45,100,233,0)');
    backgroundGradient.addColorStop(offset, 'rgba(207, 68, 135, 0)');
    backgroundGradient.addColorStop(1, 'rgba(207, 68, 135, 0.6)');
    chart.data.datasets[0].backgroundColor = backgroundGradient;
    ctx.restore();
  },
};
const gradient_border_plugin = {
  id: 'gradient_border_plugin',
  afterLayout(chart: Chart): boolean | void {
    const {
      ctx, scales, chartArea,
    } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    ctx.save();
    const yAxis = scales.y;
    const yThreshold = yAxis.getPixelForValue(0);
    const offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
    // eslint-disable-next-line prefer-const
    let borderGradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
    borderGradient.addColorStop(0, 'rgba(45,100,233,0.6)');
    borderGradient.addColorStop(offset, 'rgba(45,100,233,0.6)');
    borderGradient.addColorStop(offset, 'rgba(207,68,135,0.6)');
    borderGradient.addColorStop(1, 'rgba(207,68,135,0.6)');
    chart.data.datasets[0].borderColor = borderGradient;
    ctx.restore();
  },
};
const Charts = () => {
  const { isLoading, data } = useAppSelector(
    (state) => state.strategyInfo,
  );
  const { charts } = data;
  // ru-Ru для русского
  const dateFormat = getCookie('i18next');
  const dateLong = (date: string) => new Intl.DateTimeFormat(dateFormat, { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(date));
  const dateShort = (date: string) => new Intl.DateTimeFormat(dateFormat, { dateStyle: 'medium' }).format(new Date(date));
  const { windowWidth } = useAppSelector((state) => state.customer);
  const [ticksLimit, setTicksLimit] = useState<number>(1);

  useEffect(() => {
    setTicksLimit(windowWidth <= 545 ? 1 : 3);
  }, [windowWidth]);
  return (
    <>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && charts?.length > 0 && (
      <div
        className={styles.chart_wrapper}
      >
        <Line
          data={{
            labels: charts.map((el) => el.dt),
            datasets: [
              // ---------------Summary Chart---------------
              {
                label: '',
                data: charts.map((el) => el.yield * 100),
                borderColor: '#2d64e9',
                tension: 0.3,
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 3,
                pointBackgroundColor: '#FFF',
                pointBorderColor: '#2d64e9',
                pointHitRadius: 20,
                fill: true,
              },
              // ---------------Summary Chart---------------
              // {
              //   label: i18next.t(charts?.data_sets[1].name),
              //   data: charts?.data_sets[1].data,
              //   borderColor: '#63B121',
              //   tension: 0.3,
              //   pointRadius: 0,
              //   pointHoverRadius: 4,
              //   pointHoverBorderWidth: 3,
              //   pointBackgroundColor: '#FFF',
              //   pointBorderColor: '#63B121',
              //   pointHitRadius: 20,
              //   fill: {
              //     target: { value: 0 },
              //     above: 'rgba(99, 177, 33, 0.1)',
              //     below: 'rgba(207, 142, 68, 0.1)',
              //   },
              // },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              title: {
                display: true,
                font: {
                  family: 'Gotham-Book, sans-serif',
                },
                text: i18next.t('STRATEGIES_RATING.CHART_TITLE'),
              },
              zoom: {
                zoom: {
                  wheel: windowWidth <= 545 ? { enabled: false } : { enabled: true },
                  pinch: windowWidth <= 545 ? { enabled: false } : { enabled: true },
                },
                pan: { enabled: true },
                limits: {
                  x: { min: 'original', max: 'original', minRange: 2 },
                  y: { min: 'original', max: 'original', minRange: 20 },
                },
              },
              legend: {
                onClick(e, legendItem, legend) {
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  if (ci.isDatasetVisible(index as number)) {
                    if (index != null) {
                      ci.hide(index);
                    }
                    legendItem.hidden = true;
                  } else {
                    if (index != null) {
                      ci.resetZoom();
                      ci.show(index);
                    }
                    legendItem.hidden = false;
                  }
                },
                align: 'end',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'line',
                  font: {
                    family: 'Gotham-Book, sans-serif',
                  },
                },
                display: false,
              },
              tooltip: {
                callbacks: {
                  title(tooltipItems) {
                    return dateLong(tooltipItems[0].label);
                  },
                  label(tooltipItem) {
                    return tooltipItem.formattedValue += ' %';
                  },
                },
                displayColors: false,
                titleFont: {
                  family: 'Sans-serif',
                },
                bodyFont: {
                  family: 'Sans-serif',
                },
              },
            },
            scales: {
              y: {
                position: 'right',
                beginAtZero: true,
                grid: {
                  drawBorder: false,
                  color: (context: { tick: { value: number } }) => (context.tick.value === 0 ? '#000' : '#DDD'),
                },
              },
              x: {
                beginAtZero: true,
                ticks: {
                  align: 'inner',
                  font: {
                    family: 'Gotham-Book, sans-serif',
                  },
                  minRotation: 0,
                  maxRotation: 0,
                  maxTicksLimit: ticksLimit,
                },
                // добавление последнего тика в массив
                afterUpdate: (axis) => {
                  let increment = 0;
                  if (
                    axis.max === charts.length - 1
                    && axis.max !== axis.ticks[axis.ticks.length - 1].value
                  ) {
                    axis.ticks = [
                      ...axis.ticks,
                      {
                        value: axis.max,
                        label: charts[charts.length - 1].dt,
                      },
                    ];
                  }
                  // @ts-ignore
                  axis.ticks = axis.ticks.reduce((prev, curr, index) => {
                    if (curr.label
                    // @ts-ignore
                          && new Date(curr?.label).getDate() === new Date(prev[prev.length - 1]?.label).getDate()
                          // @ts-ignore
                          && new Date(curr?.label).getMonth() === new Date(prev[prev.length - 1]?.label).getMonth()
                    ) {
                      increment++;
                    }
                    if (curr) {
                      // @ts-ignore
                      prev.push(curr);
                    }
                    if (index === axis.ticks.length - 1) {
                      if (increment > 0) {
                        return prev.map(
                          (el) => (
                            {
                              // @ts-ignore
                              value: el.value,
                              // @ts-ignore
                              label: dateLong(el.label),
                            }
                          ),
                        );
                      }
                      return prev.map((el) => (
                        {
                          // @ts-ignore
                          value: el.value,
                          // @ts-ignore
                          label: dateShort(el.label),
                        }
                      ));
                    }
                    return prev;
                  }, []);
                },
              },
            },
          }}
          plugins={[gradient_background_plugin, gradient_border_plugin]}
        />
      </div>
      )}
    </>
  );
};
Chart.register(...registerables, zoomPlugin);
export default Charts;
