import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  currSortMethod, currSortType, IDataStrategies, IStrategiesRatingResponse,
} from './models';

const initialState: IStrategiesRatingResponse = {
  data: {
    list: [],
  },
  pagination: {
    currentPage: 1,
    ratePageSize: 1,
  },
  sort: {
    sort_types: ['profitability', 'max_drop', 'leverage', 'age', 'total_followers', 'name'],
    curr_sort_type: 'name',
    curr_sort_method: 'default',
    curr_sort_label: '',
  },
  isLoading: false,
  error: '',
};

const strategiesRatingSlice = createSlice({
  name: 'strategiesRating',
  initialState,
  reducers: {
    // -- получение списка стратегий
    getStrategiesRequest(state) {
      state.isLoading = true;
    },
    getStrategiesSuccess(state, action: PayloadAction<IDataStrategies>) {
      state.data = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    getStrategiesFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeSortType(state, action: PayloadAction<currSortType>) {
      state.sort.curr_sort_type = action.payload;
    },
    changeSortMethod(state, action: PayloadAction<currSortMethod>) {
      state.sort.curr_sort_method = action.payload;
    },
    setCurrentSortLabel(state, action: PayloadAction<string>) {
      state.sort.curr_sort_label = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setRatePageSize(state, action: PayloadAction<number>) {
      state.pagination.ratePageSize = action.payload;
    },
  },
});
export const {
  getStrategiesRequest,
  getStrategiesSuccess,
  getStrategiesFail,
  changeSortType,
  changeSortMethod,
  setCurrentPage,
  setRatePageSize,
  setCurrentSortLabel,
} = strategiesRatingSlice.actions;
export default strategiesRatingSlice.reducer;
