/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';

// eslint-disable-next-line max-len
export type TextColorsType = 'primary' | 'white' | 'gray' | 'gray-2' | 'gray-light' | 'gray-dark' | 'black' | 'green' | 'red' | 'blue' | '';

export type TextProps = {
    type?: 'h1'
        | 'h2'
        | 'h3'
        | 'mobile-menu'
        | 'button'
        | 'text'
        | 'notification-text'
        | 'page-notification-mobile'
        | 'page-notification'
        | 'sub-description'
        | 'caption'
        | 'info'
        | 'account'
        | '',
    children: React.ReactNode,
    className?: string,
    align?: 'left' | 'center' | 'right',
    color?: TextColorsType,
    onClick?: () => void,
};

const Text: React.FC<TextProps> = ({
  children,
  type,
  className,
  align,
  color,
  onClick,
}: TextProps) => (
  <span
    onClick={onClick}
    className={cx(
      // @ts-ignore
      styles.text,
      {
        // @ts-ignore
        [styles[`text--type--${type || ''}`]]: type,
        // @ts-ignore
        [styles[`text--align--${align || ''}`]]: align,
        // @ts-ignore
        [styles[`text--color--${color || ''}`]]: color,
      },
      className,
    )}
  >
    {children}
  </span>
);

export default Text;
