import {
  put, takeLeading, call,
} from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getStrategyInfoSuccess,
  getStrategyInfoFail,
  getStrategyChartSuccess,
  getStrategyChartFail,
  getStrategyChartRequest,
} from './reducer';

import { baseUrl } from '../../shared/consts/baseUrl';
import { notificationModalOpen } from '../notification/reducer';

function* getStrategyChartSaga({ payload }: PayloadAction<string>) {
  try {
    const resp: {status: number, data: any} = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: `strategy/charts/profitability/${payload}`,
    }));
    yield put(getStrategyChartSuccess(resp.data));
  } catch (e: any) {
    yield put(getStrategyChartFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_STRATEGY_INFO.TITLE',
      message: `NOTIFICATION.GET_STRATEGY_INFO.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* getStrategyInfoSaga({ payload }: PayloadAction<string>) {
  try {
    const resp: {status: number, data: any} = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: `strategy/${payload}`,
    }));
    yield put(getStrategyInfoSuccess(resp.data));
    yield put(getStrategyChartRequest(payload));
  } catch (e: any) {
    yield put(getStrategyInfoFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_STRATEGY_INFO.TITLE',
      message: `NOTIFICATION.GET_STRATEGY_INFO.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}

export function* strategyInfoWatcher() {
  yield takeLeading('strategyInfo/getStrategyInfoRequest', getStrategyInfoSaga);
  yield takeLeading('strategyInfo/getStrategyChartRequest', getStrategyChartSaga);
}
