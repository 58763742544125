import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './SupportLink.module.scss';
import envelopeIcon from '../../shared/assets/icons/envelope_icon.svg';
import Text from '../Text/Text';

const SupportLink = () => {
  const { t } = useTranslation();
  return (

    <a
      href={`mailto:${t('OTHER.SUPPORT_LINK')}`}
      className={cx(
        styles.support_link_wrapper,
      )}
    >
      <img src={envelopeIcon} alt="" />
      <Text type="button" className={styles.support_link}>
        {t('OTHER.SUPPORT_LINK')}
      </Text>
    </a>

  );
};

export default SupportLink;
