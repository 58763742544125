import React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import MyStrategyInfoDesktop from './MyStrategyInfoDesktop/MyStrategyInfoDesktop';

function MyStrategyInfo() {
  const { windowWidth } = useAppSelector((state) => state.customer);

  return (
    windowWidth <= 545
      ? null
      : <MyStrategyInfoDesktop />
  );
}

export default MyStrategyInfo;
