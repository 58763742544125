import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import route from '../../app/routes/routes';
import { useAppSelector } from '../../hooks/hooks';
import notfoundDesktopIcon from '../../shared/assets/icons/404desktop.svg';
import notfoundMobileIcon from '../../shared/assets/icons/404mobile.svg';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  const sessionToken = sessionStorage.getItem('token');
  const { windowWidth, isLoading } = useAppSelector((state) => state.customer);
  const { token } = useAppSelector((state) => state.customer.token);
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionToken || token) navigate(route.root, { replace: true });
  }, [token, navigate, sessionToken]);
  return (
    <>
      {isLoading && (
      <Spinner
        className={styles.spinner}
      />
      )}
      {!isLoading && (
        windowWidth <= 545
          ? (
            <div
              className={styles.logo_wrapper}
            >
              <img src={notfoundMobileIcon} alt="" />
            </div>
          )
          : (
            <div
              className={styles.logo_wrapper}
            >
              <img src={notfoundDesktopIcon} alt="" />
            </div>
          )
      )}
    </>

  );
};

export default NotFoundPage;
