import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActiveSubscriptionItem,
  IRequestEditSubscription,
  IClosedSubscriptionItem,
  ISubscriptionState,
  IRequestSubscribeStrategy,
  ISubscriptionData,
  currSortMethod,
  currSortType,
} from './models';

const initialState: ISubscriptionState = {
  active_subscriptions: [],
  closed_subscriptions: [],
  isActiveSubscriptionsLoading: false,
  isClosedSubscriptionsLoading: false,
  current_subscription: {
    strategy_name: '',
    id: 0,
    type_of_copy: 1,
    fixed_vol: '',
    percent_vol: '',
    stop_loss: '',
    take_profit: '',
    balance: 0,
  },
  sort: {
    sort_types: ['total_pnl', 'balance', 'equity', 'account'],
    curr_sort_type: 'account',
    curr_sort_method: 'default',
    curr_sort_label: '',
  },
  isLoading: false,
  info: {
    isActive: true,
    showActive: [0],
  },
  error: '',
};
const mySubscriptionsSlice = createSlice({
  name: 'mySubscriptions',
  initialState,
  reducers: {
    // -- получение активных подписок
    getActiveSubscriptionsRequest(state) {
      state.isActiveSubscriptionsLoading = true;
    },
    getActiveSubscriptionsSuccess(state, action: PayloadAction<IActiveSubscriptionItem[]>) {
      state.active_subscriptions = action.payload;
      state.isActiveSubscriptionsLoading = false;
      state.error = '';
    },
    getActiveSubscriptionsFail(state, action: PayloadAction<string>) {
      state.isActiveSubscriptionsLoading = false;
      state.error = action.payload;
    },
    // -- получение закрытых подписок
    getClosedSubscriptionsRequest(state) {
      state.isClosedSubscriptionsLoading = true;
    },
    getClosedSubscriptionsSuccess(state, action: PayloadAction<IClosedSubscriptionItem[]>) {
      state.closed_subscriptions = action.payload;
      state.isClosedSubscriptionsLoading = false;
      state.error = '';
    },
    getClosedSubscriptionsFail(state, action: PayloadAction<string>) {
      state.isClosedSubscriptionsLoading = false;
      state.error = action.payload;
    },
    // -- подписка на стратегию
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subscribeStrategyRequest(state, _: PayloadAction<IRequestSubscribeStrategy>) {
      state.isLoading = true;
    },
    subscribeStrategySuccess(state) {
      state.isLoading = false;
    },
    subscribeStrategyFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- переключение на активные/закрытые подписки
    setSubscriptionContent(state, action: PayloadAction<boolean>) {
      state.info.isActive = action.payload;
    },
    // -- пауза подписки
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pauseSubscriptionRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
    },
    pauseSubscriptionSuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    pauseSubscriptionFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- возобновление подписки
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resumeSubscriptionRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
    },
    resumeSubscriptionSuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    resumeSubscriptionFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- удаление подписки
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSubscriptionRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
    },
    deleteSubscriptionSuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    deleteSubscriptionFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- редактирование подписки получение данных
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editSubscriptionGetDataRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
    },
    editSubscriptionGetDataSuccess(state, action: PayloadAction<ISubscriptionData>) {
      state.current_subscription = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    editSubscriptionGetDataFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // -- редактирование подписки данных
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editSubscriptionRequest(state, action: PayloadAction<IRequestEditSubscription>) {
      state.isLoading = true;
    },
    editSubscriptionSuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    editSubscriptionFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeSortType(state, action: PayloadAction<currSortType>) {
      state.sort.curr_sort_type = action.payload;
    },
    changeSortMethod(state, action: PayloadAction<currSortMethod>) {
      state.sort.curr_sort_method = action.payload;
    },
    setCurrentSubsSortLabel(state, action: PayloadAction<string>) {
      state.sort.curr_sort_label = action.payload;
    },
    setShowActive(state, action: PayloadAction<number[]>) {
      state.info.showActive = action.payload;
    },
  },
});
export const {
  getActiveSubscriptionsRequest,
  getActiveSubscriptionsSuccess,
  getActiveSubscriptionsFail,
  getClosedSubscriptionsRequest,
  getClosedSubscriptionsSuccess,
  getClosedSubscriptionsFail,
  subscribeStrategyRequest,
  subscribeStrategySuccess,
  subscribeStrategyFail,
  setSubscriptionContent,
  pauseSubscriptionRequest,
  pauseSubscriptionSuccess,
  pauseSubscriptionFail,
  resumeSubscriptionRequest,
  resumeSubscriptionSuccess,
  resumeSubscriptionFail,
  deleteSubscriptionRequest,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  editSubscriptionGetDataRequest,
  editSubscriptionGetDataSuccess,
  editSubscriptionGetDataFail,
  editSubscriptionRequest,
  editSubscriptionSuccess,
  editSubscriptionFail,
  changeSortType,
  changeSortMethod,
  setShowActive,
  setCurrentSubsSortLabel,
} = mySubscriptionsSlice.actions;
export default mySubscriptionsSlice.reducer;
