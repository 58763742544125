import React, { FC, useState } from 'react';
import {
  Form, Input, Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import ModalSelector from '../../ModalSelectors/ModalSelector';
import styles from './EditSubscriptionContent.module.scss';
import ToggleSwitch from '../../../entities/ToggleSwitch/ToggleSwitch';
import { useAppSelector } from '../../../hooks/hooks';
import Text from '../../../entities/Text/Text';
import Feedback from '../../../entities/Feedback/Feedback';
import { IFormikPropsEdit } from '../../ReactModalEditSubscription/model/types/types';

interface IProps {
  formik: FormikProps<IFormikPropsEdit>
}

const EditSubscriptionContent: FC<IProps> = ({ formik }) => {
  const { id } = useAppSelector((state) => state.modal.info);
  const { current_subscription } = useAppSelector((state) => state.mySubscriptions); // список счетов
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const [field, setField] = useState('');
  const focusHandler = (newField: string) => {
    setIsFocus(true);
    setField(newField);
  };
  const blurHandler = () => {
    setIsFocus(false);
  };
  return (
    <>
      {
          id === 1 // как у мастера
            && (
            <Form>
              <Label className={styles.label_description}>
                <Text type="sub-description">{t('MODAL.DESCRIPTION_INVEST_LOT_SIZE')}</Text>
              </Label>
              <div className={styles.container}>
                <Label className={styles.selector_label}>
                  <Text type="text">{t('MODAL.CHOOSE_ACCOUNT')}</Text>
                </Label>
                <ModalSelector
                  values={current_subscription}
                  name="edit_account"
                  formik={formik}
                />
              </div>
              <div className={styles.container__toggle}>
                <ToggleSwitch
                  name="stop_loss_toggle"
                  label={t('MODAL.TOGGLE_SWITCH_LOSS')}// stop loss
                  formik={formik}
                />
                <div
                  className={styles.input__container}
                >
                  <Input
                    cssModule={{
                      'is-invalid': styles.is_invalid,
                    }}
                    invalid={
                        !!formik.touched.stop_loss
                        && !!formik.errors.stop_loss
                        && (field !== 'stop_loss' || !isFocus)
                    }
                    className={styles.input}
                    maxLength={11}
                    type="number"
                    {...formik.getFieldProps('stop_loss')}
                    disabled={!formik.values.stop_loss_toggle}
                    onFocus={() => focusHandler('stop_loss')}
                    onBlur={(e) => {
                      blurHandler();
                      formik.handleBlur(e);
                    }}
                    autoComplete="off"
                  />
                  <Text
                    className={styles.input__icon}
                    type="text"
                  >
                    $
                  </Text>
                  {/* <img src={questionMark} alt="" /> */}
                  <Feedback isHideHint name="stop_loss" isFocus={isFocus} formik={formik} field={field} />
                </div>
              </div>
              <div className={styles.container__toggle}>
                <ToggleSwitch
                  name="take_profit_toggle"
                  label={t('MODAL.TOGGLE_SWITCH_PROFIT')}// take profit
                  formik={formik}
                />
                <div
                  className={styles.input__container}
                >
                  <Input
                    cssModule={{
                      'is-invalid': styles.is_invalid,
                    }}
                    invalid={
                        !!formik.touched.take_profit
                        && !!formik.errors.take_profit
                        && (field !== 'take_profit' || !isFocus)
                    }
                    className={styles.input}
                    maxLength={11}
                    type="number"
                    {...formik.getFieldProps('take_profit')}
                    disabled={!formik.values.take_profit_toggle}
                    onFocus={() => focusHandler('take_profit')}
                    onBlur={(e) => {
                      blurHandler();
                      formik.handleBlur(e);
                    }}
                    autoComplete="off"
                  />
                  <Text
                    className={styles.input__icon}
                    type="text"
                  >
                    $
                  </Text>
                  {/* <img src={questionMark} alt="" /> */}
                  <Feedback isHideHint name="take_profit" isFocus={isFocus} formik={formik} field={field} />
                </div>
              </div>
            </Form>
            )
}
      {
          id === 2 // с fix объёмом
                && (
                <Form>
                  <Label className={styles.label_description}>
                    <Text type="sub-description">{t('MODAL.DESCRIPTION_INVEST_FIXED')}</Text>
                  </Label>
                  <div className={styles.container}>
                    <Label className={styles.selector_label}>
                      <Text type="text">{t('MODAL.CHOOSE_ACCOUNT')}</Text>
                    </Label>
                    <ModalSelector
                      values={current_subscription}
                      name="edit_account"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.container}>
                    <Label className={styles.selector_label}>
                      <Text type="text">{t('MODAL.LOT_SIZE')}</Text>
                    </Label>
                    <div className={styles.input__container}>
                      <Input // объём лота
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.fixed_vol
                            && !!formik.errors.fixed_vol
                            && (field !== 'fixed_vol' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={3}
                        type="number"
                        {...formik.getFieldProps('fixed_vol')}
                        onFocus={() => focusHandler('fixed_vol')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback name="fixed_vol" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="stop_loss_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_LOSS')}// stop loss
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.stop_loss
                            && !!formik.errors.stop_loss
                            && (field !== 'stop_loss' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('stop_loss')}
                        disabled={!formik.values.stop_loss_toggle}
                        onFocus={() => focusHandler('stop_loss')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      <Feedback isHideHint name="stop_loss" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="take_profit_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_PROFIT')}// take profit
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.take_profit
                            && !!formik.errors.take_profit
                            && (field !== 'take_profit' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('take_profit')}
                        disabled={!formik.values.take_profit_toggle}
                        onFocus={() => focusHandler('take_profit')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback isHideHint name="take_profit" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                </Form>
                )
      }
      {
          id === 3 // с коэффициентом
                && (
                <Form>
                  <Label className={styles.label_description}>
                    <Text type="sub-description">{t('MODAL.DESCRIPTION_INVEST_COEFFICIENT')}</Text>
                  </Label>
                  <div className={styles.container}>
                    <Label className={styles.selector_label}>
                      <Text type="text">{t('MODAL.CHOOSE_ACCOUNT')}</Text>
                    </Label>
                    <ModalSelector
                      values={current_subscription}
                      name="edit_account"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.container}>
                    <Label className={styles.selector_label}>
                      <Text type="text">{t('MODAL.COEFFICIENT')}</Text>
                    </Label>
                    <div className={styles.input__container}>
                      <Input // коэффициент
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.percent_vol
                            && !!formik.errors.percent_vol
                            && (field !== 'percent_vol' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={2}
                        type="number"
                        {...formik.getFieldProps('percent_vol')}
                        onFocus={() => focusHandler('percent_vol')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        %
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback name="percent_vol" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="stop_loss_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_LOSS')}// stop loss
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.stop_loss
                            && !!formik.errors.stop_loss
                            && (field !== 'stop_loss' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('stop_loss')}
                        disabled={!formik.values.stop_loss_toggle}
                        onFocus={() => focusHandler('stop_loss')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback isHideHint name="stop_loss" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="take_profit_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_PROFIT')}// take profit
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.take_profit
                            && !!formik.errors.take_profit
                            && (field !== 'take_profit' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('take_profit')}
                        disabled={!formik.values.take_profit_toggle}
                        onFocus={() => focusHandler('take_profit')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback isHideHint name="take_profit" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                </Form>
                )
            }
      {
          id === 4 // автоподбор
                && (
                <Form>
                  <Label className={styles.label_description}>
                    <Text type="sub-description">{t('MODAL.DESCRIPTION_INVEST_AUTO')}</Text>
                  </Label>
                  <div className={styles.container}>
                    <Label className={styles.selector_label}>
                      <Text type="text">{t('MODAL.CHOOSE_ACCOUNT')}</Text>
                    </Label>
                    <ModalSelector
                      values={current_subscription}
                      name="edit_account"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="stop_loss_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_LOSS')}// stop loss
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.stop_loss
                            && !!formik.errors.stop_loss
                            && (field !== 'stop_loss' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('stop_loss')}
                        disabled={!formik.values.stop_loss_toggle}
                        onFocus={() => focusHandler('stop_loss')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback isHideHint name="stop_loss" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                  <div className={styles.container__toggle}>
                    <ToggleSwitch
                      name="take_profit_toggle"
                      label={t('MODAL.TOGGLE_SWITCH_PROFIT')}// take profit
                      formik={formik}
                    />
                    <div
                      className={styles.input__container}
                    >
                      <Input
                        cssModule={{
                          'is-invalid': styles.is_invalid,
                        }}
                        invalid={
                            !!formik.touched.take_profit
                            && !!formik.errors.take_profit
                            && (field !== 'take_profit' || !isFocus)
                        }
                        className={styles.input}
                        maxLength={11}
                        type="number"
                        {...formik.getFieldProps('take_profit')}
                        disabled={!formik.values.take_profit_toggle}
                        onFocus={() => focusHandler('take_profit')}
                        onBlur={(e) => {
                          blurHandler();
                          formik.handleBlur(e);
                        }}
                        autoComplete="off"
                      />
                      <Text
                        className={styles.input__icon}
                        type="text"
                      >
                        $
                      </Text>
                      {/* <img src={questionMark} alt="" /> */}
                      <Feedback isHideHint name="take_profit" isFocus={isFocus} formik={formik} field={field} />
                    </div>
                  </div>
                </Form>
                )
}
    </>
  );
};

export default EditSubscriptionContent;
