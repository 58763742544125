import React from 'react';
import { useMedia } from 'react-use';
import StrategiesRatingMobile from './StrategiesRatingMobile/StrategiesRatingMobile';
import StrategiesRatingDesktop from './StrategiesRatingDesktop/StrategiesRatingDesktop';

function StrategiesRating() {
  const isMobile = useMedia('(max-width: 545px)');
  return (
    isMobile
      ? <StrategiesRatingMobile />
      : <StrategiesRatingDesktop />
  );
}

export default StrategiesRating;
