/* eslint-disable prefer-const,consistent-return */
import { useMemo } from 'react';

interface IUsePagination {
    totalCount: number,
    pageSize: number,
    siblingCount: number,
    currentPage: number,
}
const range = (start: number, end: number) => {
  const length = end - start + 1;
  /*
    Создание массива с номерами страниц.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};
export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount,
  currentPage,
}: IUsePagination) => useMemo(() => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  // Число цифр в ряде (siblingCount + siblingCount + firstPage + lastPage + currentPage)
  const totalPageNumbers = siblingCount + 5;

  /*
      Case 1:
      Если страниц меньше чем totalPageNumbers, рендер страниц от 1 до totalPageCount
   */
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount);
  }
  /*
     Вычисление позиции ближайшего от края соседа от номера страницы
  */
  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
  /*
     Вычисление нужно ли ставить точки при позиции ближайшего к краю соседнего числа,
     если между крайним числом и ближайшим соседом хотя бы одно число, то точки ставятся
    */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;
  /*
       Case 2:
       Количество страниц слева, справа последняя, справа есть точки
    */
  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 2 + 2 * siblingCount;
    let leftRange = range(1, leftItemCount);

    return [...leftRange, 'dotsRight', totalPageCount];
  }
  /*
      Case 3:
       Количество страниц справа, слева первая, слева есть точки
  */
  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 2 + 2 * siblingCount;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
    return [firstPageIndex, 'dotsLeft', ...rightRange];
  }
  /*
       Case 4:
       Точки имеются слева и справа
   */
  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, 'dotsBoth', ...middleRange, lastPageIndex];
  }
}, [totalCount, pageSize, siblingCount, currentPage]);
