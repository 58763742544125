import * as yup from 'yup';
import i18next from 'i18next';
import { IStrategyRequestKeys } from '../../store/myStrategies/models';
// схема 1-3 для создания стратегии
export const validationSchema1 = (limitValidation: IStrategyRequestKeys[]) => yup.object().shape({
  strategy_name: yup // проценты
    .string().min(3, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .matches(/^([a-zA-Z0-9]+(\s)*[a-zA-Z0-9]+)+$/g, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  strategy_reward_value: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_PERCENT_ERROR'))
    .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
    .min(limitValidation[0].min * 100, i18next.t('MODAL.VALIDATE_PERCENT_ERROR'))
    .max(limitValidation[0].max * 100, i18next.t('MODAL.VALIDATE_PERCENT_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  recommended_amount: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .min(100, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .max(1000000, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .integer(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .required(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE')),
});
export const validationSchema2 = (limitValidation: IStrategyRequestKeys[]) => yup.object().shape({
  strategy_name: yup // фикс
    .string().min(3, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .matches(/^([a-zA-Z0-9]+(\s)*[a-zA-Z0-9]+)+$/g, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  strategy_reward_value: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_FIX_ERROR'))
    .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
    .min(limitValidation[0].min, i18next.t('MODAL.VALIDATE_FIX_ERROR'))
    .max(limitValidation[0].max, i18next.t('MODAL.VALIDATE_FIX_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  recommended_amount: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .min(100, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .max(1000000, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .integer(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .required(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE')),
});
export const validationSchema3 = (limitValidation: IStrategyRequestKeys[]) => yup.object().shape({
  strategy_name: yup // фикс за период
    .string().min(3, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .matches(/^([a-zA-Z0-9]+(\s)*[a-zA-Z0-9]+)+$/g, i18next.t('MODAL.VALIDATE_STRATEGY_NAME_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  strategy_reward_value: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_FIX_DELAY_ERROR'))
    // .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
    .min(limitValidation[0].min, i18next.t('MODAL.VALIDATE_FIX_DELAY_ERROR'))
    .max(limitValidation[0].max, i18next.t('MODAL.VALIDATE_FIX_DELAY_ERROR'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  // period_payment: yup
  //   .number().typeError(i18next.t('MODAL.VALIDATE_DELAY_ERROR'))
  //   .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
  // .min(limitValidation[1].min / 60 / 60 / 24, i18next.t('MODAL.VALIDATE_DELAY_ERROR'))
  // .max(limitValidation[1].max / 60 / 60 / 24, i18next.t('MODAL.VALIDATE_DELAY_ERROR'))
  // .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
  recommended_amount: yup
    .number().typeError(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .min(100, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .max(1000000, i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .integer(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE'))
    .required(i18next.t('MODAL.VALIDATE_RECOMMENDED_VALUE')),
});
// схемы 4-7 для подписок
export const validationSchema4 = (balance: number) => yup.object().shape({
  stop_loss_toggle: yup
    .boolean(),
  take_profit_toggle: yup
    .boolean(),
  stop_loss: yup
    .number()
    .when('stop_loss_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .max(Math.floor(balance * 0.99), i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  take_profit: yup
    .number()
    .when('take_profit_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .max(99999999999, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
});
export const validationSchema5 = (balance: number) => yup.object().shape({
  stop_loss_toggle: yup
    .boolean(),
  take_profit_toggle: yup
    .boolean(),
  stop_loss: yup
    .number()
    .when('stop_loss_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .max(Math.round(balance * 0.99), i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  take_profit: yup
    .number()
    .when('take_profit_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .max(99999999999, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  fixed_vol: yup
    .number()
    .typeError(i18next.t('MODAL.VALIDATE_LOT_VOLUME'))
    .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
    .min(1, i18next.t('MODAL.VALIDATE_LOT_VOLUME'))
    .max(10000, i18next.t('MODAL.VALIDATE_LOT_VOLUME'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
});
export const validationSchema6 = (balance: number) => yup.object().shape({
  stop_loss_toggle: yup
    .boolean(),
  take_profit_toggle: yup
    .boolean(),
  stop_loss: yup
    .number()
    .when('stop_loss_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .max(Math.round(balance * 0.99), i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  take_profit: yup
    .number()
    .when('take_profit_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .max(99999999999, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  percent_vol: yup
    .number()
    .typeError(i18next.t('MODAL.VALIDATE_RATIO'))
    .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
    .min(1, i18next.t('MODAL.VALIDATE_RATIO'))
    .max(1000, i18next.t('MODAL.VALIDATE_RATIO'))
    .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
});
export const validationSchema7 = (balance: number) => yup.object().shape({
  stop_loss_toggle: yup
    .boolean(),
  take_profit_toggle: yup
    .boolean(),
  stop_loss: yup
    .number()
    .when('stop_loss_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .max(Math.round(balance * 0.99), i18next.t('MODAL.VALIDATE_SUBSCRIBE_SL'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  take_profit: yup
    .number()
    .when('take_profit_toggle', {
      is: true,
      then: yup
        .number()
        .typeError(i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
        .min(1, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .max(99999999999, i18next.t('MODAL.VALIDATE_SUBSCRIBE_TP'))
        .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
    }),
  // percent_vol: yup
  //   .number()
  //   .typeError(i18next.t('MODAL.VALIDATE_RATIO'))
  //   .integer(i18next.t('MODAL.VALIDATE_SUBSCRIBE_INTEGER'))
  //   .min(1, i18next.t('MODAL.VALIDATE_RATIO'))
  //   .max(1000, i18next.t('MODAL.VALIDATE_RATIO'))
  //   .required(i18next.t('MODAL.VALIDATE_REQUIRED')),
});
export const searchValidationSchema = () => yup.object().shape({
  searchInput: yup
    .string()
    .matches(/^([a-zA-Z0-9])*$/g, i18next.t('STRATEGIES_RATING.VALIDATE_ERROR_SEARCH')),
});
