import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart, registerables,
} from 'chart.js';
import styles from './MiniChart.module.scss';

interface IChart {
  data: number[],
}

const gradient_background_plugin = {
  id: 'gradient_background_plugin',
  afterLayout(chart: Chart): boolean | void {
    const {
      ctx, scales, chartArea,
    } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    ctx.save();
    const yAxis = scales.y;
    const yThreshold = yAxis.getPixelForValue(0);
    const offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
    // eslint-disable-next-line prefer-const
    let backgroundGradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
    backgroundGradient.addColorStop(0, 'rgba(45, 100, 233, 0.6)');
    backgroundGradient.addColorStop(offset, 'rgba(45,100,233,0)');
    backgroundGradient.addColorStop(offset, 'rgba(207, 68, 135, 0)');
    backgroundGradient.addColorStop(1, 'rgba(207, 68, 135, 0.6)');
    chart.data.datasets[0].backgroundColor = backgroundGradient;
    ctx.restore();
  },
};
const gradient_border_plugin = {
  id: 'gradient_border_plugin',
  afterLayout(chart: Chart): boolean | void {
    const {
      ctx, scales, chartArea,
    } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    ctx.save();
    const yAxis = scales.y;
    const yThreshold = yAxis.getPixelForValue(0);
    const offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
    // eslint-disable-next-line prefer-const
    let borderGradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
    borderGradient.addColorStop(0, 'rgba(45,100,233,0.6)');
    borderGradient.addColorStop(offset, 'rgba(45,100,233,0.6)');
    borderGradient.addColorStop(offset, 'rgba(207,68,135,0.6)');
    borderGradient.addColorStop(1, 'rgba(207,68,135,0.6)');
    chart.data.datasets[0].borderColor = borderGradient;
    ctx.restore();
  },
};
const MiniChart = ({ data }: IChart) => (
  <div
    className={styles.chart_container}
  >
    <Line
      data={{
        labels: data,
        datasets: [
          {
            data: data?.map((el) => el * 100),
            tension: 0.3,
            pointRadius: 0,
            fill: true,
            // fill: {
            //   target: { value: 0 },
            //   above: 'rgba(45,100,233,0.2)',
            //   below: 'rgba(207,68,135,0.2)',
            // },
          },
        ],
      }}
      options={{
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: false,
      }}
      plugins={[gradient_background_plugin, gradient_border_plugin]}
    />
  </div>
);
Chart.register(...registerables);
export default MiniChart;
