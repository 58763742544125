import React, { useState } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import Header from '../../containers/Header/Header';
import styles from './layout.module.scss';
import { useAppSelector } from '../../hooks/hooks';

function Layout({ children }: any) {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const sessionToken = sessionStorage.getItem('token');
  const { token } = useAppSelector((state) => state.customer.token);
  const onShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const { pathname } = useLocation();
  const id = pathname.replace(/[^0-9]/g, '');

  return (
    <div>
      {((sessionToken || token) || pathname === `/strategies_rating/strategy/${id}`) && (
        <Header onShowMobileMenu={onShowMobileMenu} showMobileMenu={showMobileMenu} />
      )}

      <main className={cx(styles.main_layout)}>
        {children}
      </main>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus */}
      <div
        role="button"
        className={cx(
          styles.main_layout__block_shadow,
          { [styles.main_layout__block_shadow__open]: showMobileMenu },
        )}
        onClick={onShowMobileMenu}
      />
    </div>
  );
}

export default Layout;
