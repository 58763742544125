import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { store } from './app/model/store';
import Index from './app';
import i18n from './shared/lib/i18next/i18n';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Index />
      </I18nextProvider>
    </Provider>
  </BrowserRouter>,
);
