import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModal } from './models';

interface IModalState {
    info: IModal;
    master_account: number;
    isLoading?: boolean;
    error?: string;
}
const initialState: IModalState = {
  info: {
    id: 4,
  },
  master_account: 0,
  isLoading: false,
  error: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalContent(state, action: PayloadAction<number>) {
      state.info.id = action.payload;
    },
    setModalIsOpen(state, action: PayloadAction<number>) {
      state.master_account = action.payload;
    },
  },
});
export const {
  setModalContent,
  setModalIsOpen,
} = modalSlice.actions;
export default modalSlice.reducer;
