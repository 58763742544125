import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../Text/Text';
import { USD } from '../../shared/consts/consts';

interface FeeComponentProps {
    type_of_fee: number;
    percent_fee?: number;
    fixed_fee?: number;
    textType: 'button' | 'text'
}

export const FeeComponent: FC<FeeComponentProps> = (
  {
    type_of_fee,
    percent_fee,
    fixed_fee,
    textType,
  },
) => {
  const { t } = useTranslation();
  if (type_of_fee === 1 && percent_fee) {
    return (
      <Text type={textType}>
        {`${Math.round(percent_fee * 100)}% ${t('STRATEGIES_RATING.PROFITABLE_TRADE')}`}
      </Text>
    );
  }
  if (type_of_fee === 2 && fixed_fee) {
    return (
      <Text type={textType}>
        {`${fixed_fee} ${USD} ${t('STRATEGIES_RATING.PROFITABLE_TRADE')}`}
      </Text>
    );
  }
  if (type_of_fee === 3 && fixed_fee) {
    return (
      <Text type={textType}>
        {`${fixed_fee} ${USD} ${t('STRATEGIES_RATING.PERIOD_IN_DAYS')}`}
      </Text>
    );
  }
  if (type_of_fee === 4 && percent_fee) {
    return (
      <Text type={textType}>
        {`${Math.round(percent_fee * 100)}% ${t('STRATEGIES_RATING.ROLLOVER')}`}
      </Text>
    );
  }
  return null; // обработка других типов, если необходимо)
};
