/* eslint-disable react-hooks/exhaustive-deps,prefer-const,max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { Input, Spinner, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import styles from './StrategiesRatingDesktop.module.scss';
import Text from '../../../entities/Text/Text';
import magnifier from '../../../shared/assets/icons/magnifier.svg';
import { ReactModalSubscribe } from '../../../containers/ReactModalSubscribe';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  changeSortMethod,
  changeSortType,
  getStrategiesRequest,
  getStrategiesSuccess,
  setCurrentPage,
  setRatePageSize,
} from '../../../store/strategiesRating/reducer';
import {
  currSortMethod,
  currSortType,
  IDataStrategies,
  IStrategiesRatingItem,
} from '../../../store/strategiesRating/models';
import { ButtonShowMore, ButtonTableHeader } from '../../../features/Buttons';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import Pagination from '../../../entities/Pagination/Pagination';
import { pageSize } from '../../../shared/consts/consts';
import { searchValidationSchema } from '../../../shared/schemas/schemas';
import Feedback from '../../../entities/Feedback/Feedback';
import MiniChart from '../../../entities/MiniChart/MiniChart';
import { FeeComponent } from '../../../entities/FeeComponent/FeeComponent';

function StrategiesRatingDesktop() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { data, sort, isLoading } = useAppSelector(
    (state) => state.strategiesRating,
  );
  // ratePageSize - это множитель на который умножается pageSize при нажатии показать ещё
  const { currentPage, ratePageSize } = useAppSelector(
    (state) => state.strategiesRating.pagination,
  );
  const [isFocus, setIsFocus] = useState(false);
  const [field, setField] = useState('');
  const focusHandler = (newField: string) => {
    setIsFocus(true);
    setField(newField);
  };
  const blurHandler = () => {
    setIsFocus(false);
  };
  // глубокое копирование state
  const sortedData: IDataStrategies = JSON.parse(JSON.stringify(data));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
  } = sort;

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    if (curr_sort_type !== sortType) {
      dispatch(changeSortMethod('down'));
      dispatch(changeSortType(sortType));
      sortedData.list.sort((a, b) => {
        if (a[`${sortType}`] < b[`${sortType}`]) {
          return 1;
        }
        if (a[`${sortType}`] > b[`${sortType}`]) {
          return -1;
        }
        return 0;
      });
      dispatch(getStrategiesSuccess(sortedData));
    } else {
      switch (sortMethod) {
        case 'default':
          // dispatch(changeSortMethod('down'));
          dispatch(changeSortMethod('up'));
          // sortedData.list.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
          sortedData.list.sort((a, b) => {
            if (a[`${sortType}`] < b[`${sortType}`]) {
              return -1;
            }
            if (a[`${sortType}`] > b[`${sortType}`]) {
              return 1;
            }
            return 0;
          });
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case 'down':
          dispatch(changeSortMethod('up'));
          sortedData.list.sort((a, b) => {
            if (a[`${sortType}`] < b[`${sortType}`]) {
              return -1;
            }
            if (a[`${sortType}`] > b[`${sortType}`]) {
              return 1;
            }
            return 0;
          });
          dispatch(getStrategiesSuccess(sortedData));
          break;
        case 'up': // в колонке стратегия с up меняется сразу на down
          if (curr_sort_type === sort_types[0]) {
            dispatch(changeSortMethod('down'));
            sortedData.list.sort((a, b) => {
              if (a[`${sortType}`] < b[`${sortType}`]) {
                return 1;
              }
              if (a[`${sortType}`] > b[`${sortType}`]) {
                return -1;
              }
              return 0;
            });
            dispatch(getStrategiesSuccess(sortedData));
          } else {
            dispatch(changeSortMethod('default'));
            dispatch(changeSortType(sort_types[0]));
            // sortedData.list.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
            sortedData.list.sort((a, b) => b[`${sort_types[0]}`] - a[`${sort_types[0]}`]);
            dispatch(getStrategiesSuccess(sortedData));
          }
          break;
        default:
          break;
      }
    }
  }, [curr_sort_type, dispatch, sortedData]);

  interface IInitialValues {
    searchInput: string
  }

  const formik = useFormik<IInitialValues>({
    initialValues: { searchInput: '' },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {
    },
    validationSchema: searchValidationSchema,
  });
  useEffect(() => {
    if (
      !data.list.length
      || location.state === null
      // @ts-ignore
      || !location.state.includes('/strategies_rating/strategy/')
    ) {
      if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
      if (curr_sort_type !== 'profitability') dispatch(changeSortType('profitability'));
      dispatch(getStrategiesRequest());
      if (currentPage !== 1) dispatch(setCurrentPage(1));
      if (ratePageSize !== 1) dispatch(setRatePageSize(1));
    }
  }, [dispatch]);
  // useEffect для сбрасывание rate и currentPage при поиске
  useEffect(() => {
    if (formik.values.searchInput) {
      if (currentPage !== 1) dispatch(setCurrentPage(1));
      if (ratePageSize !== 1) dispatch(setRatePageSize(1));
    }
  }, [formik.values.searchInput]);
  // сбрасываем currentPage и setRatePage, если перешли в инфо стратегии и при этом был использован поиск
  const handleDefaultPageState = () => {
    if (formik.values.searchInput) {
      if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
      if (curr_sort_type !== 'profitability') dispatch(changeSortType('profitability'));
      if (currentPage !== 1) dispatch(setCurrentPage(1));
      if (ratePageSize !== 1) dispatch(setRatePageSize(1));
    }
  };
  // определение индексов элементов массива для метода slice
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - ratePageSize) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize * ratePageSize;
    return (
      !formik.values.searchInput
        ? data.list.slice(firstPageIndex, lastPageIndex)
        : data.list
          .filter(({ name }) => name.toLowerCase().includes(formik.values.searchInput.toLowerCase()))
          .slice(firstPageIndex, lastPageIndex)
    );
  }, [currentPage, ratePageSize, data, formik.values.searchInput]);
  const handleShowMoreClick = () => {
    dispatch(setCurrentPage(currentPage + 1));
    dispatch(setRatePageSize(ratePageSize + 1));
  };
  return (
    <div>
      <div
        className={styles.head_container}
      >
        <div
          className={cx(
            styles.head_container__left,
          )}
        >
          <Text type="h1" className={styles.title}>
            {t('STRATEGIES_RATING.PAGE_TITLE')}
          </Text>
          {t('STRATEGIES_RATING.INFO_CONTENT') !== ''
            && t('STRATEGIES_RATING.INFO_CONTENT') !== 'STRATEGIES_RATING.INFO_CONTENT'
            && (
              <ReactModalInfo
                title={t('OTHER.INFO_TITLE')}
                content={t('STRATEGIES_RATING.INFO_CONTENT')}
              />
            )}

        </div>
        <div
          className={cx(
            styles.head_container__right,
          )}
        >
          <Input
            className={cx({ [styles.active]: formik.values.searchInput })}
            maxLength={20}
            autoComplete="off"
            {...formik.getFieldProps('searchInput')}
            onFocus={() => focusHandler('searchInput')}
            onBlur={(e) => {
              blurHandler();
              formik.handleBlur(e);
            }}
            placeholder={t('OTHER.SEARCH_PLACEHOLDER')}
            type="text"
          />
          <div
            className={styles.magnifier}
          >
            <img src={magnifier} alt="" />
          </div>
          <Feedback
            name="searchInput"
            isFocus={isFocus}
            formik={formik}
            field={field}
            className={styles.search_feedback}
          />
        </div>
      </div>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && data.list.length > 0 && (
        <>
          <Table
            className={styles.table}
          >
            <thead>
              <tr>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[5], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[5]}
                  >
                    <Text type="button">{t('STRATEGIES_RATING.STRATEGY')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[5]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[0], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[0]}
                  >
                    <Text type="button">{t('STRATEGIES_RATING.PROFITABILITY')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[0]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader disabled>
                    <Text type="button">{t('STRATEGIES_RATING.CHART')}</Text>
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[1], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[1]}
                  >
                    <Text type="button">{t('STRATEGIES_RATING.MAXIMUM_DRAWDOWN')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[1]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[2], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[2]}
                  >
                    <Text type="button">{t('STRATEGIES_RATING.MAXIMUM_LEVERAGE')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[2]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[3], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[3]}
                  >
                    <Text type="button">{t('STRATEGIES_RATING.AGE')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[3]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader
                    onClick={() => handleSortClick(sort_types[4], curr_sort_method)}
                    isActive={curr_sort_type === sort_types[4]}
                  >
                    <Text type="button">{t('MY_STRATEGIES.SUBSCRIBERS')}</Text>
                    <ArrowSwitcher
                      currSort={curr_sort_type}
                      sortType={sort_types[4]}
                      sortMethod={curr_sort_method}
                    />
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader disabled>
                    <Text type="button">{t('STRATEGIES_RATING.SUBSCRIPTION_TERMS')}</Text>
                  </ButtonTableHeader>
                </th>
                <th>
                  <ButtonTableHeader disabled>
                    <Text type="button">{t('STRATEGIES_RATING.MANAGEMENT')}</Text>
                  </ButtonTableHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((strategy: IStrategiesRatingItem) => (
                <tr className={styles.table_row} key={uuidv4()}>
                  <td>
                    <Link
                      className="nav-link"
                      ria-current="page"
                      to={`/strategies_rating/strategy/${strategy.id}`}
                      state={{ from: location.pathname }}
                      onClick={handleDefaultPageState}
                    >
                      <Text type="button">{strategy.name}</Text>
                    </Link>

                  </td>
                  <td>
                    <Text type="text">
                      {strategy.profitability}
                      {' '}
                      %
                    </Text>
                  </td>
                  <td>
                    {/* eslint-disable-next-line react/no-danger */}
                    {/* <div dangerouslySetInnerHTML={{ __html: strategy.chart_mini }} /> */}
                    <MiniChart
                      data={strategy.chart_small}
                    />
                  </td>
                  <td>
                    <Text type="text">
                      {strategy.max_drop}
                      {' '}
                      %
                    </Text>
                  </td>
                  <td>
                    <Text type="text">{strategy.max_leverage}</Text>
                  </td>
                  <td>
                    <Text type="text">
                      {strategy.age}
                      {' '}
                      d
                    </Text>
                  </td>
                  <td>
                    <Text type="text">{strategy.total_followers}</Text>
                  </td>
                  <td>
                    <FeeComponent
                      type_of_fee={strategy.fee.type_of_fee}
                      percent_fee={strategy.fee.percent_fee}
                      fixed_fee={strategy.fee.fixed_fee}
                      textType="text"
                    />
                  </td>
                  <td>
                    <ReactModalSubscribe
                      button="MODAL.SUBSCRIBE"
                      title="MODAL.SUBSCRIBE_TITLE"
                      args={strategy}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            className={styles.footer}
          >
            {!formik.values.searchInput
              ? currentPage < Math.ceil(data.list.length / pageSize)
              && (
                <ButtonShowMore
                  onClick={handleShowMoreClick}
                >
                  <Text type="mobile-menu">
                    {t('OTHER.LOAD_MORE')}
                  </Text>
                </ButtonShowMore>
              )
              : currentPage < Math.ceil(data.list
                .filter(({ name }) => name
                  .toLowerCase()
                  .includes(formik.values.searchInput.toLowerCase())).length / pageSize)
              && (
                <ButtonShowMore
                  onClick={handleShowMoreClick}
                >
                  <Text type="mobile-menu">
                    {t('OTHER.LOAD_MORE')}
                  </Text>
                </ButtonShowMore>
              )}
            <Pagination
              className={styles.pagination_bar}
              currentPage={currentPage}
              totalCount={
                !formik.values.searchInput
                  ? data.list.length
                  : data.list
                    .filter(({ name }) => name.toLowerCase().includes(formik.values.searchInput.toLowerCase())).length
              }
              pageSize={pageSize}
              onPageChange={(page) => {
                dispatch(setCurrentPage(page));
                dispatch(setRatePageSize(1));
              }}
              siblingCount={2}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(StrategiesRatingDesktop);
