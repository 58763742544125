/* eslint-disable react-hooks/exhaustive-deps */
import cx from 'classnames';
import { Spinner, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './MySubscriptionsDesktop.module.scss';
import openAccountIcon from '../../../shared/assets/icons/open_account_icon.svg';
import Text from '../../../entities/Text/Text';
import { ButtonToggle } from '../../../features/ButtonToggle';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  changeSortMethod,
  changeSortType,
  getActiveSubscriptionsRequest,
  getActiveSubscriptionsSuccess,
  getClosedSubscriptionsRequest,
  setSubscriptionContent,
} from '../../../store/mySubscription/reducer';
import depositIcon from '../../../shared/assets/icons/deposit-icon.svg';
import withdrawIcon from '../../../shared/assets/icons/withdraw-icon.svg';
import { ButtonManage, ButtonTableHeader } from '../../../features/Buttons';
import deleteIcon from '../../../shared/assets/icons/delete-icon.svg';
import launchIcon from '../../../shared/assets/icons/launch-icon.svg';
import pauseIcon from '../../../shared/assets/icons/pause-icon.svg';
import {
  onChangeSubscriptionStatusHandler,
  onDeleteSubscriptionHandler,
  onRedirectHandler,
} from '../../../handlers/handlers';
import { currSortMethod, currSortType, ISubscriptionState } from '../../../store/mySubscription/models';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import { USD } from '../../../shared/consts/consts';
import { ClosedTHeaderSubs } from '../../../widgets/subscriptionsTHeader/closed/ui/ClosedTHeader';

function MySubscriptionsDesktop() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showBalanceMenu, setShowBalanceMenu] = useState(0);
  const location = useLocation();
  // вынести получение ссылок в отельный файл с общими функциями!
  const {
    deposit_funds_url,
    withdraw_funds_url,
    create_account_url,
  } = useAppSelector((state) => state.customer.redirectUrls);
  // переключение типа контента активные/закрытые
  const onToggleContent = (e: boolean) => {
    dispatch(setSubscriptionContent(e));
  };
  const { mySubscriptions } = useAppSelector((state) => state);
  const {
    active_subscriptions,
    closed_subscriptions,
    isActiveSubscriptionsLoading,
    isClosedSubscriptionsLoading,
    sort,
    info,
  } = mySubscriptions;
  const { isActive } = info;
  const sortedData: ISubscriptionState = JSON.parse(JSON.stringify(mySubscriptions));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
  } = sort;
  useEffect(() => {
    if (
      !active_subscriptions.length
        || !closed_subscriptions.length
        || location.state === null
      // @ts-ignore
        || !location.state.includes('/strategies_rating/strategy/')
    ) {
      if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
      if (curr_sort_type !== 'account') dispatch(changeSortType('account'));
      dispatch(getActiveSubscriptionsRequest());
      dispatch(getClosedSubscriptionsRequest());
    }
  }, [dispatch]);

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    if (curr_sort_type !== sortType) {
      dispatch(changeSortType(sortType));
      dispatch(changeSortMethod('down'));
      sortedData.active_subscriptions.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
      dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
    } else {
      switch (sortMethod) {
        case 'default':
          dispatch(changeSortMethod('down'));
          sortedData.active_subscriptions.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        case 'down':
          dispatch(changeSortMethod('up'));
          sortedData.active_subscriptions.sort((a, b) => a[`${sortType}`] - b[`${sortType}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        case 'up':
          if (curr_sort_type === sort_types[3]) {
            dispatch(changeSortMethod('down'));
            sortedData.active_subscriptions.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
            dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          } else {
            dispatch(changeSortMethod('default'));
            dispatch(changeSortType(sort_types[3]));
            sortedData.active_subscriptions.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
            dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          }
          break;
        default:
          break;
      }
    }
  }, [curr_sort_type, dispatch, sortedData.active_subscriptions]);
  return (
    <div>
      <div
        className={styles.head_container}
      >
        <div
          className={cx(
            styles.head_container,
            styles.head_container__left,
          )}
        >
          <Text type="h1" className={styles.title}>
            {t('MY_SUBSCRIPTIONS.PAGE_TITLE')}
          </Text>
          <ButtonToggle
            onClickContent={onToggleContent}
            isSubscriptions
          />
          { isActive
            && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== ''
            && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== 'MY_SUBSCRIPTIONS.INFO_CONTENT'
            && (
            <ReactModalInfo
              title={t('OTHER.INFO_TITLE')}
              content={t('MY_SUBSCRIPTIONS.INFO_CONTENT')}
            />
            )}
          { !isActive
            && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== ''
            && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== 'MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED'
            && (
            <ReactModalInfo
              title={t('OTHER.INFO_TITLE')}
              content={t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED')}
            />
            )}
        </div>
        <div
          className={styles.head_container__right}
        >
          <div>
            <ButtonManage
              onClick={() => onRedirectHandler(create_account_url)}
              icon={openAccountIcon}
              handleReset={() => false}
            >
              <Text type="button">{t('MY_SUBSCRIPTIONS.CREATE_ACCOUNT_BTN')}</Text>
            </ButtonManage>
          </div>
          <div>
            <ButtonManage
              onClick={() => onRedirectHandler(deposit_funds_url)}
              icon={depositIcon}
              handleReset={() => false}
            >
              <Text type="button">{t('MODAL.DEPOSIT')}</Text>
            </ButtonManage>
          </div>
          <div>
            <ButtonManage
              onClick={() => onRedirectHandler(withdraw_funds_url)}
              icon={withdrawIcon}
              handleReset={() => false}
            >
              <Text type="button">{t('MODAL.WITHDRAW')}</Text>
            </ButtonManage>
          </div>
        </div>
      </div>
      {
        isActiveSubscriptionsLoading
          && isClosedSubscriptionsLoading
          && <Spinner className={styles.spinner} />
      }
      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && isActive
      && active_subscriptions.length === 0
      && closed_subscriptions.length === 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_1')}
          </Text>
          <Text type="page-notification">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_2')}
          </Text>
        </div>
      )}
      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && isActive
      && active_subscriptions.length === 0
      && closed_subscriptions.length > 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_3')}
          </Text>
          <Text type="page-notification">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_2')}
          </Text>
        </div>
      )}
      { !isActiveSubscriptionsLoading && isActive && active_subscriptions.length > 0 && (
      <Table
        className={styles.table}
      >
        <thead>
          <tr>
            <th>
              <ButtonTableHeader
                onClick={() => handleSortClick(sort_types[3], curr_sort_method)}
                isActive={curr_sort_type === sort_types[3]}
              >
                <Text type="button">{t('MY_SUBSCRIPTIONS.ACCOUNT_NUMBER')}</Text>
                <ArrowSwitcher
                  currSort={curr_sort_type}
                  sortType={sort_types[3]}
                  sortMethod={curr_sort_method}
                />
              </ButtonTableHeader>
            </th>
            <th>
              <ButtonTableHeader
                disabled
              >
                <Text type="button">{t('MY_SUBSCRIPTIONS.SUBSCRIPTION_MANAGEMENT')}</Text>
              </ButtonTableHeader>
            </th>
            <th>
              <ButtonTableHeader
                onClick={() => handleSortClick(sort_types[0], curr_sort_method)}
                isActive={curr_sort_type === sort_types[0]}
              >
                <Text type="button">{t('MY_STRATEGIES.PROFIT_LOSS')}</Text>
                <ArrowSwitcher
                  currSort={curr_sort_type}
                  sortType={sort_types[0]}
                  sortMethod={curr_sort_method}
                />
              </ButtonTableHeader>
            </th>
            <th>
              <ButtonTableHeader
                onClick={() => handleSortClick(sort_types[1], curr_sort_method)}
                isActive={curr_sort_type === sort_types[1]}
              >
                <Text type="button">{t('MY_STRATEGIES.BALANCE')}</Text>
                <ArrowSwitcher
                  currSort={curr_sort_type}
                  sortType={sort_types[1]}
                  sortMethod={curr_sort_method}
                />
              </ButtonTableHeader>
            </th>
            <th>
              <ButtonTableHeader
                onClick={() => handleSortClick(sort_types[2], curr_sort_method)}
                isActive={curr_sort_type === sort_types[2]}
              >
                <Text type="button">{t('MY_STRATEGIES.FUNDS')}</Text>
                <ArrowSwitcher
                  currSort={curr_sort_type}
                  sortType={sort_types[2]}
                  sortMethod={curr_sort_method}
                />
              </ButtonTableHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          {active_subscriptions.map((subscription) => (
            <tr
              key={uuidv4()}
              className={styles.table_row}
            >
              <td>
                <Text type="text">{subscription.account}</Text>
              </td>
              <td className={cx(styles.subscriptions_td)}>
                {
                  subscription.subscription_data.map((item) => (
                    <div
                      className={styles.strategy_name_wrapper}
                      key={uuidv4()}
                    >
                      <Link
                        className="nav-link"
                        ria-current="page"
                        to={`/strategies_rating/strategy/${item.strategy_id}`}
                        state={{ from: location.pathname }}
                      >
                        <Text type="text">{item.strategy_name}</Text>
                      </Link>
                      <div className={cx(styles.manage_icons_wrapper)}>
                        <div
                          onClick={
                            () => onChangeSubscriptionStatusHandler(dispatch, item.subscription_id, item.status)
                          }
                          className={cx(styles.icon_wrapper)}
                        >
                          <img src={item.status === 'ACTIVE' ? pauseIcon : launchIcon} alt="" />
                        </div>
                        {/* <div */}
                        {/*  onClick={ */}
                        {/*    () => onEditSubscriptionHandler(dispatch, item.subscription_id) */}
                        {/*  } */}
                        {/*  className={cx(styles.icon_wrapper)} */}
                        {/* > */}
                        {/*  <img src={editIcon} alt="" /> */}
                        {/* </div> */}
                        <div
                          onClick={
                            () => onDeleteSubscriptionHandler(dispatch, item.subscription_id)
                          }
                          className={cx(styles.icon_wrapper)}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  ))
                  }

              </td>
              <td>
                <Text
                  type="text"
                  className={cx(
                    {
                      [styles.green_font]: subscription.total_pnl > 0,
                      [styles.red_font]: subscription.total_pnl < 0,
                      [styles.black_font]: subscription.total_pnl === 0,
                    },
                  )}
                >
                  {
                      (subscription.total_pnl > 0 && `+${+subscription.total_pnl.toFixed(2)} ${USD}`)
                      || (subscription.total_pnl === 0 && `0 ${USD}`)
                      || (subscription.total_pnl < 0 && `${+subscription.total_pnl.toFixed(2)} ${USD}`)
                    }
                </Text>
              </td>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <td
                onClick={showBalanceMenu
                  ? () => setShowBalanceMenu(0)
                  : () => setShowBalanceMenu(subscription.account)}
                className={styles.table__balance}
              >
                <Text type="text">
                  {`${subscription.balance} ${USD}`}
                </Text>
                {showBalanceMenu === subscription.account
                  && (
                  <div
                    className={styles.table__balance__menu}
                  >
                    <div
                      onClick={() => onRedirectHandler(`${deposit_funds_url}?account=${subscription.account}`)}
                    >
                      <Text type="text">{t('MODAL.DEPOSIT')}</Text>
                    </div>
                    <div
                      onClick={() => onRedirectHandler(`${withdraw_funds_url}?account=${subscription.account}`)}
                    >
                      <Text type="text">{t('MODAL.WITHDRAW')}</Text>
                    </div>
                  </div>
                  )}
              </td>
              <td>
                <Text type="text">{`${subscription.equity}$`}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      )}
      {/* --------------- */}
      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && !isActive
      && closed_subscriptions.length === 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification">
            {t('MY_SUBSCRIPTIONS.NO_CLOSED_SUBSCRIPTIONS_DESC_1')}
          </Text>
        </div>
      )}
      { !isClosedSubscriptionsLoading
        && !isActive
        && closed_subscriptions.length > 0
        && (
        <Table
          className={styles.table}
          hover
        >
          <ClosedTHeaderSubs />
          <tbody>
            {closed_subscriptions.map((subscription) => (
              <tr
                className={styles.table_row}
                key={uuidv4()}
              >
                <td>
                  <Text type="text">{subscription.account}</Text>
                </td>
                <td>
                  <Text type="text">{subscription.strategy_name}</Text>
                </td>
                <td>
                  <Text
                    type="text"
                    className={cx(
                      {
                        [styles.green_font]: subscription.total_pnl > 0,
                        [styles.red_font]: subscription.total_pnl < 0,
                        [styles.black_font]: subscription.total_pnl === 0,
                      },
                    )}
                  >
                    {
                    (subscription.total_pnl > 0 && `+${+subscription.total_pnl.toFixed(2)} ${USD}`)
                    || (subscription.total_pnl === 0 && `0 ${USD}`)
                    || (subscription.total_pnl < 0 && `${+subscription.total_pnl.toFixed(2)} ${USD}`)
                  }
                  </Text>
                </td>
                <td>
                  <Text type="text">{`${subscription.balance} ${USD}`}</Text>
                </td>
                <td>
                  <Text type="text">{subscription.created_at}</Text>
                </td>
                <td>
                  <Text type="text">{subscription.closed_at}</Text>
                </td>
                <td>
                  <Text type="text">{`${subscription.equity} ${USD}`}</Text>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        )}
    </div>
  );
}

export default MySubscriptionsDesktop;
