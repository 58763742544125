import {
  put, takeLeading, call,
} from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createStrategyFail,
  createStrategySuccess,
  getRewardFail,
  getRewardSuccess,
  getActiveStrategiesFail,
  getActiveStrategiesSuccess,
  deleteStrategySuccess,
  deleteStrategyFail,
  getClosedStrategiesSuccess,
  getClosedStrategiesFail,
  getActiveStrategiesRequest,
} from './reducer';
import { notificationModalOpen } from '../notification/reducer';
import {
  IActiveStrategies,
  IClosedStrategies,
  IPropsStrategy,
  IResponseCreateStrategy,
  IResponseDeleteStrategy,
  IRewardTypes,
} from './models';
import { baseUrl } from '../../shared/consts/baseUrl';

function* getActiveStrategiesSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      data: IActiveStrategies
    } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'strategy/active',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    resp.data.active_strategies.sort((a, b) => a.account - b.account);
    yield put(getActiveStrategiesSuccess(resp.data.active_strategies));
  } catch (e: any) {
    yield put(getActiveStrategiesFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_ACTIVE_STRATEGY.TITLE',
      message: `NOTIFICATION.GET_ACTIVE_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* getClosedStrategiesSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      data: IClosedStrategies
    } = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'strategy/closed',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    resp.data.closed_strategies.sort((a, b) => a.account - b.account);
    yield put(getClosedStrategiesSuccess(resp.data.closed_strategies));
  } catch (e: any) {
    yield put(getClosedStrategiesFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_CLOSED_STRATEGY.TITLE',
      message: `NOTIFICATION.GET_CLOSED_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* createStrategySaga({ payload }: PayloadAction<IPropsStrategy>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: IResponseCreateStrategy
    } = yield call(() => axios({
      method: 'post',
      baseURL: baseUrl,
      url: 'strategy',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: payload,
    }));
    yield put(createStrategySuccess());
    yield put(getActiveStrategiesRequest());
    if (resp.status >= 200 && resp.status <= 299) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.CREATING_STRATEGY.TITLE',
        message: 'NOTIFICATION.CREATING_STRATEGY.SUCCESS',
        actionButton: 'MODAL.OK_BTN',
        type: 'success',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    yield put(createStrategyFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.CREATING_STRATEGY.TITLE',
      message: `NOTIFICATION.CREATING_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* deleteStrategySaga({ payload }: PayloadAction<number>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {
      status: number,
      data: IResponseDeleteStrategy
    } = yield call(() => axios({
      method: 'delete',
      baseURL: baseUrl,
      url: `strategy/${payload}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    yield put(deleteStrategySuccess());
    if (resp.status >= 200 && resp.status <= 299) {
      yield put(notificationModalOpen({
        label: 'NOTIFICATION.DELETING_STRATEGY.TITLE',
        message: 'NOTIFICATION.DELETING_STRATEGY.SUCCESS',
        actionButton: 'MODAL.OK_BTN',
        props: resp.data.name,
        type: 'success',
        isOpen: true,
      }));
    }
  } catch (e: any) {
    yield put(deleteStrategyFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.DELETING_STRATEGY.TITLE',
      message: `NOTIFICATION.DELETING_STRATEGY.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}
function* getRewardSaga() {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {data: IRewardTypes[]} = yield call(() => axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'strategy_types',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    }));
    yield put(getRewardSuccess(resp.data));
  } catch (e) {
    yield put(getRewardFail((e as Error).message));
  }
}
export function* myStrategiesWatcher() {
  yield takeLeading('myStrategies/createStrategyRequest', createStrategySaga);
  yield takeLeading('myStrategies/deleteStrategyRequest', deleteStrategySaga);
  yield takeLeading('myStrategies/getRewardRequest', getRewardSaga);
  yield takeLeading('myStrategies/getActiveStrategiesRequest', getActiveStrategiesSaga);
  yield takeLeading('myStrategies/getClosedStrategiesRequest', getClosedStrategiesSaga);
}
