import React from 'react';
import { useMedia } from 'react-use';
import arrowDown from '../../shared/assets/icons/arrowDown.svg';
import arrowUp from '../../shared/assets/icons/arrowUp.svg';
import mobileArrowDown from '../../shared/assets/icons/mobileArrowDown.svg';
import mobileArrowUp from '../../shared/assets/icons/mobileArrowUp.svg';
import doubleArrow from '../../shared/assets/icons/doubleArrow.svg';
import styles from './ArrowSwitcher.module.scss';

interface IArrowProps {
  currSort: string
  sortType?: string
  sortMethod: string
  onClick?: () => void
}
const ArrowSwitcher = ({
  currSort, sortType, sortMethod, onClick,
}: IArrowProps) => {
  const isMobile = useMedia('(max-width: 545px)');
  return (
    isMobile
      ? (
        <div
          onClick={onClick}
          className={styles.wrapper}
        >
          {sortMethod === 'down'
                    && (
                    <img src={mobileArrowDown} alt="" />
                    )}
          {sortMethod === 'up'
                    && (
                    <img src={mobileArrowUp} alt="" />
                    )}
          {sortMethod === 'default'
                    && (
                    // <img src={mobileArrowUp} alt="" />
                    <img src={mobileArrowDown} alt="" />
                    )}
        </div>
      )
      : (
        <div
          className={styles.wrapper}
        >
          {/* {currSort === sortType && sortMethod === 'down' */}
          {currSort === sortType && (sortMethod === 'down' || sortMethod === 'default')
          && (
            <img src={arrowDown} alt="" />
          )}
          {/* {currSort === sortType && (sortMethod === 'up' || sortMethod === 'default') */}
          {currSort === sortType && sortMethod === 'up'
          && (
            <img src={arrowUp} alt="" />
          )}
          {currSort !== sortType && <img src={doubleArrow} alt="" />}
        </div>
      )
  );
};

export default ArrowSwitcher;
