import { Dispatch } from 'react';
import { notificationModalOpen } from '../store/notification/reducer';
import { editSubscriptionGetDataRequest } from '../store/mySubscription/reducer';

export const onDeleteStrategyHandler = (dispatch: Dispatch<any>, strategyId: number) => {
  dispatch(notificationModalOpen({
    label: 'NOTIFICATION.DELETING_STRATEGY.TITLE',
    message: 'NOTIFICATION.DELETING_STRATEGY.ATTENTION',
    actionButton: 'NOTIFICATION.DELETE_STRATEGY',
    cancelButton: 'MODAL.CANCEL_BTN',
    props: strategyId,
    type: 'attention',
    isOpen: true,
  }));
};
export const onChangeSubscriptionStatusHandler = (dispatch: Dispatch<any>, subscriptionId: number, status: string) => {
  switch (status) {
    case 'ACTIVE':
      dispatch(notificationModalOpen({
        label: 'NOTIFICATION.PAUSE_SUBSCRIPTION.TITLE',
        message: 'NOTIFICATION.PAUSE_SUBSCRIPTION.ATTENTION',
        actionButton: 'NOTIFICATION.PAUSE_BTN',
        cancelButton: 'MODAL.CANCEL_BTN',
        props: subscriptionId,
        type: 'attention',
        isOpen: true,
      }));
      break;
    case 'PAUSED':
      dispatch(notificationModalOpen({
        label: 'NOTIFICATION.RESUME_SUBSCRIPTION.TITLE',
        message: 'NOTIFICATION.RESUME_SUBSCRIPTION.ATTENTION',
        actionButton: 'NOTIFICATION.RESUME_BTN',
        cancelButton: 'MODAL.CANCEL_BTN',
        props: subscriptionId,
        type: 'attention',
        isOpen: true,
      }));
      break;
    default:
      break;
  }
};
export const onDeleteSubscriptionHandler = async (dispatch: Dispatch<any>, subscriptionId: number) => {
  dispatch(notificationModalOpen({
    label: 'NOTIFICATION.DELETING_SUBSCRIPTION.TITLE',
    message: 'NOTIFICATION.DELETING_SUBSCRIPTION.ATTENTION',
    actionButton: 'NOTIFICATION.DELETE_SUBSCRIPTION',
    cancelButton: 'MODAL.CANCEL_BTN',
    props: subscriptionId,
    type: 'attention',
    isOpen: true,
  }));
};
export const onEditSubscriptionHandler = async (dispatch: Dispatch<any>, subscriptionId: number) => {
  dispatch(notificationModalOpen({
    label: 'NOTIFICATION.EDIT_SUBSCRIPTION.TITLE',
    message: 'NOTIFICATION.EDIT_SUBSCRIPTION.ATTENTION',
    actionButton: 'NOTIFICATION.EDIT_BTN',
    cancelButton: 'MODAL.CANCEL_BTN',
    // props: subscriptionId,
    type: 'attention',
    isOpen: true,
  }));
  dispatch(editSubscriptionGetDataRequest(Number(subscriptionId)));
};

export const onRedirectHandler = (url: string) => window.location.assign(url);
