import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import route from '../../app/routes/routes';
import styles from './Header.module.scss';
import LanguagePanel from '../LanguagePanel/LanguagePanel';
import Text from '../../entities/Text/Text';
import logo from '../../shared/assets/icons/logo.svg';
import hamburgerMenu from '../../shared/assets/icons/hamburger-menu.svg';
import SupportLink from '../../entities/SupportLink/SupportLink';
import LogoutButton from '../../entities/LogoutButton/LogoutButton';
import MobileMenu from '../MobileMenu/MobileMenu';

function Header({ onShowMobileMenu, showMobileMenu }: {onShowMobileMenu: () => void, showMobileMenu: boolean}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div>

      <MobileMenu onShowMobileMenu={onShowMobileMenu} showMobileMenu={showMobileMenu} />

      <header>
        <nav className={cx(
          'navbar navbar-expand-lg navbar-light bg-light',
          styles.main_nav,
        )}
        >
          <div className={cx(
            'container-fluid',
            styles.container_fluid,
          )}
          >
            <div className={cx(styles.logo_nav_wrapper)}>
              <div className={cx(
                styles.logo_wrapper,
              )}
              >
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  src={hamburgerMenu}
                  alt="menu"
                  className={cx(styles.burger_menu)}
                  onClick={onShowMobileMenu}
                />

                <NavLink
                  to={route.root}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className={cx(styles.logo)}
                  />
                </NavLink>
              </div>
              <div
                className={cx(
                  styles.nav_bar,
                )}
                id="navbarNav"
              >
                <ul className={cx(
                  'navbar-nav',
                  styles.ul,
                )}
                >
                  <li className="nav-item">
                    <NavLink
                      className={cx(
                        'nav-link',
                        styles.nav_link_props,
                        {
                          [styles.active]: pathname === route.root
                          || pathname.includes('/strategies_rating/strategy/'),
                          // найти решение лучше
                        },
                      )}
                      aria-current="page"
                      to={route.root}
                    >
                      <Text type="button">{t('STRATEGIES_RATING.PAGE_TITLE')}</Text>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={cx(
                        'nav-link',
                        styles.nav_link_props,
                        {
                          [styles.active]: pathname === route.my_subscriptions.catalog,
                        },
                      )}
                      ria-current="page"
                      to={route.my_subscriptions.catalog}
                    >
                      <Text type="button">{t('MY_SUBSCRIBES.PAGE_TITLE')}</Text>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className={cx(
                        'nav-link',
                        styles.nav_link_props,
                        {
                          [styles.active]: pathname === route.my_strategies.catalog
                          || pathname.includes('/my_strategy/strategy/'),
                        },
                      )}
                      aria-current="page"
                      to={route.my_strategies.catalog}
                    >
                      <Text type="button">{t('MY_STRATEGIES.PAGE_TITLE')}</Text>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={cx(styles.control_wrapper)}
            >
              <LanguagePanel />
              <SupportLink />
              <LogoutButton />
            </div>
          </div>
        </nav>
      </header>

    </div>
  );
}

export default Header;
