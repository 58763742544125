import React from 'react';
import cx from 'classnames';
import styles from './LogoutButton.module.scss';
import logoutIcon from '../../shared/assets/icons/logout_icon.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { deleteToken } from '../../store/customer/reducer';

const LogoutButton = () => {
  const dispatch = useAppDispatch();
  const {
    return_to_external_url,
  } = useAppSelector((state) => state.customer.redirectUrls);

  const logoutHandler = () => {
    dispatch(deleteToken({ token: '' }));
    sessionStorage.removeItem('token');
    window.location.assign(return_to_external_url);
  };
  return (
    <div
      className={cx(styles.logout_wrapper)}
      onClick={() => logoutHandler()}
    >
      <img src={logoutIcon} alt="" />
    </div>
  );
};

export default LogoutButton;
