import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonTableHeader } from '../../../../features/Buttons';
import Text from '../../../../entities/Text/Text';

export const ClosedTHeaderStr: FC = () => {
  const { t } = useTranslation();
  const tHeaderValue = [
    t('MY_STRATEGIES.ACCOUNT_NUMBER'),
    t('MY_STRATEGIES.STRATEGY_NAME'),
    t('MY_STRATEGIES.PROFIT_LOSS'),
    t('MY_STRATEGIES.SUBSCRIBERS'),
    t('MY_STRATEGIES.CREATION_DATE'),
    t('MY_STRATEGIES.CLOSING_DATE'),
    t('MY_STRATEGIES.BALANCE'),
    t('MY_STRATEGIES.FUNDS'),
    t('MY_STRATEGIES.REWARD_TYPE'),
  ];
  return (
    <thead>
      <tr>
        {
          tHeaderValue.map((value) => (
            <th>
              <ButtonTableHeader
                disabled
              >
                <Text type="button">{value}</Text>
              </ButtonTableHeader>
            </th>
          ))
        }
      </tr>
    </thead>
  );
};
