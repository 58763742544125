import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import styles from './ModalButtonGroup.module.scss';
import Text from '../Text/Text';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import ModalSelector from '../../containers/ModalSelectors/ModalSelector';
import { ISubscriptionsMobileSelector } from '../../store/myStrategies/models';
import { setModalContent } from '../../store/modal/reducer';

interface ISubscriptionTypes {
  label: string,
  id: number
}
interface IProps {
  onResetForm: () => void
}
const ModalButtonGroup: React.FC<IProps> = ({ onResetForm }) => {
  const { id } = useAppSelector((state) => state.modal.info);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // получаем номер кнопки в модалке на странице рейтинга, для рендера контента модалки
  const onClickContent = useCallback((value: ISubscriptionTypes) => {
    dispatch(setModalContent(value.id));
  }, [dispatch]);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0);
  useEffect(() => {
    setWindowInnerWidth(window.innerWidth);
  }, []);
  const subscriptionTypes: ISubscriptionTypes[] = [
    { label: 'MODAL.HEADER_BTN_AUTO', id: 4 },
    { label: 'MODAL.HEADER_BTN_FIXED', id: 2 },
    { label: 'MODAL.HEADER_BTN_COEFFICIENT', id: 3 },
    { label: 'MODAL.HEADER_BTN_MASTER', id: 1 },
  ];
  const initialValues = {
    label: '',
    id: 0,
  };
  const formik = useFormik<ISubscriptionsMobileSelector>({
    initialValues,
    onSubmit: (value) => {
      dispatch(setModalContent(value.id));
    },
  });
  return (
    <div>
      {
        // --- desktop ---
        windowInnerWidth > 545
          ? (
            <ButtonGroup
              cssModule={{ 'btn-group': styles.btn_group }}
            >
              {
            subscriptionTypes.map((value) => (
              <Button
                key={uuidv4()}
                onClick={() => {
                  onResetForm();
                  // переключение кнопок
                  onClickContent(value);
                }}
                outline={false}
                className={cx(
                  styles.btn,
                  {
                    [styles.active]: id === value.id,
                  },
                )}
              >
                <Text type="button">{t(value.label)}</Text>
              </Button>
            ))
              }
            </ButtonGroup>
          )
        // --- mobile ---
          : (
            <ModalSelector
              formik={formik}
              values={subscriptionTypes}
              name="label"
            />
          )
        }
    </div>
  );
};

export default ModalButtonGroup;
