/* eslint-disable react-hooks/exhaustive-deps */
import cx from 'classnames';
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import styles from './MySubscriptionsMobile.module.scss';
import Text from '../../../entities/Text/Text';
import { ButtonToggle } from '../../../features/ButtonToggle';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import depositIcon from '../../../shared/assets/icons/deposit-icon.svg';
import withdrawIcon from '../../../shared/assets/icons/withdraw-icon.svg';
import showIcon from '../../../shared/assets/icons/show-icon.svg';
import hideIcon from '../../../shared/assets/icons/hide-icon.svg';
import controlIcon from '../../../shared/assets/icons/control-icon.svg';
import activeControlIcon from '../../../shared/assets/icons/active-control-icon.svg';
import deleteIcon from '../../../shared/assets/icons/delete-icon.svg';
import { ButtonManage } from '../../../features/Buttons';
import ModalSelector from '../../../containers/ModalSelectors/ModalSelector';
import {
  changeSortMethod, changeSortType,
  getActiveSubscriptionsRequest,
  getActiveSubscriptionsSuccess,
  getClosedSubscriptionsRequest,
  setCurrentSubsSortLabel,
  setShowActive,
  setSubscriptionContent,
} from '../../../store/mySubscription/reducer';
import openAccountIcon from '../../../shared/assets/icons/open_account_icon.svg';
import {
  onChangeSubscriptionStatusHandler,
  onDeleteSubscriptionHandler,
  // onEditSubscriptionHandler,
  onRedirectHandler,
} from '../../../handlers/handlers';
import pauseIcon from '../../../shared/assets/icons/pause-icon.svg';
import launchIcon from '../../../shared/assets/icons/launch-icon.svg';
import { currSortMethod, currSortType, ISubscriptionState } from '../../../store/mySubscription/models';
import ArrowSwitcher from '../../../entities/ArrowSwitcher/ArrowSwitcher';
import { ReactModalInfo } from '../../../containers/ReactModalInfo';
import { USD } from '../../../shared/consts/consts';

interface IInitialValues {
  mobileSelector: string
}
const mySubscriptionsMobileSelector = [
  { label: 'MY_SUBSCRIPTIONS.ACCOUNT_NUMBER' },
  { label: 'MY_STRATEGIES.PROFIT_LOSS' },
  { label: 'MY_STRATEGIES.BALANCE' },
  { label: 'MY_STRATEGIES.FUNDS' },
];

function MySubscriptionsMobile() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const onToggleContent = (e: boolean) => { // переключение типа контента активные/закрытые
    dispatch(setSubscriptionContent(e));
  };
  const { mySubscriptions } = useAppSelector((state) => state);
  const { isActive, showActive } = mySubscriptions.info;

  const {
    active_subscriptions,
    closed_subscriptions,
    isActiveSubscriptionsLoading,
    isClosedSubscriptionsLoading,
    sort,
  } = useAppSelector((state) => state.mySubscriptions);
  // const [showActive, setShowActive] = useState([0]);
  const [showControl, setShowControl] = useState(0);
  const menuRef = useRef(null);
  const sortedData: ISubscriptionState = JSON.parse(JSON.stringify(mySubscriptions));
  const {
    curr_sort_method,
    curr_sort_type,
    sort_types,
    curr_sort_label,
  } = sort;

  const handleSortClick = useCallback((sortType: currSortType, sortMethod: currSortMethod) => {
    switch (sortMethod) {
      case 'default':
        dispatch(changeSortMethod('down'));
        sortedData.active_subscriptions.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
        dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
        break;
      case 'up':
        dispatch(changeSortMethod('down'));
        sortedData.active_subscriptions.sort((a, b) => b[`${sortType}`] - a[`${sortType}`]);
        dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
        break;
      case 'down':
        dispatch(changeSortMethod('up'));
        sortedData.active_subscriptions.sort((a, b) => a[`${sortType}`] - b[`${sortType}`]);
        dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
        break;
      default:
        break;
    }
  }, [dispatch, sortedData]);
  const initialValues = {
    mobileSelector: curr_sort_label,
  };
  const formik = useFormik<IInitialValues>({
    initialValues,
    onSubmit: (sortValue) => {
      switch (sortValue.mobileSelector) {
        case t('MY_SUBSCRIPTIONS.ACCOUNT_NUMBER'):
          dispatch(changeSortType(sort_types[3]));
          dispatch(changeSortMethod('up'));
          sortedData.active_subscriptions.sort((a, b) => a[`${sort_types[3]}`] - b[`${sort_types[3]}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        case t('MY_STRATEGIES.PROFIT_LOSS'):
          dispatch(changeSortType(sort_types[0]));
          dispatch(changeSortMethod('up'));
          sortedData.active_subscriptions.sort((a, b) => a[`${sort_types[0]}`] - b[`${sort_types[0]}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        case t('MY_STRATEGIES.BALANCE'):
          dispatch(changeSortType(sort_types[1]));
          dispatch(changeSortMethod('up'));
          sortedData.active_subscriptions.sort((a, b) => a[`${sort_types[1]}`] - b[`${sort_types[1]}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        case t('MY_STRATEGIES.FUNDS'):
          dispatch(changeSortType(sort_types[2]));
          dispatch(changeSortMethod('up'));
          sortedData.active_subscriptions.sort((a, b) => a[`${sort_types[2]}`] - b[`${sort_types[2]}`]);
          dispatch(getActiveSubscriptionsSuccess(sortedData.active_subscriptions));
          break;
        default:
          break;
      }
    },
  });
  useEffect(() => {
    if (
      !active_subscriptions.length
        || !closed_subscriptions.length
        || location.state === null
        // @ts-ignore
        || !location.state.includes('/strategies_rating/strategy/')
    ) {
      if (curr_sort_method !== 'default') dispatch(changeSortMethod('default'));
      if (curr_sort_type !== 'account') dispatch(changeSortType('account'));
      formik.setFieldValue('mobileSelector', '');
      dispatch(setCurrentSubsSortLabel(''));
      dispatch(setShowActive([0]));
      dispatch(getActiveSubscriptionsRequest());
      dispatch(getClosedSubscriptionsRequest());
    }
  }, [dispatch]);
  const {
    create_account_url,
    deposit_funds_url,
    withdraw_funds_url,
  } = useAppSelector((state) => state.customer.redirectUrls);
  useEffect(() => {
    if (showControl && menuRef && menuRef.current) {
      // @ts-ignore
      const menu: HTMLDivElement = menuRef.current;
      menu.focus();
    }
  }, [
    showControl,
  ]);
  // если есть true и input пустой, то сразу выбираем первое значение из массива
  return (
    <div
      className={cx(styles.my_subscriptions_mobile)}
    >
      <div
        className={styles.title}
      >
        <Text type="h1">
          {t('MY_SUBSCRIPTIONS.PAGE_TITLE')}
        </Text>
        { isActive
        && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== ''
        && t('MY_SUBSCRIPTIONS.INFO_CONTENT') !== 'MY_STRATEGIES.INFO_CONTENT'
        && (
          <ReactModalInfo
            title={t('OTHER.INFO_TITLE')}
            content={t('MY_SUBSCRIPTIONSS.INFO_CONTENT')}
          />
        )}
        { !isActive
        && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== ''
        && t('MY_SUBSCRIPTIONS.INFO_CONTENT_CLOSED') !== 'MY_STRATEGIES.INFO_CONTENT_CLOSED'
        && (
          <ReactModalInfo
            title={t('OTHER.INFO_TITLE')}
            content={t('MY_STRATEGIES.INFO_CONTENT_CLOSED')}
          />
        )}
      </div>

      <div className={cx(styles.action_buttons_wrapper)}>
        <ButtonManage
          onClick={() => onRedirectHandler(create_account_url)}
          icon={openAccountIcon}
          dispatch={dispatch}
          handleReset={() => false}
        />
        <ButtonManage
          onClick={() => onRedirectHandler(deposit_funds_url)}
          icon={depositIcon}
          handleReset={formik.handleReset}
          dispatch={dispatch}
          actions={[]}
        />
        <ButtonManage
          onClick={() => onRedirectHandler(withdraw_funds_url)}
          icon={withdrawIcon}
          handleReset={formik.handleReset}
          dispatch={dispatch}
          actions={[]}
        />
      </div>

      <ButtonToggle
        isSubscriptions
        onClickContent={onToggleContent}
      />
      {isActiveSubscriptionsLoading
      && isClosedSubscriptionsLoading
      && (
      <Spinner
        className={styles.spinner}
      />
      )}

      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && isActive
      && active_subscriptions.length === 0
      && closed_subscriptions.length === 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification-mobile">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_1')}
          </Text>
          <Text type="page-notification-mobile">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_2')}
          </Text>
        </div>
      )}
      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && isActive
      && active_subscriptions.length === 0
      && closed_subscriptions.length > 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification-mobile">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_3')}
          </Text>
          <Text type="page-notification-mobile">
            {t('MY_SUBSCRIPTIONS.NO_ACTIVE_SUBSCRIPTIONS_DESC_2')}
          </Text>
        </div>
      )}

      {!isActiveSubscriptionsLoading && isActive && active_subscriptions.length > 0 && (
      <>
        <div className={cx(styles.filter_block)}>
          <ModalSelector
            values={mySubscriptionsMobileSelector}
            name="mobileSelector"
            formik={formik}
          />
          <div className={cx(styles.filter_icon)}>
            <ArrowSwitcher
              onClick={() => handleSortClick(curr_sort_type, curr_sort_method)}
              currSort={curr_sort_type}
              sortMethod={curr_sort_method}
            />
          </div>
        </div>
        {active_subscriptions.map((subscription) => (
          <div
            key={uuidv4()}
            className={cx(
              styles.strategy_card,
              {
                [styles.strategy_card__active]: showActive.includes(subscription.account),
              },
            )}
          >

            <div
              className={styles.show_icon}
              onClick={() => (
                showActive.includes(subscription.account)
                  ? dispatch(setShowActive(showActive.filter((el) => el !== subscription.account)))
                  : dispatch(setShowActive([...showActive, subscription.account]))
              )}
            >
              <img
                src={showActive.includes(subscription.account) ? hideIcon : showIcon}
                alt=""
              />
            </div>
            <div
              className={cx(
                styles.title_wrapper,
                {
                  [styles.title_wrapper__active]: showActive.includes(subscription.account),
                },
              )}
              onClick={() => dispatch(setShowActive([...showActive, subscription.account]))}
            >

              <div className={styles.item_title}>
                <Text
                  type={showActive.includes(subscription.account) ? 'h2' : 'h3'}
                  className={cx(
                    styles.account,
                    {
                      [styles.account__active]: showActive.includes(subscription.account),
                    },
                  )}
                >
                  {t('MY_STRATEGIES.ACCOUNT_NUMBER')}
                </Text>
                {' '}
                <Text
                  // type="h3"
                  type={showActive.includes(subscription.account) ? 'h2' : 'h3'}
                  className={cx(
                    styles.account,
                    {
                      [styles.account__active]: showActive.includes(subscription.account),
                    },
                  )}
                >
                  {subscription.account}
                </Text>
              </div>

            </div>

            <div
              className={cx(
                styles.info_block,
                {
                  [styles.info_block__active]: showActive.includes(subscription.account),
                },
              )}
              onClick={() => dispatch(setShowActive([...showActive, subscription.account]))}
            >
              <div className={cx(styles.info_item)}>
                <Text
                  type="h1"
                  className={cx(
                    {
                      [styles.green_font]: (showActive.includes(subscription.account) && subscription.total_pnl > 0),
                      [styles.red_font]: (showActive.includes(subscription.account) && subscription.total_pnl < 0),
                      [styles.black_font]: (showActive.includes(subscription.account) && subscription.total_pnl === 0),
                    },
                  )}
                >
                  {
                  (subscription.total_pnl > 0 && `+${+subscription.total_pnl.toFixed(2)} ${USD}`)
                  || (subscription.total_pnl === 0 && `0 ${USD}`)
                  || (subscription.total_pnl < 0 && `${+subscription.total_pnl.toFixed(2)} ${USD}`)
                }
                </Text>
                <Text type="text">
                  {t('MY_STRATEGIES.PROFIT_LOSS')}
                </Text>
              </div>

              <div className={cx(
                styles.info_item,
                {
                  // [styles.info_item__text_end]: !showActive.includes(subscription.account),
                },
              )}
              >
                <Text
                  type="h1"
                >
                  {
                     `${subscription.balance} ${USD}`
                  }
                </Text>
                <Text type="text">
                  {t('MY_STRATEGIES.BALANCE')}
                </Text>
              </div>

              <div
                className={cx(
                  styles.control_block_wrapper,
                  {
                    [styles.control_block_wrapper__active]: showActive.includes(subscription.account),
                  },
                )}
                role="button"
              >
                <div
                  className={cx(styles.control_icon)}
                  onClick={
                  () => (showControl === subscription.account
                    ? setShowControl(0)
                    : setShowControl(subscription.account))
                }
                >
                  <img src={showControl === subscription.account ? activeControlIcon : controlIcon} alt="" />
                </div>

                {
                  showControl === subscription.account && (
                  <div
                    ref={menuRef}
                    className={cx(styles.control_dropdown)}
                    tabIndex={-1}
                    role="listbox"
                  >
                    <div
                      className={cx(styles.icon_wrapper)}
                      onClick={() => onRedirectHandler(deposit_funds_url)}
                    >
                      <img src={depositIcon} alt="" />
                    </div>
                    <div
                      className={cx(styles.icon_wrapper)}
                      onClick={() => onRedirectHandler(withdraw_funds_url)}
                    >
                      <img src={withdrawIcon} alt="" />
                    </div>
                  </div>
                  )
                }

              </div>
            </div>

            {
              showActive.includes(subscription.account) && (
              <div className={cx(styles.content_wrapper)}>

                <div className={cx(styles.content_item)}>
                  <Text type="text">
                    {t('MY_STRATEGIES.FUNDS')}
                  </Text>
                  <Text type="button">
                    {`${subscription.equity} ${USD}`}
                  </Text>
                </div>

                <div className={cx(
                  styles.strategies_row,
                )}
                >
                  <Text type="text">
                    {t('MY_STRATEGIES.STRATEGIES')}
                  </Text>
                </div>

                <div className={styles.subscriptions_items_wrapper}>
                  {
                    subscription.subscription_data.map((item) => (
                      <div
                        className={styles.subscriptions_item}
                        key={uuidv4()}
                      >
                        <div className="subscription_name">
                          <Link
                            className={cx(
                              'nav-link',
                              styles.nav_link,
                            )}
                            ria-current="page"
                            to={`/strategies_rating/strategy/${item.strategy_id}`}
                            state={{ from: location.pathname }}
                          >
                            <Text type="button">{item.strategy_name}</Text>
                          </Link>
                        </div>

                        <div className={cx(styles.manage_icons_wrapper)}>
                          <div
                            onClick={
                              () => onChangeSubscriptionStatusHandler(
                                dispatch,
                                item.subscription_id,
                                item.status,
                              )
                            }
                            className={cx(styles.icon_wrapper)}
                          >
                            <img src={item.status === 'ACTIVE' ? pauseIcon : launchIcon} alt="" />
                          </div>
                          {/* <div */}
                          {/*  onClick={ */}
                          {/*    () => onEditSubscriptionHandler(dispatch, item.subscription_id) */}
                          {/*  } */}
                          {/*  className={cx(styles.icon_wrapper)} */}
                          {/* > */}
                          {/*  <img src={editIcon} alt="" /> */}
                          {/* </div> */}
                          <div
                            onClick={
                              () => onDeleteSubscriptionHandler(dispatch, item.subscription_id)
                            }
                            className={cx(styles.icon_wrapper)}
                          >
                            <img src={deleteIcon} alt="" />
                          </div>
                        </div>

                      </div>
                    ))
                  }
                </div>

              </div>
              )
            }

          </div>
        ))}
      </>
      )}

      {!isActiveSubscriptionsLoading
      && !isClosedSubscriptionsLoading
      && !isActive
      && closed_subscriptions.length === 0
      && (
        <div className={styles.notification_text_wrapper}>
          <Text type="page-notification-mobile">
            {t('MY_SUBSCRIPTIONS.NO_CLOSED_SUBSCRIPTIONS_DESC_1')}
          </Text>
        </div>
      )}

      {!isClosedSubscriptionsLoading && !isActive && closed_subscriptions.length > 0 && (
        <>
          {closed_subscriptions.map((subscription) => (

            <div
              key={uuidv4()}
              className={cx(
                styles.closed_strategy_card,
                {
                  [styles.closed_strategy_card__active]: showActive.includes(subscription.id),
                },
              )}
            >

              <div
                className={styles.show_icon}
                onClick={
                  () => (
                    showActive.includes(subscription.id)
                      ? dispatch(setShowActive(showActive.filter((el) => el !== subscription.id)))
                      : dispatch(setShowActive([...showActive, subscription.id]))
                  )
                }
              >
                <img
                  src={showActive.includes(subscription.id) ? hideIcon : showIcon}
                  alt=""
                />
              </div>
              <div
                className={cx(
                  styles.closed_subscription_title_wrapper,
                  {
                    [styles.closed_subscription_title_wrapper__active]: showActive.includes(subscription.id),
                  },
                )}
                onClick={() => dispatch(setShowActive([...showActive, subscription.id]))}
              >

                <div className={styles.closed_subscription_title}>
                  <Text
                    type={showActive.includes(subscription.id) ? 'h2' : 'h3'}
                    className={cx(
                      styles.strategy_name,
                      {
                        [styles.strategy_name__active]: showActive.includes(subscription.id),
                      },
                    )}
                  >
                    {t('MY_STRATEGIES.ACCOUNT_NUMBER')}
                  </Text>
                  {' '}
                  <Text
                    type={showActive.includes(subscription.id) ? 'h2' : 'h3'}
                    className={cx(
                      styles.strategy_name,
                      {
                        [styles.strategy_name__active]: showActive.includes(subscription.id),
                      },
                    )}
                  >
                    {subscription.account}
                  </Text>
                </div>

                <div className={cx(
                  styles.account_number_wrapper,
                  {
                    [styles.account_number_wrapper__active]: showActive.includes(subscription.id),
                  },
                )}
                >
                  <Text type="text">{t('STRATEGIES_RATING.STRATEGY')}</Text>
                  {' '}
                  <Text type="text">
                    {subscription.strategy_name}
                  </Text>
                </div>

              </div>

              <div
                className={cx(
                  styles.info_block,
                )}
                onClick={() => dispatch(setShowActive([...showActive, subscription.id]))}
              >
                <div className={cx(
                  styles.info_item,
                  {
                    [styles.black_font]: showActive.includes(subscription.id),
                  },
                )}
                >
                  <Text
                    type="h1"
                    className={cx(
                      {
                        [styles.green_font]: (showActive.includes(subscription.id) && subscription.total_pnl > 0),
                        [styles.red_font]: (showActive.includes(subscription.id) && subscription.total_pnl < 0),
                        [styles.black_font]: (showActive.includes(subscription.id) && subscription.total_pnl === 0),
                      },
                    )}
                  >
                    {
                      (subscription.total_pnl > 0 && `+${+subscription.total_pnl.toFixed(2)} ${USD}`)
                      || (subscription.total_pnl === 0 && `0 ${USD}`)
                      || (subscription.total_pnl < 0 && `${+subscription.total_pnl.toFixed(2)} ${USD}`)
                    }
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.PROFIT_LOSS')}
                  </Text>
                </div>
                <div className={cx(
                  styles.info_item,
                  {
                    [styles.black_font]: showActive.includes(subscription.id),
                  },
                )}
                >
                  <Text type="h1">
                    {`${subscription.balance} ${USD}`}
                  </Text>
                  <Text type="text">
                    {t('MY_STRATEGIES.BALANCE')}
                  </Text>
                </div>
              </div>
              {showActive.includes(subscription.id) && (
                <div className={cx(styles.content_wrapper)}>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.CREATION_DATE')}
                    </Text>
                    <Text type="button">
                      {`${subscription.created_at}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.CLOSING_DATE')}
                    </Text>
                    <Text type="button">
                      {`${subscription.closed_at}`}
                    </Text>
                  </div>
                  <div className={cx(styles.content_item)}>
                    <Text type="text">
                      {t('MY_STRATEGIES.FUNDS')}
                    </Text>
                    <Text type="button">
                      {`${subscription.equity} ${USD}`}
                    </Text>
                  </div>
                </div>
              )}

            </div>
          ))}
        </>
      )}

    </div>
  );
}

export default MySubscriptionsMobile;
