import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import cx from 'classnames';
import Text from '../../../entities/Text/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getStrategyInfoRequest } from '../../../store/strategyInfo/reducer';
import styles from './MyStrategyInfoDesktop.module.scss';
import { getActiveStrategiesRequest } from '../../../store/myStrategies/reducer';
import { ButtonBack, ButtonDelete } from '../../../features/Buttons';
import { onDeleteStrategyHandler } from '../../../handlers/handlers';
import Charts from '../../../entities/Charts/Charts';
import { ButtonToggle } from '../../../features/ButtonToggle';
import { USD } from '../../../shared/consts/consts';
import { FeeComponent } from '../../../entities/FeeComponent/FeeComponent';

const MyStrategyInfoDesktop = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(true);
  const goBack = () => {
    navigate(-1);
  };
  const { data, isLoading } = useAppSelector(
    (state) => state.strategyInfo,
  );
  const { allStrategies } = useAppSelector(
    (state) => state.myStrategies,
  );
  const { active } = allStrategies;
  const myParams = active.active_strategies.find((value) => value.id === Number(id));
  useEffect(() => {
    if (!myParams) dispatch(getActiveStrategiesRequest());
    if (id) {
      dispatch(getStrategyInfoRequest(id));
    }
  }, [id, dispatch, myParams]);
  return (
    <>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && data && myParams && (
        <div
          className={styles.content_wrapper}
        >
          <div className={cx(styles.page_title_wrapper)}>
            <div className={cx(styles.page_title_wrapper__left_side)}>
              <div className="page_title">
                <Text type="h1">{t('STRATEGIES_RATING.STRATEGY')}</Text>
                {' '}
                <Text type="h1">{myParams.name}</Text>
              </div>
              <ButtonToggle
                onClickContent={() => setIsToggled(!isToggled)}
                isToggled={isToggled}
                isMyStrategy
              />
            </div>
            <div className={cx(styles.buttons_wrapper)}>
              <ButtonBack
                onClick={goBack}
                buttonSize="medium"
              >
                <Text type="button">{t('STRATEGIES_RATING.ALL_STRATEGIES')}</Text>
              </ButtonBack>
              <ButtonDelete
                buttonSize="medium"
                onClick={() => onDeleteStrategyHandler(dispatch, myParams.id)}
              >
                <Text type="button">{t('NOTIFICATION.DELETE_STRATEGY')}</Text>
              </ButtonDelete>
            </div>
          </div>
          <Text type="account">{`${t('MY_STRATEGIES.ACCOUNT_NUMBER')} ${myParams.account}`}</Text>
          <div
            className={styles.wrap_container}
          >
            {isToggled && (
            <div>
              <div className={cx(styles.info_block_wrapper)}>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.PROFIT_LOSS')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text
                      type="button"
                      className={cx(
                        {
                          [styles.green_font]: myParams.total_pnl > 0,
                          [styles.red_font]: myParams.total_pnl < 0,
                          [styles.black_font]: myParams.total_pnl === 0,
                        },
                      )}
                    >
                      {
                        (myParams.total_pnl > 0 && `+${+myParams.total_pnl.toFixed(2)} ${USD}`)
                          || (myParams.total_pnl === 0 && `0 ${USD}`)
                          || (myParams.total_pnl < 0 && `${+myParams.total_pnl.toFixed(2)} ${USD}`)
                      }
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.SUBSCRIBERS')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {myParams.total_followers}
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.BALANCE')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">{`${myParams.balance} ${USD}`}</Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.FUNDS')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {`${myParams.equity} ${USD}`}
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.REWARD_TYPE')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <FeeComponent
                      type_of_fee={data.fee.type_of_fee}
                      percent_fee={data.fee.percent_fee}
                      fixed_fee={data.fee.fixed_fee}
                      textType="button"
                    />
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.RECOMMENDED_BALANCE')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">{`${myParams.recommended_amount} ${USD}`}</Text>
                  </div>
                </div>
              </div>
            </div>
            )}
            {!isToggled && (
            <div>
              <div className={cx(styles.info_block_wrapper)}>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.PROFITABILITY')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {data.profitability}
                      {' '}
                      %
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.MAXIMUM_DRAWDOWN')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {data.max_drop}
                      {' '}
                      %
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.MAXIMUM_LEVERAGE')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">{data.max_leverage}</Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.AGE')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {data.age}
                      {' '}
                      d
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.SUBSCRIPTIONS')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">
                      {data.subscribers_balance}
                      {' '}
                      {USD}
                    </Text>
                  </div>
                </div>
                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('MY_STRATEGIES.SUBSCRIBERS')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="button">{data.total_followers}</Text>
                  </div>
                </div>
              </div>
              <div className={cx(styles.subscription_terms_wrapper)}>
                <div className="subscription_terms_label">
                  <Text type="button">{t('STRATEGIES_RATING.SUBSCRIPTION_TERMS')}</Text>
                </div>

                <div className={cx(styles.info_row)}>
                  <div className={cx(styles.info_row_item)}>
                    <Text type="text">{t('STRATEGIES_RATING.REMUNERATION')}</Text>
                  </div>
                  <div className={cx(styles.info_row_item)}>
                    <FeeComponent
                      type_of_fee={data.fee.type_of_fee}
                      percent_fee={data.fee.percent_fee}
                      fixed_fee={data.fee.fixed_fee}
                      textType="button"
                    />
                  </div>
                </div>
              </div>
            </div>
            )}

            <Charts />
          </div>
        </div>
      )}
    </>
  );
};

export default MyStrategyInfoDesktop;
