import React, { useEffect } from 'react';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getStrategyInfoRequest,
} from '../../../store/strategyInfo/reducer';
import Text from '../../../entities/Text/Text';
import styles from './StrategyInfoDesktop.module.scss';
import { ButtonBack, ButtonRegistration } from '../../../features/Buttons';
import { ReactModalSubscribe } from '../../../containers/ReactModalSubscribe';
import Charts from '../../../entities/Charts/Charts';
import route from '../../../app/routes/routes';
import { registrationUrl, USD } from '../../../shared/consts/consts';
import { onRedirectHandler } from '../../../handlers/handlers';
import { FeeComponent } from '../../../entities/FeeComponent/FeeComponent';

function StrategyInfoDesktop() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionToken = sessionStorage.getItem('token');
  const { token } = useAppSelector((state) => state.customer.token);
  const { data, isLoading } = useAppSelector(
    (state) => state.strategyInfo,
  );
  const goSubscriptions = (to: string, value: string) => {
    navigate(to, { state: value });
  };
  const goRating = (to: string, value: string) => {
    navigate(to, { state: value });
  };
  useEffect(() => {
    if (id) {
      dispatch(getStrategyInfoRequest(id));
    }
  }, [id, dispatch]);
  return (
    <>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && data && (
      <div
        className={styles.content_wrapper}
      >
        <div className={cx(styles.page_title_wrapper)}>
          <div className="page_title">
            <Text type="h1">{t('STRATEGIES_RATING.STRATEGY')}</Text>
            {' '}
            <Text type="h1">{data.name}</Text>
          </div>
          <div className={cx(styles.buttons_wrapper)}>
            <ButtonBack
              onClick={
                // @ts-ignore
                location.state?.from === route.my_subscriptions.catalog
                // @ts-ignore
                  ? () => goSubscriptions(location.state?.from, location.pathname)
                // @ts-ignore
                  : () => goRating(location.state?.from, location.pathname)
            }
              buttonSize="medium"
            >
              <Text type="button">{t('STRATEGIES_RATING.ALL_STRATEGIES')}</Text>
            </ButtonBack>
            {(sessionToken || token)
              ? (
                <ReactModalSubscribe
                  button="MODAL.SUBSCRIBE"
                  title="MODAL.SUBSCRIBE_TITLE"
                  args={data}
                  buttonSize="medium"
                />
              )
              : (
                <ButtonRegistration
                  onClick={() => onRedirectHandler(`${registrationUrl}${location.search}`)}
                  handleReset={() => false}
                  buttonSize="medium"
                >
                  <Text type="button">{t('OTHER.REGISTRATION')}</Text>
                </ButtonRegistration>
              )}
          </div>
        </div>
        <div className={styles.wrap_container}>
          <div>
            <div className={cx(styles.info_block_wrapper)}>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.PROFITABILITY')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">
                    {data.profitability}
                    {' '}
                    %
                  </Text>
                </div>
              </div>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.MAXIMUM_DRAWDOWN')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">
                    {data.max_drop}
                    {' '}
                    %
                  </Text>
                </div>
              </div>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.MAXIMUM_LEVERAGE')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">{data.max_leverage}</Text>
                </div>
              </div>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.AGE')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">
                    {data.age}
                    {' '}
                    d
                  </Text>
                </div>
              </div>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.SUBSCRIPTIONS')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">
                    {data.subscribers_balance}
                    {' '}
                    {USD}
                  </Text>
                </div>
              </div>
              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('MY_STRATEGIES.SUBSCRIBERS')}</Text>
                </div>
                <div className={cx(styles.info_row_item)}>
                  <Text type="button">{data.total_followers}</Text>
                </div>
              </div>
            </div>
            <div className={cx(styles.subscription_terms_wrapper)}>
              <div className="subscription_terms_label">
                <Text type="button">{t('STRATEGIES_RATING.SUBSCRIPTION_TERMS')}</Text>
              </div>

              <div className={cx(styles.info_row)}>
                <div className={cx(styles.info_row_item)}>
                  <Text type="text">{t('STRATEGIES_RATING.REMUNERATION')}</Text>
                </div>
                <div className={cx(
                  styles.info_row_item,
                )}
                >
                  <FeeComponent
                    type_of_fee={data.fee.type_of_fee}
                    percent_fee={data.fee.percent_fee}
                    fixed_fee={data.fee.fixed_fee}
                    textType="button"
                  />
                </div>
              </div>
            </div>
          </div>
          <Charts />
        </div>
      </div>
      )}
    </>
  );
}

export default StrategyInfoDesktop;
