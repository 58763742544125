import {
  put, takeLeading, call,
} from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getStrategiesSuccess,
  getStrategiesFail,
} from './reducer';
import {
  IDataStrategies,
  IStrategiesRatingRequest,
} from './models';
import { baseUrl } from '../../shared/consts/baseUrl';
import { notificationModalOpen } from '../notification/reducer';

function* getStrategiesRatingSaga({ payload }: PayloadAction<IStrategiesRatingRequest>) {
  try {
    const sessionToken = sessionStorage.getItem('token');
    const resp: {data: IDataStrategies} = yield call(() => axios({
      method: 'post',
      baseURL: baseUrl,
      url: 'strategy/list',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: payload || {}, // пустой объект для получения стретегий по всем счетам, payload - по конкретному
    }));
    resp.data.list.sort((a, b) => b.profitability - a.profitability);
    yield put(getStrategiesSuccess(resp.data));
  } catch (e: any) {
    yield put(getStrategiesFail((e as Error).message));
    yield put(notificationModalOpen({
      label: 'NOTIFICATION.GET_STRATEGIES_RATING.TITLE',
      message: `NOTIFICATION.GET_STRATEGIES_RATING.${e.response.data.Key}`,
      actionButton: 'MODAL.OK_BTN',
      request_id: e.response.data.RequestID,
      type: 'error',
      isOpen: true,
    }));
  }
}

export function* strategiesRatingWatcher() {
  yield takeLeading('strategiesRating/getStrategiesRequest', getStrategiesRatingSaga);
}
