import React, { useState } from 'react';
import { getCookie } from 'typescript-cookie';
import cx from 'classnames';
import Flag from 'react-world-flags';
import i18next from 'i18next';
import styles from './LanguagePanel.module.scss';
import DropDownMenu from '../../entities/DropdownMenu/DropdownMenu';
import Text from '../../entities/Text/Text';

interface Ilanguages {
  code: string,
  countryCode: string,
}
const languages: Ilanguages[] = [
  {
    code: 'en',
    countryCode: 'gb',
  },
  {
    code: 'es',
    countryCode: 'es',
  },
  {
    code: 'id',
    countryCode: 'id',
  },
  {
    code: 'ms',
    countryCode: 'ms',
  },
  {
    code: 'ru',
    countryCode: 'ru',
  },
];
const LanguagePanel = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const cookieLanguageCode = getCookie('i18next')?.substring(0, 2);
  const currentLanguageCode = cookieLanguageCode || 'en';
  const currentCountryCode = languages.find(
    ({ code }) => code === currentLanguageCode,
  );
  return (
    <div
      tabIndex={0}
      role="button"
      className={cx(
        styles.language_panel,
      )}
      onClick={() => setDropdownOpen(!dropdownOpen)}
      onBlur={() => setDropdownOpen(false)}
    >
      <div
        className={cx(
          styles.flag_wrap,
          { [styles['flag_wrap--active']]: dropdownOpen },
        )}
      >
        <Flag
          code={currentCountryCode?.countryCode}
          fallback={<Flag code="gb" />}
          className={styles.flag}
        />
      </div>
      {
    dropdownOpen && (
    <DropDownMenu
      inlineBlock="right"
    >
      {
        languages.map(({ code }, index) => (
          <div key={code}>
            {code && code !== currentLanguageCode && (
            <>
              <div
                key={code}
                onClick={() => {
                  i18next.changeLanguage(code); // dispatch с запросом переводов сюда
                }}
                className={styles.language_panel__item}
              >
                <div
                  className={cx(
                    styles.flag_wrap,
                  )}
                >
                  <Flag
                    code={code}
                    fallback={<Flag code="gb" />}
                    className={styles.flag}
                  />
                </div>
                {' '}
                <Text type="button" className={styles.language_panel__item__text}>
                  {code.toUpperCase()}
                </Text>
              </div>
              {(languages.length - 1 !== index && languages.length > 2) && <br /> }
            </>
            )}
          </div>
        ))
      }
    </DropDownMenu>
    )
  }
    </div>
  );
};

export default LanguagePanel;
