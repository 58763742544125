import { Dispatch } from 'react';

interface IActions {
  type: string,
  payload?: string | number
}
export const handleDispatch = (dispatch: Dispatch<any> | undefined, actions: IActions[] | undefined) => {
  if (actions && dispatch) {
    actions.forEach((action) => dispatch(action));
  }
};
