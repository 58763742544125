import React from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './MobileMenu.module.scss';
import closeMenuIcon from '../../shared/assets/icons/close_menu_icon.svg';
import Text from '../../entities/Text/Text';
import route from '../../app/routes/routes';

const MobileMenu = ({ onShowMobileMenu, showMobileMenu }: {onShowMobileMenu: () => void, showMobileMenu: boolean}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className={cx(
      styles.mobile_menu_wrapper,
      { [styles.mobile_menu_wrapper__open]: showMobileMenu },
    )}
    >
      <div className={cx(styles.close_icon_wrapper)}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={closeMenuIcon}
          alt="menu"
          className={cx(styles.close_icon)}
          onClick={onShowMobileMenu}
        />
      </div>
      <div className="mobile_menu">
        <ul className={cx('navbar-nav')}>
          <li className="nav-item">
            <NavLink
              className={cx(
                'nav-link',
                styles.nav_link,
                {
                  [styles.active]: pathname === route.root,
                },
              )}
              aria-current="page"
              to={route.root}
              onClick={onShowMobileMenu}
            >
              <Text type="mobile-menu">{t('STRATEGIES_RATING.PAGE_TITLE')}</Text>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={cx(
                'nav-link',
                styles.nav_link,
                {
                  [styles.active]: pathname === route.my_subscriptions.catalog,
                },
              )}
              ria-current="page"
              to={route.my_subscriptions.catalog}
              onClick={onShowMobileMenu}
            >
              <Text type="mobile-menu">{t('MY_SUBSCRIBES.PAGE_TITLE')}</Text>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className={cx(
                'nav-link',
                styles.nav_link,
                {
                  [styles.active]: pathname === route.my_strategies.catalog,
                },
              )}
              aria-current="page"
              to={route.my_strategies.catalog}
              onClick={onShowMobileMenu}
            >
              <Text type="mobile-menu">{t('MY_STRATEGIES.PAGE_TITLE')}</Text>
            </NavLink>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default MobileMenu;
