import { all, fork } from 'redux-saga/effects';
import { customerWatcher } from '../../../store/customer/sagas';
import { myStrategiesWatcher } from '../../../store/myStrategies/sagas';
import { strategiesRatingWatcher } from '../../../store/strategiesRating/sagas';
import { strategyInfoWatcher } from '../../../store/strategyInfo/sagas';
import { mySubscriptionsWatcher } from '../../../store/mySubscription/sagas';

export default function* rootSaga() {
  try {
    yield all([
      fork(customerWatcher),
      fork(myStrategiesWatcher),
      fork(strategiesRatingWatcher),
      fork(mySubscriptionsWatcher),
      fork(strategyInfoWatcher),
    ]);
  } finally {
    // always runs
  }
}
