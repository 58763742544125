import React, { FC, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styles from './ReactModalStrategy.module.scss';
import Text from '../../../entities/Text/Text';
import { ButtonCancel, ButtonNormal, ButtonManage } from '../../../features/Buttons';
import ModalContent from '../../ModalContent/ModalContent';
import { validationSchema1, validationSchema2, validationSchema3 } from '../../../shared/schemas/schemas';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { changeValidScheme, createStrategyRequest, getRewardRequest } from '../../../store/myStrategies/reducer';
import { getVacantAccountsRequest, getVacantAccountsSuccess } from '../../../store/customer/reducer';
import { ReactModalInfo } from '../../ReactModalInfo';
import { IFormikPropsStrategy, IReactModalProps } from '../model/types/types';

const initialValues = {
  vacant_accounts: '',
  strategy_name: '',
  recommended_amount: 100,
  strategy_reward: '',
  strategy_reward_value: '',
  // period_payment: '',
};

export const ReactModalStrategy: FC<IReactModalProps> = ({
  button, title, args, icon,
}) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customer, myStrategies } = useAppSelector((state) => state); // список счетов
  const { rewards, info } = myStrategies; // список вознаграждений
  const { validationScheme } = info;
  const isLoading = customer.isLoading || rewards.isLoading;
  const windowInnerWidth = window.innerWidth;
  const limitValidation = rewards.rewardTypes?.find((el) => el.strategy_type === validationScheme);
  const formik = useFormik<IFormikPropsStrategy>({
    initialValues,
    onSubmit: async (data) => {
      dispatch(createStrategyRequest({
        target_account: Number(data.vacant_accounts),
        strategy_name: data.strategy_name,
        recommended_amount: Number(data.recommended_amount),
        type_of_fee: validationScheme,
        percent_fee: validationScheme === 1 ? (Number(data.strategy_reward_value) / 100) : 0, // если валидная схема
        fixed_fee: validationScheme === 1 ? 0 : Number(data.strategy_reward_value),
        // delay_in_seconds: (Number(data.period_payment) * 60 * 60 * 24),
      }));
      // обнуляем state с аккаунтами
      dispatch(getVacantAccountsSuccess(
        {
          vacant_accounts: [],
          invest_accounts: [],
          isLoading: false,
          error: '',
        },
      ));
    },
    validationSchema:
    // выбираем validation схему согласно выбранному пункту в reward
      (validationScheme === 1 && limitValidation && validationSchema1(limitValidation.strategy_request_keys))
      || (validationScheme === 2 && limitValidation && validationSchema2(limitValidation.strategy_request_keys))
      || (validationScheme === 3 && limitValidation && validationSchema3(limitValidation.strategy_request_keys)),
  });
  const toggle = () => setModal(!modal);
  return (
    <div className={styles.modal_wrapper}>
      <ButtonManage
        onClick={toggle}
        handleReset={formik.handleReset}
        icon={icon}
        dispatch={dispatch}
        actions={
          [
            getVacantAccountsRequest('vacant'),
            getRewardRequest(),
            changeValidScheme(1),
          ]
      }
      >
        {
          button ? <Text type="button">{t(button)}</Text> : false
        }
      </ButtonManage>
      {customer.accounts.vacant_accounts.length > 0
      && !isLoading
      && (
      <Modal
        isOpen={modal}
        centered
        className={styles.modal_window}
        contentClassName={styles.modal_content}
        backdrop={windowInnerWidth > 545}
      >
        <ModalHeader
          className={styles.header}
          cssModule={{ 'btn-close': styles.btn_close }}
          toggle={toggle}
        >
          {t('MODAL.CRATE_STRATEGY_INFO_CONTENT') !== ''
          && t('MODAL.CRATE_STRATEGY_INFO_CONTENT') !== 'MODAL.CRATE_STRATEGY_INFO_CONTENT'
          && (
            <div className={styles.info_wrapper}>
              <ReactModalInfo
                title={t('OTHER.INFO_TITLE')}
                content={t('MODAL.CRATE_STRATEGY_INFO_CONTENT')}
              />
              <div className={styles.info_border} />
            </div>
          )}

          <Text type="h1">{`${t(title)} ${args}`}</Text>

        </ModalHeader>
        <ModalBody
          className={styles.modal_body}
        >
          <ModalContent
            formik={formik}
          />
        </ModalBody>
        <ModalFooter
          className={styles.footer}
        >
          <ButtonNormal
            disabled={!formik.dirty || !formik.isValid} // проверка на ошибки
            onClick={() => {
              formik.handleSubmit();
              toggle();
            }}
          >
            <Text type="button">{t('MODAL.CREATE_BTN')}</Text>
          </ButtonNormal>
          <ButtonCancel
            onClick={toggle}
          >
            <Text type="button">{t('MODAL.CANCEL_BTN')}</Text>
          </ButtonCancel>
        </ModalFooter>
      </Modal>
      )}
    </div>
  );
};
