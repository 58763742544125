export const getRoute = () => ({
  root: '/strategies_rating',
  title: 'Rating',
  strategy_information: {
    catalog: '/strategies_rating/strategy/:id',
    title: 'Strategy',
  },
  my_strategy_information: {
    catalog: '/my_strategy/strategy/:id',
    title: 'My Strategy',
  },
  my_subscriptions: {
    catalog: '/my_subscriptions',
    title: 'My Subscriptions',
  },
  my_strategies: {
    catalog: '/my_strategies',
    title: 'My Strategies',
  },
  faq: {
    catalog: '/faq',
    title: 'FAQ',
  },
  not_found_page: {
    catalog: '/page_not_found',
    title: 'Page is not found',
  },
});
const route = getRoute();
export default route;
